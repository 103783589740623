import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/app.constant';
import { ConsumptionModelV2 } from 'src/app/consumptionv2/consumptionv2.model';
import { HomeService } from 'src/app/home/home.service';
import { ResultService, ResultDataService } from 'src/app/result/result.service';
import { ConsumptionModelV5 } from './consumption.model';

@Component({
  selector: 'app-consumptionv5',
  templateUrl: './consumptionv5.component.html',
  styleUrls: ['./consumptionv5.component.scss']
})
export class Consumptionv5Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() public consumption: ConsumptionModelV5;
  @Output() extraInput1: EventEmitter<any> = new EventEmitter<any>();
  @Input() yearly: string;
  @Input() electrics: [];
  @Output() electId: EventEmitter<any> = new EventEmitter<any>();
  @Output() supplierShortName: EventEmitter<any> = new EventEmitter<any>();
  email;
  stateList = [];
  tariffList = [];
  electricSuppliers = [];
  selectedState;
  selectedTariff;
  selectedSupplier;
  selectedSupplierCode;
  supplierCodeArray = [];
  SupplierShortName: string;
  leadId;
  electricId = 0;
  shortName: string;
  getSupplier = false;
  rdata;
  data;

  showLoader = false;
  isSelected = 'AnnualConsumptionSelected';
  constructor(private homeService: HomeService,
    private appConstants: Constants,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private resultService: ResultService,
    private router: Router,
    private resultDataService: ResultDataService,
  ) {
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.appConstants.leadId.subscribe(value => {
      this.leadId = value as string;
    });
    this.consumption = new ConsumptionModelV5();
    this.getSupplier = false;
    this.consumption.numPeople = 1;

    // this.consumption.numPeople = 1;
  }

  ngOnInit(): void {
    this.electrics = [];
    this.check();
    // this.getElectricSupplier();
  }
  ngOnChanges(changes: SimpleChanges) {
    // if (this.leadId && this.leadId !== '') {
    //   this.getElectricSupplier();
    // }
    if (this.electrics && this.electrics.length > 0) {
    }
  }
  formatLabel(value: number) {
    return value + ' kWh';
  }
  formatLabel2(value: number) {
    return value + ' CHF';
  }
  proceessElectricSupplierList(){

  }
  check() {
    if (this.isSelected === 'AnnualConsumptionSelected') {
      this.consumption.isMonthlyPriceSelected = false;
    } else if (this.isSelected === 'MonthlyPriceSelected') {
      this.consumption.isMonthlyPriceSelected = true;
    }
    console.log(this.consumption, 'consmoel')
  }


  getElectricSupplierCode(elecId) {
    this.selectedSupplierCode = this.electrics.find((supplierObj: any) => supplierObj.elecID === elecId);
    console.log(this.selectedSupplierCode, 'selectedSupplierOBJ');
    const code = this.selectedSupplierCode.Name;
    this.supplierCodeArray = code.split(' ');
    let supplierCodeArrayElement;
    supplierCodeArrayElement = this.supplierCodeArray.slice(-2);

    this.SupplierShortName = supplierCodeArrayElement[0] + ' ' + supplierCodeArrayElement[1];
    this.supplierShortName.emit({
      value: this.SupplierShortName
    });
  }


  // uploadElectricId() {
  //
  //   this.homeService.uploadElectricId({
  //     email: this.email,
  //     lead_id: this.leadId,
  //     action: 'upload',
  //     elecID: this.electricId,
  //   }).subscribe(res => {
  //     const response = res as any;
  //     this.data = response;
  //   });
  // }

  getResult() {
    this.resultService.getResult().subscribe(res => {
      this.data = res as any;
      this.router.navigate(['resultv']);
      if (this.data.status) {
        this.resultDataService.setResult(this.data);
      }
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

  submitForm() {
    // this.getResult();
    if ((this.consumption.YearlyElecRequired !== 0 && this.consumption.YearlyElecRequired !== undefined)) {
      this.onSubmit.emit({
        value: 'Average Consumption',
        isMonthlySelected: this.consumption.isMonthlyPriceSelected ,
        YearlyElecRequired: this.consumption.YearlyElecRequired,
        monthlyElecCost: this.consumption.monthlyElecCost
      });
    } else {
      this.toastr.warning('Please enter the details first!')
    }
    this.electId.emit({
      value: this.electricId
    });
  }


}
