import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/app.constant';
import { HomeService } from 'src/app/home/home.service';
import { ResultService, ResultDataService } from 'src/app/result/result.service';
import { ConsumptionModelV4 } from './consumption.model';

@Component({
  selector: 'app-consumptionv4',
  templateUrl: './consumptionv4.component.html',
  styleUrls: ['./consumptionv4.component.scss']
})
export class Consumptionv4Component implements OnInit {


  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() public consumption: ConsumptionModelV4;
  @Input() yearly: string;
  @Output() electId: EventEmitter<any> = new EventEmitter<any>();
  
  email;
  stateList = [];
  tariffList = [];
  electricSuppliers = [];
  selectedState;
  selectedTariff;
  selectedSupplier;
  leadId;
  electricId = 0;
  electrics = [];
  getSupplier = false;
  rdata;
  data;
  showLoader = false;
  isSelected = 'AnnualConsumptionSelected';
  formatLabel(value: number) {
    // if (value >= 1000) {
    return value + ' kWh';

  }
  formatLabel2(value: number) {
    return value + ' CHF';
  }
  constructor(private homeService: HomeService,
    private appConstants: Constants,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private resultService: ResultService,
    private router: Router,
    private resultDataService: ResultDataService,
  ) {
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.appConstants.leadId.subscribe(value => {
      this.leadId = value as string;
    });
    this.consumption = new ConsumptionModelV4();
    this.getSupplier = false;
    this.consumption.numPeople = 1;

    // this.consumption.numPeople = 1;
  }

  ngOnInit(): void {
    this.electrics = [];
    this.check();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.leadId && this.leadId !== '') {
      this.getElectricSupplier();
    }
  }
  check() {
    if (this.isSelected === 'AnnualConsumptionSelected') {
      this.consumption.isMonthlyPriceSelected = false;
    } else if (this.isSelected === 'MonthlyPriceSelected') {
      this.consumption.isMonthlyPriceSelected = true;
    }
    console.log(this.consumption, 'consmoel')
  }
  getElectricSupplier() {
    console.log('consumption api working')
    this.electricId = 0;
    this.homeService.getElectricSupplier({
      email: this.email,
      lead_id: this.leadId,
      action: 'getsupplierdata'
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.electrics = this.data.ListofSuppliers;
      this.electricId = parseInt(this.data.ListofSuppliers[0].elecID);
    }, error => {
      this.toastr.warning('Supplier list not found Please try again ', 'Oops');
      this.getSupplier = true;
    });
  }


  uploadElectricId() {

    this.homeService.uploadElectricId({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      elecID: this.electricId,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
    });
  }






  // public extraInput(event): void {
  //   this.extraInput1.emit({
  //     value: this.consumption,
  //   });
  // }



  getResult() {
    this.resultService.getResult().subscribe(res => {
      this.data = res as any;
      this.router.navigate(['resultv']);
      if (this.data.status) {
        this.resultDataService.setResult(this.data);
      }
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

  submitForm() {
    // this.getResult();
    this.onSubmit.emit({
      value: 'Average Consumption',
      elecId: this.electricId,
      consumption: this.consumption
    });
  }


}
