<section class="container-fluid" style="border: 1px solid gray; border-radius: 10px; padding: 15px">
  <h1 mat-dialog-title></h1>
  <div mat-dialog-content>
    <p>{{'roof-area-agv2-modal.content' | translate}}</p>
    <br>
    <p >{{'roof-area-agv2-modal.content1' | translate}}</p>
    <br>
    <p >{{'roof-area-agv2-modal.content2' | translate}}</p>
    <br *ngIf="lang !== 'de'">
    <p *ngIf="lang !== 'de'">{{'roof-area-agv2-modal.content3' | translate}}</p>
    <br *ngIf="lang !== 'de'">
  </div>
</section>
