<div class="container-fluid px-5 py-5 bg-danger text-white rounded">
  <div class="row mb-2">
    <div class="col-12">
      <h2 class="text-center">Unable to get the results.</h2>
      <!--<h3 class="text-center">Please fill up form, we will get back to you.</h3>-->
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <button class="btn btn-outline-light px-4 py-1" (click)="showContact()">OK</button>
    </div>
  </div>
</div>
