/**
 * Created by Avinash maurya on 17/12/20.
 */
import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {throwError} from 'rxjs';
import {HomeService} from '../home/home.service';
import {Constants} from '../app.constant';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor( private router: Router,
               private toastr: ToastrService,
               private homeService: HomeService,
               private appConstants: Constants,) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (!err.status || err.status === 500) {
      this.toastr.info('Session Out');
      const data = {
        origin: localStorage.getItem('origin')
      }
      this.homeService.checkOrigin(data).subscribe(response => {
          if (response && response['access_token']) {
            // location.reload();
            this.appConstants.setTokenPreProd(response['access_token']);
            this.appConstants.setEmail(response['leadusers']);
            this.appConstants.setReload(true);
            this.toastr.info('Reload your Session Time');
          }
        },
        response => {
          if (response && response.error && response.error.error) {
            this.toastr.error(response.error.error, 'Opps!');
          } else {
            this.toastr.error('Unable to complete your request, please try after sometime.', 'Opps!');
          }
        });
    }
    return throwError(err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/assets/i18n')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleAuthError(error);
      }));
  }
}
