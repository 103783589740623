import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-roof-area-dialog',
  templateUrl: './roof-area-dialog.component.html',
  styleUrls: ['./roof-area-dialog.component.scss']
})
export class RoofAreaDialogComponent implements OnInit {
  lang;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
  }

}
