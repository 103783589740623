import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Constants} from "../app.constant";
import {HomeService} from "../home/home.service";
import {ToastrService} from "ngx-toastr";
import {HouseConsumptionNewV1Model} from "./house-consumption-new-v1.model";

@Component({
  selector: 'app-house-consumption-new-v1',
  templateUrl: './house-consumption-new-v1.component.html',
  styleUrls: ['./house-consumption-new-v1.component.scss']
})
export class HouseConsumptionNewV1Component implements OnInit, OnChanges {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() consumptionStep;
  @Output() skip: EventEmitter<any> = new EventEmitter<any>();
  @Output() extraInput1: EventEmitter<any> = new EventEmitter<any>();
  @Input() public consumption: HouseConsumptionNewV1Model;
  @Input() leadId: string;
  @Input() yearly: string;
  @Input() isAreaUploaded: boolean;
  data;
  electricId ;
  electrics = [];
  edit = 0;
  getSupplier = false;
  email;
  isSelected = 'AnnualConsumptionSelected';
  formatLabel(value: number) {
    // if (value >= 1000) {
      return value + ' kWh';

  }
  formatLabel2(value: number) {
    return value + ' CHF';
  }
  constructor(private appConstants: Constants,
              private homeService: HomeService,
              private  toastr: ToastrService,
              private cdr: ChangeDetectorRef) {
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.getSupplier = false;
    this.consumption = new HouseConsumptionNewV1Model();
    this.consumption.numPeople = 1;
    // this.consumption.YearlyElecRequired = 50000;
  }

  ngOnInit(): void {
    this.electrics = [];
    this.extraInput(Event);
    this.check();


  }
  ngOnChanges(changes: SimpleChanges) {
    // if (this.leadId && this.leadId !== '') {
    //   this.getElectricSupplier();
    // }
    if (this.isAreaUploaded) {
      if (this.leadId && this.leadId !== '') {
        this.getElectricSupplier();
      }
    }
  }


  public submitForm(): void {
    this.onSubmit.emit({
      value: this.consumption, electricId: this.electricId
    });
  }

  public skip1() {
    this.skip.emit({
      value: this.consumption
    });
  }

  public extraInput(event): void {
    this.extraInput1.emit({
      value: this.consumption,
      // runApi: !event
    });
  }

  getElectricSupplier() {
    console.log('consumption api working');
    this.electricId = 0;
    this.homeService.getElectricSupplier({
      email: this.email,
      lead_id: this.leadId,
      action: 'getsupplierdata'
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.electrics = this.data.ListofSuppliers;
      this.electricId  = parseInt(this.data.ListofSuppliers[0].elecID);
    }, error => {
      this.toastr.warning('Supplier list not found Please try again ', 'Oops');
      this.getSupplier = true;
    });
  }

  uploadElectricId() {

    this.homeService.uploadElectricId({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      elecID: this.electricId,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
    });
  }


  check() {
    if (this.isSelected === 'AnnualConsumptionSelected') {
      this.consumption.isMonthlyPriceSelected = false;
    } else if (this.isSelected === 'MonthlyPriceSelected') {
      this.consumption.isMonthlyPriceSelected = true;
    }
  }
}
