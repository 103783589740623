import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiEndPoints, Constants} from '../app.constant';

@Injectable({
  providedIn: 'root'
})
export class PriorityService {
  httpOptions;

  accessToken;
  email
  constructor(
    private http: HttpClient,
    private apiEndPoints: ApiEndPoints,
    private appConstants: Constants
  ) {
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.appConstants.accessToken.subscribe(next => {
      this.accessToken = next as string;
      this.httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.accessToken,
        })
      };
    });
  }

  getPriorityListItems(leadId) {
    const data = {
      email: this.email,
      lead_id: leadId,
      prioritylist: 'getlist',
      lang: localStorage.getItem('lang')
    }
    return this.http.post(this.apiEndPoints.GET_SOLAR_PRIORITY_URL, data, this.httpOptions);
  }

}
