import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {HomeService} from "../home/home.service";
import {Constants} from "../app.constant";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ResultDataService, ResultService} from "../result/result.service";
import {Router} from "@angular/router";
import {ConsumptionModelV2} from "./consumptionv2.model";

@Component({
  selector: 'app-consumptionv2',
  templateUrl: './consumptionv2.component.html',
  styleUrls: ['./consumptionv2.component.scss']
})
export class Consumptionv2Component implements OnInit {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() public consumption: ConsumptionModelV2;
  @Output() extraInput1: EventEmitter<any> = new EventEmitter<any>();
  @Input() yearly: string;
  @Output() electId: EventEmitter<any> = new EventEmitter<any>();
  email;
  stateList = [];
  tariffList = [];
  electricSuppliers = [];
  selectedState;
  selectedTariff;
  selectedSupplier;
  leadId;
  electricId = 0;
  electrics = [];
  getSupplier = false;
  rdata;
  data;
  showLoader = false;

  constructor(private homeService: HomeService,
              private appConstants: Constants,
              private toastr: ToastrService,
              private modalService: BsModalService,
              private bsModalRef: BsModalRef,
              private resultService: ResultService,
              private router: Router,
              private resultDataService: ResultDataService,
  ) {
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.appConstants.leadId.subscribe(value => {
      this.leadId = value as string;
    });
    this.consumption = new ConsumptionModelV2();
    this.getSupplier = false;
    this.consumption.numPeople = 1;

    // this.consumption.numPeople = 1;
  }

  ngOnInit(): void {
    this.electrics = [];
    this.extraInput(event);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.leadId && this.leadId !== '' && this.yearly && this.yearly !== '') {
      this.getElectricSupplier();
    }
  }
  getElectricSupplier() {
    console.log('consumption api working')
    this.electricId = 0;
    this.homeService.getElectricSupplier({
      email: this.email,
      lead_id: this.leadId,
      action: 'getsupplierdata'
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.electrics = this.data.ListofSuppliers;
      this.electricId  = parseInt(this.data.ListofSuppliers[0].elecID);
    }, error => {
      this.toastr.warning('Supplier list not found Please try again ', 'Oops');
      this.getSupplier = true;
    });
  }


  uploadElectricId() {

    this.homeService.uploadElectricId({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      elecID: this.electricId,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
    });
  }






  public extraInput(event): void {
    this.extraInput1.emit({
      value: this.consumption,
    });
  }



  getResult() {
    this.resultService.getResult().subscribe(res => {
      this.data = res as any;
      this.router.navigate(['resultv']);
      if (this.data.status) {
        this.resultDataService.setResult(this.data);
      }
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

  submitForm() {
    // this.getResult();
    if (this.consumption.numPeople && this.yearly) {
      this.onSubmit.emit({
        value: 'Average Consumption'
      });
    } else {
      this.toastr.warning('Please enter the details first!')
    }
    this.electId.emit({
      value: this.electricId
    });
  }


}
