<div class="container-fluid d-flex flex-row">


    <div class="row  sidebar d-flex flex-column">
      <div class="col d-flex flex-column align-items-start justify-content-center  navcol" (click)="stepChange(head.title)" [ngClass]="{'active' : activeStep === head.title }" *ngFor=" let head of headArr">
          <a>{{head.title}}</a>
        <div class="row">
          <div class="col-12">
            <span *ngIf="(activeStep === head.title || head.selected)" class="selectedOption" >{{head.value}}</span>
            <span *ngIf="activeStep === 'Average Consumption' && activeStep === head.title" class="selectedOption">{{head.numOfPeople}}</span>
            <span *ngIf="activeStep === 'Average Consumption' && activeStep === head.title" class="selectedOption" style="margin: 0 5px 0 5px">{{head.Consumption}}</span>
            <span *ngIf="activeStep === 'Average Consumption' && activeStep === head.title" class="selectedOption">{{head.Supplier}}</span>
          </div>
        </div>
      </div>
    </div>




    <div class="row main-container background"*ngIf="!showLoader" >
      <div class="col" >

      <ng-container *ngIf="activeStep === 'Location'">
        <app-locationv3 (onSubmit)="next($event.value)" (mapDetails)="receiveData($event)"></app-locationv3>
      </ng-container>


        <ng-container *ngIf="activeStep === 'Roof Area'">
          <app-roofareav3 (onSubmit)="next($event.value)"  (leadid)="receiveLeadId($event)"></app-roofareav3>
          <!--        [value]=""-->
        </ng-container>

        <ng-container *ngIf="activeStep === 'Hot Water'">
          <app-hotwaterv3 (onSubmit)="next($event.value)" (selectedData)="selectedDataHotWater($event)"></app-hotwaterv3>
        </ng-container>


        <ng-container *ngIf="activeStep === 'Heater'">
          <app-heaterv3 (onSubmit)="next($event.value)" [leadId]="this.leadId" [inputConsumption]="consumption" [hotWaterType]="this.hotWater" (selectedData)="selectedHeater($event)" ></app-heaterv3>
        </ng-container>

        <ng-container *ngIf="activeStep === 'Average Consumption'">
          <app-consumptionv3 [yearly]="yearly" (electId)="receiveElecId($event)" (supplierShortName)="receiveSupplierShortName($event)" (onSubmit)="next($event.value)" (extraInput1)="uploadExtraInput1($event)">
          </app-consumptionv3>
        </ng-container>

      </div>
    </div>

  <div class="result-bar d-flex align-items-center background" *ngIf="showLoader" style="height:100vh ;width: 100%; padding-left: 50px; padding-right: 50px"
       >
    <div class="container-fluid">
      <div class="row mb-5">
        <div class="col-12  ">
          <p class="fetch-result text-center" style="color: #3E494A; font-size: 35px; font-weight: bold">{{'Preparing-Results' | translate}}</p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <div class="progress" style="height: 37px">
            <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar"
                 style="width: 100%; background-color: #154A75 !important;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="text-center result-content" style="color: #677171; font-size: 17px">
            {{'content' | translate}}
            {{'content1' | translate}}
            {{'content2' | translate}}
            {{'content3' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>

</div>




