import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {HomeService} from "../home/home.service";
import {Constants} from "../app.constant";

@Component({
  selector: 'app-heaterv2',
  templateUrl: './heaterv2.component.html',
  styleUrls: ['./heaterv2.component.scss']
})
export class Heaterv2Component implements OnInit {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedData: EventEmitter<any> = new EventEmitter<any>();
  selectedHeaterEquipment;
  @Input() leadId;
  @Input() hotWaterType;
  @Input() inputConsumption;
  email;
  oldHeater;
  yearlyElectricAfterChange;
  consumption;
  constructor(private toastr: ToastrService,
              private homeService: HomeService,
              private appConstants: Constants,
  ) { this.appConstants.email.subscribe(value => {
    this.email = value as string;
  }); }

  ngOnInit(): void {}
  selectOption(value) {
    if (this.selectedHeaterEquipment === value) {
      this.selectedHeaterEquipment = '';
    } else {
      this.selectedHeaterEquipment = value;
    }
  }
  uploadExtraInput1() {
    console.log('inside extra input 1');
    console.log(this.consumption);
    this.oldHeater = this.selectedHeaterEquipment;
    this.homeService.uploadExtraInput({
      email: this.email,
      lead_id: this.leadId,
      info: {
        NumPeople: this.consumption.numPeople,
        Heating: this.selectedHeaterEquipment,
        Water: this.hotWaterType,
        Type: 'Residential',
      }
    }).subscribe(res => {
      const response = res as any;
      this.consumption.YearlyElecRequired = response.energy;
      this.yearlyElectricAfterChange = response.energy;
    }, error => {
      this.toastr.warning('Something went Wrong Please try again', 'Oops');
    });
  }


  // upload extra input
  uploadExtraInput() {
    console.log('inside extra input 2')

    this.homeService.uploadExtraInput({
      email: this.email,
      lead_id: this.leadId,
      info: {
        NumPeople: this.consumption?.numPeople,
        Heating: this.selectedHeaterEquipment,
        Water: this.hotWaterType,
        Type: 'Residential',
      }
    }).subscribe(res => {
      const response = res as any;
      this.consumption.YearlyElecRequired = response.energy;
      this.yearlyElectricAfterChange = response.energy;
    } );
  }

  submitForm() {
    // this.uploadExtraInput();

    if (this.selectedHeaterEquipment) {
      this.onSubmit.emit({
        value: 'Heater'
      });
      this.selectedData.emit({
        value: this.selectedHeaterEquipment
      });
    } else {
      this.toastr.warning('Please select an option first!')
    }
  }


}

