import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
// import { MetaGuard } from '@ngx-meta/core';
import { ResultComponent } from './result/result/result.component';
import { AddressComponent } from './address/address.component';
import { NewResultComponent } from './new-result/new-result.component';
import { ServiceErrorComponent } from './service-error/service-error.component';
import { HeaderComponent } from './header/header.component';
import { Resultv3Component } from "./resultv3/resultv3.component";
import { AddressNewV1Component } from "./address-new-v1/address-new-v1.component";
import { HomeNewV1Component } from "./home-new-v1/home-new-v1.component";
import { ResultNewV1Component } from "./result-new-v1/result-new-v1.component";
import { Resultv2Component } from "./resultv2/resultv2.component";
import { Homev3Component } from "./homev3/homev3.component";
import { Homev4Component } from './v4/homev4/homev4.component';
import { Homev5Component } from './v5/homev5/homev5.component';
const routes: Routes = [
  {
    path: '',
    component: AddressComponent,
    pathMatch: 'full'
  },
  {
    path: 'V1',
    component: AddressComponent,
    pathMatch: 'full'
  },
  {
    path: 'v1',
    component: AddressComponent,
    pathMatch: 'full'
  },
  {
    path: 'V2',
    component: HeaderComponent,
    pathMatch: 'full'
  },
  {
    path: 'v2',
    component: HeaderComponent,
    pathMatch: 'full'
  },
  {
    path: 'V3',
    component: Homev3Component,
    pathMatch: 'full'
  },
  {
    path: 'v3',
    component: Homev3Component,
    pathMatch: 'full'
  },
  {
    path: 'V4',
    component: Homev4Component,
    pathMatch: 'full'
  },
  {
    path: 'v4',
    component: Homev4Component,
    pathMatch: 'full'
  },
  {
    path: 'V5',
    component: Homev5Component,
    pathMatch: 'full'
  },
  {
    path: 'v5',
    component: Homev5Component,
    pathMatch: 'full'
  },
  {
    path: 'AGV2',
    component: AddressNewV1Component,
    pathMatch: 'full'
  },
  {
    path: 'agv2',
    component: AddressNewV1Component,
    pathMatch: 'full'
  },
  {
    path: 'fhnw',
    loadChildren: () => import('./fhnw/fhnw.module').then(m => m.FhnwModule)
  },
  {
    path: 'home',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'home-agv2',
    component: HomeNewV1Component,
    pathMatch: 'full'
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    pathMatch: 'full'
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionComponent,
    data: {
      meta: {
        title: 'Sweet home',
        description: 'Home, home sweet home... and what?'
      }
    },
    pathMatch: 'full'
  },
  // {
  //   path: 'result',
  //   canActivate: [],
  //   loadChildren: 'src/app/result/result.module#ResultModule',
  //   pathMatch: 'full'
  // },
  {
    path: 'result',
    component: NewResultComponent,
  },
  {
    path: 'resultagv2',
    component: ResultNewV1Component,
  },
  {
    path: 'resultv2',
    component: Resultv2Component
  },
  {
    path: 'resultv3',
    component: Resultv3Component
  },
  {
    path: 'service-error',
    component: ServiceErrorComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
