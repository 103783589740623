<section>
  <div class="justify-content-center text-center">
    <div class="row mt-5 p-3 mb-2">
      <div class="col">
        <span class="dtext">{{'Consumption.header' | translate}}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-form-label">
        <div class="form-check">
          <input class="form-check-input" (change)="check()" [(ngModel)]="isSelected" checked
            value="AnnualConsumptionSelected" type="radio" name="isAnnualConsumptionSelected" id="flexRadioDefault1">
          <label class="form-check-label" for="flexRadioDefault1">
            {{'Consumption.yearly-consumption-new' | translate}}
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isSelected === 'AnnualConsumptionSelected'">
      <div class="col-12 mb-3 col-form-label d-flex justify-content-center">

        <input type="number" class="form-control w-25 searchbar " [ngModelOptions]="{standalone: true}"
          [(ngModel)]="consumption.YearlyElecRequired" placeholder="{{'Consumption.yearly-consumption' | translate}}"
          (mousewheel)="$event.preventDefault()">
      </div>

    </div>

    <div class="row" *ngIf="isSelected === 'AnnualConsumptionSelected'">
      <div class="col-12 col-form-label ">
        <mat-slider class="w-25" thumbLabel [(ngModel)]="consumption.YearlyElecRequired" [displayWith]="formatLabel"
          tickInterval="1000" step="5000" min="0" max="500000 " aria-label="unit(s)"></mat-slider>
      </div>
    </div>
  </div>

  <div class="container-fluid form-group d-flex flex-column ">
    <div class="row">
      <div class="col-12 text-center">
        <div class="form-check">
          <input class="form-check-input" (change)="check()" [(ngModel)]="isSelected" value="MonthlyPriceSelected"
            type="radio" name="isMonthlyPriceSelected" id="flexRadioDefault2">
          <label class="form-check-label" for="flexRadioDefault2">
            {{'Consumption.monthly-electricity-cost' | translate}}
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isSelected === 'MonthlyPriceSelected'">
      <div class="col-12 mb-3 col-form-label d-flex justify-content-center text-center">

        <input type="number" class="form-control w-25 searchbar" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="consumption.monthlyElecCost" placeholder="{{'Consumption.monthly-electricity-cost' | translate}}"
          (mousewheel)="$event.preventDefault()">
      </div>

    </div>

    <div class="row" *ngIf="isSelected === 'MonthlyPriceSelected'">
      <div class="col-12 col-form-label text-center d-flex justify-content-center text-center">
        <mat-slider class="w-25" thumbLabel [(ngModel)]="consumption.monthlyElecCost" [displayWith]="formatLabel2"
          tickInterval="50" step="50" min="0" max="5000 " aria-label="unit(s)"></mat-slider>
      </div>
    </div>
  </div>
  <div class="row heading mt-3  py-2">
    <div class="col-12 text-center">
      <span class="htext">{{'Consumption.question3' | translate}}</span>
    </div>
  </div>
  <div class="row mt-4 ">
    <div class="col-12 text-center">
      
      <button *ngIf="electrics.length===0" class="btn btn-block btn-light text-center spinner-button searchbar w-25"
        type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
        </span>
        Loading...
      </button>
      <select *ngIf="electrics.length>0" id="electricSupp" id="electricSupp"
        class="form-control text-center searchbar w-25" [ngModelOptions]="{standalone: true}"
        (change)="getElectricSupplierCode(electricId)" [(ngModel)]="electricId" type="text">
        <option *ngIf="!electricId" [value]="0" selected hidden>Select Energy Supplier</option>
        <option *ngFor="let electric of electrics" [value]="electric.elecID">{{ electric.Name }}</option>
      </select>
    </div>
  </div>

  <div class="mt-3 text-center d-flex justify-content-center">
    <button type="button" style="font-size: 17px" class="btn btn-secondary" (click)="submitForm()">{{'Continue' |
      translate}}</button>
  </div>

</section>