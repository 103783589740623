import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/app.constant';
import { HeaderModel } from 'src/app/header/header.model';
import { HomeService } from 'src/app/home/home.service';
import { Homev3Model, Homev5Model } from 'src/app/homev3/homev3.model';
import { Quotev3Component } from 'src/app/quotev3/quotev3.component';
import { ResultService, ResultDataService } from 'src/app/result/result.service';

@Component({
  selector: 'app-homev5',
  templateUrl: './homev5.component.html',
  styleUrls: ['./homev5.component.scss']
})
export class Homev5Component implements OnInit {
  bsModalRef: BsModalRef;
  activeStep = 'Location';
  isVisible = true;
  showLoader = false;
  leadId;
  consumption;
  surfaceArea;
  hotWater;
  heaterType;
  searchedlocation;
  yearly;
  email;
  yearlyElectricAfterChange;
  data;
  isResultApiCompleted = false;
  contactFormDetails: any;
  filtered;
  model: Homev5Model = {
    email: '',
    token: '',
    leadId: '',
    roofArea: 0,
    // longitude: any;
    // latitude: any;
    roofSurfaceNumber: 0,
    roofPitch: 0,
    alignment: 0,
    consumption: {
      numPeople: 0,
      YearlyElecRequired: 50000,
      monthlyElecCost: 150,
      isMonthlyPriceSelected: false
    },
    hotWaterType: '',
    heaterType: '',
    electric: 0,
    location: null,
  };
  electricId;
  electrics = [];
  headArr = [
    {title: 'Location', selected: false, value: '...'},
    {title: 'Roof Area', selected: false, value: '...'},
    // {title: 'Hot Water', selected: false, value: '...'},
    // {title: 'Heater', selected: false, value: '...'},
    {title: 'Average Consumption', selected: false, Consumption: '...', Supplier: '...'}
  ];

  constructor(private homeService: HomeService,
              private appConstants: Constants,
              private toastr: ToastrService,
              private translate: TranslateService,
              private loader: NgxSpinnerService,
              private resultService: ResultService,
              private router: Router,
              private resultDataService: ResultDataService,
              private modalService: BsModalService,
  ) {
    // @ts-ignore
    window?.angularComponentRef = {component: this, zone: Zone};
    this.model = new Homev5Model();
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
  }

  ngOnInit(): void {
  }
  stepChange(step) {
    this.filtered = this.headArr.filter((item) => item.title === step);
    console.log(this.filtered)
    if (this.filtered[0].selected) {
      this.filtered[0].value = '...';
      return this.activeStep = step;
    }
  }
  next(step) {
    // console.log(step, 'step');
    if (step.value === 'Location') {
      this.activeStep = 'Roof Area';
      this.isVisible = true;
      this.headArr[0].selected = true;
      this.searchedlocation = localStorage.getItem('locationName');
      console.log(this.searchedlocation, typeof (this.searchedlocation));
      this.headArr[0].value = this.searchedlocation;
      console.log(this.headArr[0].value);
    } else if (step.value === 'Roof Area') {
      this.activeStep = 'Average Consumption';
      this.isVisible = true;
      this.headArr[1].selected = true;
    } else if (step.value === 'Average Consumption') {
      this.showLoader = true;
      this.isVisible = true;
      this.headArr[2].selected = true;
      console.log(this.model, 'model')
      if (step.isMonthlySelected === false) {
        console.log('1')
        this.uploadYearlyElectric(step.YearlyElecRequired);
      } else if (step.isMonthlySelected === true) {
        // this.verifyUploadEnergy();
        console.log('2')
        this.uploadElectricIdMonthly(step.isMonthlySelected, step.monthlyElecCost);
      }
      this.updateContactInformations();

    } else {
      this.isVisible = true;
    }
    // else if (step === 'Motivation') {
    //   this.isVisible = true;
    // }
  }
  
  callSurfaceArea(event) {
    console.log(event, 'EVEN');
    this.homeService.surfaceInfo({
      email: this.email,
      lead_id: this.model.leadId,
      latitude: parseFloat(event.latitude),
      longitude: parseFloat(event.longitude),
      locationName: event.locationName,
      CountryCode: event.CountryCode,
      Postal: event.Postal,
      'map address': event['map address'],
      tilt: event.tilt,
      azimuth: event.azimuth,
      numsurfaces: event.numsurfaces ? 2 : 1,
      surfacearea: event.surfacearea
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.uploadMannualArea(event.manual_area);
      if ( Object.keys(event.surfacearea).length !== 0) {
      this.toastr.success(this.translate.instant('Toast.Area-successfully-selected'));
      } else {
        this.toastr.warning('Please select a roof first!');
      }
    });
  }
  uploadMannualArea(manual_area) {
    this.homeService.uploadMannulaArea({
      email: this.email,
      lead_id: this.leadId,
      manual_area: manual_area
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.uploadExtraInput1();
    });
  }
  uploadExtraInput1() {

    console.log('first working');
    console.log(this.heaterType);
    // this.model.consumption = event.value;
    this.homeService.uploadExtraInput({
      email: this.email,
      lead_id: this.leadId,
      info: {
        NumPeople: 4,
        Water: "OL",
        Heating: "EL",
        Type: 'Residential',
      }
    }).subscribe(res => {
      const response = res as any;
      console.log(this.model.consumption, 'consumption')
      this.model.consumption.YearlyElecRequired = response.energy;
      this.yearlyElectricAfterChange = response.energy;
      this.headArr[2].Consumption = response.energy.toString() + 'kW';
      this.getElectricSupplier();
    }, error => {
      this.toastr.warning('Something went Wrong Please try again', 'Oops');
    });
  }

  getElectricSupplier() {
    console.log('consumption api working');
    this.electricId = 0;
    this.loader.show
    this.homeService.getElectricSupplier({
      email: this.email,
      lead_id: this.leadId,
      action: 'getsupplierdata'
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.electrics = this.data.ListofSuppliers;
      this.loader.hide;
      // this.electricId  = parseInt(this.data.ListofSuppliers[0].elecID);
    }, error => {
      this.toastr.warning('Supplier list not found. Please try again.', 'Oops');
    });
}
  receiveData(data): void {
    this.model.email = data.email;
    this.model.token = data.token;
    this.model.leadId = data.lead;
    // this.model.longitude = data.lat;
    this.model.location = data.location;
    this.model.roofPitch = data.pitch;
    this.model.alignment = data.alignAngle;
  }

  receiveLeadId(data) {
    this.leadId = data.value;
    this.model.leadId = data.value;
    this.surfaceArea = data.surfaceArea["Area0"];
    this.headArr[1].value = this.surfaceArea + ' m2 ';
  }

  selectedDataHotWater(data) {
    console.log(data.value);
    this.hotWater = data.value;
    if (this.hotWater === 'EL') {
      this.headArr[2].value = 'Electric Heater';
    } else if (this.hotWater === 'WP') {
      this.headArr[2].value = 'Heat Pump Boiler';
    } else if (this.hotWater === 'OL') {
      this.headArr[2].value = 'Oil/Gas/Wood';
    }
  }

  selectedHeater(data) {
    this.heaterType = data.value;
    console.log(this.heaterType);
    if (this.heaterType === 'EL') {
      this.headArr[3].value = 'Electric Heater';
    } else if (this.heaterType === 'WP') {
      this.headArr[3].value = 'Heat Pump';
    } else if (this.heaterType === 'OL') {
      this.headArr[3].value = 'Oil/Gas/Wood';
    }
  }

  

  uploadExtraInput() {
    setTimeout(() => {
      console.log('second working');
      console.log(this.heaterType);
      this.homeService.uploadExtraInput({
        email: this.email,
        lead_id: this.leadId,
        info: {
          NumPeople: this.model.consumption?.numPeople,
          Water: this.hotWater,
          Heating: this.heaterType,
          Type: 'Residential',
        }
      }).subscribe(res => {
        const response = res as any;
        this.model.consumption.YearlyElecRequired = response.energy;
        this.yearlyElectricAfterChange = response.energy;
        this.headArr[2].Consumption = this.model.consumption.YearlyElecRequired.toString() + 'kW';
      });
    }, 200);
  }


  uploadYearlyElectric(yearlyElec) {
    this.homeService.uploadYearlyElectric({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      energy: yearlyElec,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.yearly = this.data.YearlyElecRequired;
      this.headArr[2].Consumption = yearlyElec;
      this.uploadElectricIdYearly();
      if (this.yearly === undefined || this.yearly === '') {
        this.toastr.warning('There is no Suppliers in your Location Sorry!', 'Oops');
      }
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Yearly-Electric-not-updated-Please-try-again'));
    });
  }

  verifyUploadEnergy() {
    console.log('verifyuploadenegy api working')
    this.homeService.uploadYearlyElectric({
      email: this.email,
      lead_id: this.leadId,
      action: 'getenergydata',
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.getResultV2();
      // if (response.YearlyElecRequired.TotalElec === this.model.consumption.YearlyElecRequired) {
      //   this.uploadElectricId();
      // }
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Yearly-Electric-not-updated-Please-try-again'));
    });
  }
  uploadElectricIdYearly() {
    this.homeService.uploadElectricId({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      elecID: this.model.electric,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.verifyUploadEnergy();
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Supplier-not-updated-Please-try-again'));
    });
  }

  uploadElectricIdMonthly(monthlySelected, monthlyElecCost) {
    //timeeout is added because elecId should come before this api is called
    setTimeout(()=> {
      this.homeService.uploadElectricId({
        email: this.email,
        lead_id: this.leadId,
        action: 'upload',
        elecID: this.model.electric,
        ebillmonthly: monthlySelected ? monthlyElecCost : undefined
      }).subscribe(res => {
        const response = res as any;
        this.headArr[2].Consumption = monthlyElecCost;
        this.data = response;
        this.getResultV2();
      }, error => {
        this.toastr.warning(this.translate.instant('Toast.Supplier-not-updated-Please-try-again'));
      });
    }, 1000)
   
  }

  contactFormSubmit(myData) {
    myData.data.customercontact.lead_id = this.model.leadId;
    this.homeService.updateContactInformations(myData.data).subscribe(reply => {
      const response = reply as any;
      this.bsModalRef.hide();
      this.sendLeadEmail();
      this.toastr.success('Quotation upload successfully');
      // this.router.navigate(['result', 'independence']);

    }, error => {
      this.toastr.warning('Contact Information not updated Please try again', 'Oops');
    });
  }

  sendLeadEmail() {
    const data = {
      email: this.email,
      lead_id: this.model.leadId,
      lang: 'de',
    };
    this.homeService.sendLeadEmail(data).subscribe(reply => {
      const response = reply as any;
    }, error => {
      this.toastr.warning('Something went wrong', 'Oops');
    });
  }

  getResultV2() {
    this.loader.hide();
    this.resultService.getResultV2().subscribe(res => {
      this.data = res as any;
      if (this.isResultApiCompleted) {
        this.contactFormSubmit(this.contactFormDetails);
      } else {
        this.isResultApiCompleted = true;
      }
      this.router.navigate(['resultv3']);
      if (this.data.status) {
      }
      this.resultDataService.setResult(this.data);
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

  startTimer() {

    this.showLoader = true;
  }

  updateContactInformations() {
    this.startTimer();
    const initialState = {
      title: 'Contact',
      confirmed: false,
      class: 'modal-lg',
    };
    this.bsModalRef = this.modalService.show(Quotev3Component, {initialState});

    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose = (myData) => {
      this.contactFormDetails = myData;
      if (this.isResultApiCompleted) {
        this.contactFormSubmit(this.contactFormDetails);
      } else {
        this.bsModalRef.hide();
        this.isResultApiCompleted = true;
      }
    };
  }

  receiveElecId(event) {
    this.model.electric = event.value;
  }
  receiveSupplierShortName(event) {
    console.log(event);
    this.headArr[2].Supplier = event.value;
  }

}
