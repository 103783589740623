<div class="container-fluid">
  <!--  <div class=" justify-content-center pt-4 text-center">-->
  <!--    <div class="row mt-5 p-3">-->
  <!--      <div class="col "><span class="dtext">{{'typical-day.label1' | translate }}</span></div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--switch toggler-->
  <div class="row mt-5">
    <div class="col-6 text-center">
      <div class="form-check">
        <input class="form-check-input input" [(ngModel)]="storage" (change)="changeSeason()" value="PV-without-storage"
               type="radio" name="flexRadioDefault"
               id="flexRadioDefault1">
        <label class="form-check-label label1" for="flexRadioDefault1">
          {{'pv-without-storage' | translate }}
        </label>
        <span><i class="fas fa-info-circle" tooltip="Photovoltaic system without battery"> </i></span>
      </div>

    </div>

    <div class="col-6 text-center ">
      <div class="form-check">
        <input class="form-check-input input" [(ngModel)]="storage" (change)="changeSeason()" value="PV-with-storage"
               type="radio" name="flexRadioDefault"
               id="flexRadioDefault2">
        <label class="form-check-label label1" for="flexRadioDefault2">
          {{'PV-with-storage' | translate }}
        </label>
        <span><i class="fas fa-info-circle" tooltip="Photovoltaic system with battery"> </i></span>
      </div>
    </div>

  </div>

  <!--  second row of switches-->
  <div class="row my-5 toggle">
    <div class=" col-6 col-lg-3 justify-content-center d-flex align-items-center">
      <ui-switch size="small" color="yellow" [(ngModel)]="showProduction"
                 (change)="toggleDataSeries('production')"></ui-switch>
      <label class="slabel">{{'typical-day.production' | translate }}[kWh]</label>
    </div>
    <div class="col-6 col-lg-3 justify-content-center  d-flex align-items-center">
      <ui-switch size="small" [(ngModel)]="showConsumption"
                 (change)="toggleDataSeries('consumption')" color="green"></ui-switch>
      <label class="slabel">{{'typical-day.consumption' | translate }}[kWh]</label>
    </div>
    <div class="col-6 col-lg-3 justify-content-center  d-flex align-items-center">
      <ui-switch size="small" [disabled]="disableBattery" [(ngModel)]="showBattery"
                 (change)="toggleDataSeries('battery')" color="blue"></ui-switch>
      <label class="slabel">{{'typical-day.battery' | translate }}[kWh]</label>
    </div>
    <div class="col-6 col-lg-3 justify-content-center  d-flex align-items-center">
      <ui-switch size="small" [(ngModel)]="showIndependence"
                 checked="showIndependence" (change)="toggleDataSeries('independence')" color="gray"></ui-switch>
      <label class="slabel">{{'typical-day.independence' | translate }}(%)</label>
    </div>
  </div>

  <!--  container of graph-->
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div class="graph">
              <div class=" row all-season justify-content-end">
                <div class="col-4 d-flex pb-4">
                  <kendo-dropdownlist class="kendo form-control"
                                      [data]="listItems"
                                      textField="text"
                                      valueField="value"
                                      [valuePrimitive]="true"
                                      (valueChange)="changeSeason()"
                                      [(ngModel)]="season">
                  </kendo-dropdownlist>
                </div>
              </div>
              <canvas baseChart #baseChart="base-chart" style="margin: auto"
                      [datasets]="lineChartData"
                      [labels]="lineChartLabels"
                      [options]="lineChartOptions"
                      [colors]="lineChartColors"
                      [legend]="lineChartLegend"
                      [chartType]="lineChartType"
                      [plugins]="lineChartPlugins">
              </canvas>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- container of mobile graph-->

</div>
