import { Component, OnInit } from '@angular/core';
import {HomeService} from '../home/home.service';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../app.constant';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {DialogContactComponent} from '../dialog-contact/dialog-contact.component';
import {ResultDataService, ResultService} from '../result/result.service';
import {ChartDataSets, ChartOptions} from "chart.js";
import {Color, Label} from "ng2-charts";
import {ActivatedRoute} from "@angular/router";
import {DialogContactAgv2Component} from "../dialog-contact-agv2/dialog-contact-agv2.component";

interface Item {
  text: string;
  value: string;
}

@Component({
  selector: 'app-result-new-v1',
  templateUrl: './result-new-v1.component.html',
  styleUrls: ['./result-new-v1.component.scss']
})
export class ResultNewV1Component implements OnInit {
  storage = 'PV-without-storage';
  convertLang;
  isBatteryEnabled = false;
  data;
  radius;
  seasonValue;
  directUse;
  leadId;
  toNet;
  toNetWBatt;
  selfComsumptionWBatt;
  solarToBattery;
  KWAC;
  dcPower;
  battkWh;
  receievedTotEnergy;
  totEnergy;
  yearPercent;
  recievedInvestment;
  investment;
  payback;
  mainColor = '#707070';
  secondaryColor = '#D9DDDF';
  seasonLogo;
  battery;
  searchLocation: any;
  location: string;
  houserNumber: string;
  street: string;
  bsModalRef: BsModalRef;
  seasonSelected = 'yearly';
  email;
  translatedSeason;
  headArr = [{title: 'Independence'},
    {title: 'Consumption'},
    {title: 'Financial'},
    {title: 'A Typical Day'},
    {title: 'Sustainability'},
    {title: 'How Further'},
  ];
  isVisible = true;
  contactFormDetails: any;
  public listItems: Array<Item> = [
    {text: this.translate.instant('season.whole-year'), value: 'yearly'},
    {text: this.translate.instant('season.spring'), value: 'spring'},
    {text: this.translate.instant('season.summer'), value: 'summer'},
    {text: this.translate.instant('season.autumn'), value: 'autumn'},
    {text: this.translate.instant('season.winter'), value: 'winter'}
  ];

  public circleSeasonList = {
    yearly: 'year',
    spring: 'spring',
    summer: 'summer',
    winter: 'winter',
    autumn: 'autumn'
  };

  public season = 'yearly';

  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: this.translate.instant('result-agv2.Solar-Production') + '[kWh]',
      fill: 'origin'
    },
    {
      data: [],
      label: this.translate.instant('result-agv2.Self-Consumption-graph') + '[kWh]',
      fill: 'origin'
    },
    {
      data: [],
      label: this.translate.instant('result-agv2.Battery-charging') + '[kWh]',
      fill: 'origin'
    },
    {
      data: [],
      label: this.translate.instant('result-agv2.Independence') + '[kWh]',
      fill: 'origin',
      yAxisID: 'y-axis-1'
    }
  ];
  // disableBattery = false;
  public solarValue = [];
  private selfComsumption = [];
  public lineChartLabels: Label[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];


  // public linesChartData: ChartConfiguration['data'] = {
  //   datasets: [
  //     {
  //       data: [ 65, 59, 80, 81, 56, 55, 40 ],
  //       label: 'Titan',
  //       backgroundColor: 'rgba(148,159,177,0.2)',
  //       borderColor: 'rgba(148,159,177,1)',
  //       pointBackgroundColor: 'rgba(148,159,177,1)',
  //       pointBorderColor: '#fff',
  //       pointHoverBackgroundColor: '#fff',
  //       pointHoverBorderColor: 'rgba(148,159,177,0.8)',
  //       fill: 'origin',
  //     }
  //   ],
  // };


  public lineChartOptions: (ChartOptions) = {

    legend: {
      position: 'bottom',
      display: true
    },

    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: this.translate.instant('result-agv2.Hours')
        }
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            fontColor: 'gray',
            min: 0,
          },
          scaleLabel: {
            display: true,
            labelString: 'kW'
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          ticks: {
            fontColor: 'gray',
            min: 0,
            max: 100,
          },
          scaleLabel: {
            display: true,
            labelString: '%'
          },
          gridLines: {
            display: false
          }
        }
      ]
    },
  };
  public lineChartColors: Color[] = [
    {borderColor: 'yellow'},
    {borderColor: 'green'},
    {borderColor: 'blue'},
    {borderColor: 'gray'}
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  constructor(private translate: TranslateService,
              private homeService: HomeService,
              private route: ActivatedRoute,
              private resultDataService: ResultDataService,
              private appConstants: Constants,
              private modalService: BsModalService,
              private  toastr: ToastrService,
  ) {
    this.appConstants.leadId.subscribe(value => {
      this.leadId = value as string;
    });
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.convertLang = localStorage.getItem('lang');
    this.resultDataService.result.subscribe(res => {
      this.data = res as any;
    });
    this.radius = 65;

    this.appConstants.searchLocation.subscribe(value => {
      this.searchLocation = value;
      console.log(this.searchLocation, 'this is location searched');
      this.location = this.searchLocation[0];
      const splitLocation = this.location.split(',');
      console.log(splitLocation);
      this.houserNumber = splitLocation[0];
      this.street = splitLocation[1];
      console.log(this.houserNumber, this.street, 'frank')
    });

  }

  ngOnInit(): void {
    this.convertLanguage();
    this.changeSeason();

  }

  changeSeasonTranslation(season) {
     if (season === 'yearly') {
      this.translatedSeason = this.translate.instant('season.whole-year');
    }
     if (season === 'summer') {
      this.translatedSeason = this.translate.instant('season.summer');
    }
     if (season === 'winter') {
      this.translatedSeason = this.translate.instant('season.winter');
    }
     if (season === 'autumn') {
      this.translatedSeason = this.translate.instant('season.autumn');
    }
     if (season === 'spring') {
      this.translatedSeason =  this.translate.instant('season.spring');
    }
  }

  changeSeason() {
    if (this.storage === 'PV-without-storage') {

      this.changeSeasonTranslation(this.season);
      // for season circle progress bar

      this.yearPercent = Math.round(this.data?.Output.independance[this.circleSeasonList[this.season]]);
      if (this.circleSeasonList[this.season] === 'year') {
        this.seasonSelected = 'year';
        this.mainColor = '#707070';
        this.secondaryColor = '#D9DDDF';
        this.seasonLogo = './assets/season/year.png';
      }
      if (this.circleSeasonList[this.season] === 'spring') {
        this.seasonSelected = 'spring';
        this.mainColor = '#2F9E6A';
        this.secondaryColor = '#CEF1D3';
        this.seasonLogo = './assets/season/spring.png';
      }
      if (this.circleSeasonList[this.season] === 'summer') {
        this.seasonSelected = 'summer';
        this.mainColor = '#FFCC00';
        this.secondaryColor = '#FFF3C2';
        this.seasonLogo = './assets/season/summer.png';
      }
      if (this.circleSeasonList[this.season] === 'winter') {
        this.seasonSelected = 'winter';
        this.mainColor = '#64C7DC';
        this.secondaryColor = '#D3EFF5';
        this.seasonLogo = './assets/season/winter.png';
      }
      if (this.circleSeasonList[this.season] === 'autumn') {
        this.seasonSelected = 'autumn';
        this.mainColor = '#B16848';
        this.secondaryColor = '#E9D5CB';
        this.seasonLogo = './assets/season/autumn.png';
      }

      // for the graph

      this.solarValue = Object.values(this.data?.Output.Solar[this.season]);
      this.toNet = Object.values(this.data?.Output.Independance[this.season]);
      this.selfComsumption = Object.values(this.data?.Output.SelfConsumption[this.season]);
      this.lineChartData[0].data = this.solarValue;
      this.lineChartData[1].data = this.selfComsumption;
      this.lineChartData[2].data = [];
      this.lineChartData[3].data = this.toNet;

      // for the data
      this.payback = Math.round(this.data.Output.Financial.PVNoStorage.PaybackTime);
      this.KWAC = Math.round(this.data.Output.kWAC);
      this.dcPower = Math.round(this.data.Output.DCPower);
      this.recievedInvestment = Math.round(this.data.Output.Financial.PVNoStorage.Investment);
      this.investment = new Intl.NumberFormat('en-US').format(this.recievedInvestment);
      this.receievedTotEnergy = Math.round(this.data.Output.TotEnergy / 1000);
      this.totEnergy =  new Intl.NumberFormat('en-US').format(this.receievedTotEnergy);
      this.convertToTenergyformat(this.totEnergy);
      this.convertCurrencyFormat(this.investment);

    } else if (this.storage === 'PV-with-storage') {
      this.changeSeasonTranslation(this.season);
      // for season circle progress bar
      this.yearPercent = Math.round(this.data?.Output.independanceWbatt[this.circleSeasonList[this.season]]);
      console.log(this.yearPercent, this.season);

      if (this.circleSeasonList[this.season] === 'year') {
        this.seasonSelected = 'year';
        this.mainColor = '#707070';
        this.secondaryColor = '#D9DDDF';
        this.seasonLogo = './assets/season/year.png';
      }
      if (this.circleSeasonList[this.season] === 'spring') {
        this.seasonSelected = 'spring';
        this.mainColor = '#2F9E6A';
        this.secondaryColor = '#CEF1D3';
        this.seasonLogo = './assets/season/spring.png';
      }
      if (this.circleSeasonList[this.season] === 'summer') {
        this.seasonSelected = 'summer';
        this.mainColor = '#FFCC00';
        this.secondaryColor = '#FFF3C2';
        this.seasonLogo = './assets/season/summer.png';
      }
      if (this.circleSeasonList[this.season] === 'winter') {
        this.seasonSelected = 'winter';
        this.mainColor = '#64C7DC';
        this.secondaryColor = '#D3EFF5';
        this.seasonLogo = './assets/season/winter.png';
      }
      if (this.circleSeasonList[this.season] === 'autumn') {
        this.seasonSelected = 'autumn';
        this.mainColor = '#B16848';
        this.secondaryColor = '#E9D5CB';
        this.seasonLogo = './assets/season/autumn.png';
      }
      // for the graph

      this.solarValue = Object.values(this.data?.Output.Solar[this.season]
      )
      ;
      this.toNetWBatt = Object.values(this.data?.Output.IndependanceWBatt[this.season]
      )
      ;
      this.selfComsumptionWBatt = Object.values(this.data?.Output.SelfConsumptionWBatt[this.season]
      )
      ;
      this.solarToBattery = Object.values(this.data?.Output.SolartoBattery[this.season]
      )
      ;
      this.lineChartData[0].data = this.solarValue;
      this.lineChartData[1].data = this.selfComsumptionWBatt;
      this.lineChartData[2].data = this.solarToBattery;
      this.lineChartData[3].data = this.toNetWBatt;

      // for the data
      this.payback = Math.round(this.data.Output.Financial.PVStorage.PaybackTime);
      this.KWAC = Math.round(this.data.Output.kWAC);
      this.dcPower = Math.round(this.data.Output.DCPower);
      this.receievedTotEnergy = Math.round(this.data.Output.TotEnergy / 1000);
      this.totEnergy =  new Intl.NumberFormat('en-US').format(this.receievedTotEnergy);
      this.convertToTenergyformat(this.totEnergy);
      this.recievedInvestment = Math.round(this.data.Output.Financial.PVStorage.Investment);
      this.investment = new Intl.NumberFormat('en-US').format(this.recievedInvestment);
      this.convertCurrencyFormat(this.investment);
    }
  }
  convertToTenergyformat(value) {
    this.totEnergy =  String(value)
      .replace(/,/g,  '\'')
      .replace(/\./g, '1000');
  }
  convertCurrencyFormat(value) {
    this.investment =  String(value)
      .replace(/,/g,  '\'')
      .replace(/\./g, '1000');
  }

  toggleBattery(event) {
    this.isBatteryEnabled = event;
    console.log(this.isBatteryEnabled);
    if (!this.isBatteryEnabled) {
      this.storage = 'PV-without-storage';
    } else {
      this.storage = 'PV-with-storage';
    }
    console.log(this.storage)
    this.changeSeason();
  }

  convertLanguage() {
    // this.convertLang = localStorage.getItem('lang');
    if (this.convertLang === null || this.convertLang === '') {
      localStorage.setItem('lang', 'en');
      this.translate.use(this.homeService.getLanguage());
      // console.log('IF', this.convertLang)
    } else {
      this.translate.use(this.convertLang);
    }
  }
  sendLeadEmail() {
    const data = {
      altrecipient: this.email,
      lead_id: this.leadId,
      lang: "de",
    }
    this.homeService.sendLeadEmail(data).subscribe(reply => {
      const response = reply as any;
    }, error => {
      // this.toastr.warning('Something went wrong', 'Oops');
    });
  }


  createQuotation() {
    const initialState = {
      title: 'Conatct',
      confirmed: false,
      class: 'modal-lg'
    };
    this.bsModalRef = this.modalService.show(DialogContactAgv2Component, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose = (myData) => {
      myData.data.customercontact.lead_id  = this.leadId;
      this.homeService.updateContactInformations(myData.data).subscribe(reply => {
        const response = reply as any;
        this.bsModalRef.hide();
        this.sendLeadEmail();
        this.toastr.success(this.translate.instant('Toast.Quotation-upload-successfully'), this.translate.instant('Toast.Success'), {
          timeOut: 3000,
        });
      }, error => {
        this.toastr.warning('Contact Information not updated Please try again', 'Oops');
      });
    };

  }
}
