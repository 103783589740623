<div class="container-fluid wrapper-address" (click)="processChange($event)" >
  <div class="row wrapper-row justify-content-center d-flex  align-items-center">
    <div class="col-12">
      <div class="address-page " >
        <div class="title mb-4 text-center">{{'address.solar' | translate}}</div>
        <div class="content mt-4 text-center mb-4 px-5">
          <p>{{'address.content' | translate}}</p>
        </div>
        <div class="search input-group mt-4 mb-3">
          <input type="text" class=" form-control"  style="height: 60px" [(ngModel)]="searchLocation"  (keyup)="valuechange($event)" placeholder="{{'address.placeholder' | translate}}"  (input)="location()" ngx-google-places-autocomplete [options]='options'  (onAddressChange)="handleAddressChange($event)"  #placesRef="ngx-places" aria-label="Recipient's username" aria-describedby="basic-addon2">
          <!--<small class="form-text text-danger" *ngIf="place && searchLocation != ''">This service is provided only in Switzerland </small>-->
          <div class="input-group-append"  style="height: 60px">
            <span class="input-group-text" id="basic-addon2"><img  style="height: 50px" src="./assets/search.png"  (click)="homePage()"></span>
          </div>
        </div>
        <small class="form-text text-danger text-center" *ngIf="place && searchLocation != ''">{{'Toast.This-service-is-provided-only-in-Switzerland' | translate}} </small>
      </div>
    </div>
  </div>
</div>
