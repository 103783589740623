import {ConsumptionModel} from "../consumptionv3/Consumption.model";
import { ConsumptionModelV5 } from "../v5/consumptionv5/consumption.model";

class Homev3Model {
  email: string;
  token: any;
  leadId: string;
  roofArea: number;
  // longitude: any;
  // latitude: any;
  roofSurfaceNumber: number;
  roofPitch: number;
  alignment: number;
  consumption: ConsumptionModel;
  hotWaterType: string;
  heaterType: string;
  electric: number;
  location: any;
}
class Homev5Model {
  email: string;
  token: any;
  leadId: string;
  roofArea: number;
  // longitude: any;
  // latitude: any;
  roofSurfaceNumber: number;
  roofPitch: number;
  alignment: number;
  consumption: ConsumptionModelV5 = {
    numPeople: 0,
    YearlyElecRequired: 50000,
    monthlyElecCost: 150,
    isMonthlyPriceSelected: false
  }; 
  hotWaterType: string;
  heaterType: string;
  electric: number;
  location: any;
}
export  {
  Homev3Model,
  Homev5Model
};
