import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute , Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/app.constant';
import { HomeService } from 'src/app/home/home.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-location-v5',
  templateUrl: './location-v5.component.html',
  styleUrls: ['./location-v5.component.scss']
})
export class LocationV5Component implements OnInit {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmit1: EventEmitter<any> = new EventEmitter<any>();
  @Output() mapDetails: EventEmitter<any> = new EventEmitter<any>();
  input = document.getElementsByClassName('pac-item');

  // @Input() rfunction;
  lang;
  lat;
  data;
  place = false;
  searchLocation;
  accessToken;
  location1;
  locationName;
  postalCode;
  convertLang;
  countryCode;
  surfaceData = [];
  origin;
  accessTokenPreProd
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options = {
    type: [],
    componentRestrictions: {country: 'CH'}
  };

  constructor(private appConstants: Constants,
              private toastr: ToastrService,
              private homeService: HomeService,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private locations: Location,
              private http: HttpClient,
  ) {
    this.appConstants.accessToken.subscribe(value => {
      this.accessToken = value as string;
    });
    this.appConstants.lang = this.route.snapshot.queryParamMap.get('lang');
    this.origin = this.route.snapshot.queryParamMap.get('origin');
    localStorage.setItem('lang', this.route.snapshot.queryParamMap.get('lang') );
    localStorage.setItem('origin', this.origin );
    this.convertLang = this.route.snapshot.queryParamMap.get('lang');
    // this.homeService.setLanguage(this.appConstants.lang);
    this.appConstants.accessTokenPreProd.subscribe(value => {
      this.accessTokenPreProd = value as string;
    });
  }

  ngOnInit(): void {
    this.checkOrigin();
    this.convertLanguage();
  }

  checkOrigin() {
    let origin;
    const data = {
      origin: ''
    };
    if (this.route.snapshot.queryParams?.origin) {
      data.origin = this.route.snapshot.queryParams?.origin;
    } else if (this.locations['_platformLocation'].location.origin.includes('solextron')) {
      data.origin = 'https://solcalc.solextron.com';
    } else {
      // data.origin = 'https://solcalc.solextron.com';
      data.origin = " ";
    }
    localStorage.setItem('origin', data.origin);

    this.homeService.checkOrigin(data).subscribe(res => {
      this.appConstants.setTokenPreProd(res['access_token']);
      this.appConstants.setEmail(res['leadusers']);
    }, error => {
      if (error && error && error.message) {
        this.router.navigate(['service-error']);
      }
    });
  }
  convertLanguage() {
    if (this.convertLang === null || this.convertLang === '') {
      localStorage.setItem('lang', 'de');
      this.translate.use(this.homeService.getLanguage());
    } else {
      this.translate.use(this.convertLang);
    }
  }
  public location() {
    if (this.input.length === 0) {
      this.place = true;
    } else {
      this.place = false;
    }
  }
  valuechange(newValue) {
    this.searchLocation = newValue.srcElement.value;
  }
  public handleAddressChange(address: Address) {
    if (address && address.geometry && address.geometry.location) {
      this.appConstants.setReload(false)
      this.place = false;
      this.lang = address.geometry.location.lng();
      this.lat = address.geometry.location.lat();
      this.location1 = address.formatted_address;
      this.locationName = address.name;
      this.searchLocation = address.formatted_address;
      length = address.address_components.length;
      this.postalCode = '';
      for (let i = 0; i < address.address_components.length; i++) {
        for (let j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === 'postal_code') {
            this.postalCode = address.address_components[i].long_name;
          }
          if (address.address_components[i].types[j] === 'country') {
            this.countryCode = address.address_components[i].short_name;
          }
        }
      }
      if (this.postalCode === '') {
        this.toastr.error('Please write your full address');
        return false;
      }
      this.surfaceData = [];
      this.surfaceData.push(this.searchLocation);
      this.surfaceData.push(this.postalCode);
      this.surfaceData.push(this.locationName);
      localStorage.setItem('locationName', this.locationName);
      this.surfaceData.push(this.countryCode);
      localStorage.setItem('address', JSON.stringify(this.surfaceData));
      this.appConstants.setLocation(this.surfaceData);
      console.log(this.surfaceData[2]);

    }
  }

  // location() {
  //   if (this.lang === '' || this.lat === '' || this.lang === undefined || this.lat === undefined) {
  //     this.toastr.warning('Please select an address first');
  //     return false;
  //   }
  //   if (this.searchLocation === '' || this.searchLocation === undefined) {
  //     this.toastr.warning('Please type your address ');
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
  homePage() {
    if (this.searchLocation === undefined || this.searchLocation === '') {
      this.toastr.warning('Please write your address');
      return false;
    } else if (this.lat === undefined || this.lang === undefined || this.lang === '' || this.lat === '') {
      this.callGeocode();
    } else {
      if (this.postalCode || this.postalCode.length !== 0) {
        this.router.navigate(['V5'], {queryParams: {lon: this.lang, lat: this.lat}});
      }
      return true;
    }
  }
  public callGeocode<T>() {
    let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.searchLocation}&key=AIzaSyA1uefY3TIXavmMXW0qgHNLn9a8vcE_804`;
    this.http.get(api).subscribe(res => {
      const response = res as any;
      const address = response.results[0];
      this.lang = address.geometry.location.lng;
      this.lat = address.geometry.location.lat;
      this.location1 = address.formatted_address;
      length = address.address_components.length;
      // this.locationName = address.address_components[0].long_name;
      this.postalCode = '';
      for (var i = 0; i < address.address_components.length; i++) {
        for (var j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === "postal_code") {
            this.postalCode = address.address_components[i].long_name;
          }
          if (address.address_components[i].types[j] === "country") {
            this.countryCode = address.address_components[i].short_name;
          }
          if (address.address_components[i].types[j] === "locality") {
            this.locationName = address.address_components[i].short_name;
          }
        }
      }
      if (this.postalCode === '') {
        this.toastr.error('Please write your full address');
        return false;
      }
      if (this.countryCode !== 'CH') {
        this.toastr.warning('This service is provided only in Switzerland');
        return false;
      }
      this.surfaceData = [];
      this.surfaceData.push(this.searchLocation);
      this.surfaceData.push(this.postalCode);
      this.surfaceData.push(this.locationName);
      localStorage.setItem('locationName', this.locationName);
      this.surfaceData.push(this.countryCode);
      localStorage.setItem('address', JSON.stringify(this.surfaceData));
      this.appConstants.setLocation(this.surfaceData);
      this.router.navigate(['V5'], {queryParams: {lon: this.lang, lat: this.lat}});
    })
  }

  public submitForm(): void {
    this.homePage();
    if (this.postalCode || this.postalCode.length !== 0) {
      this.onSubmit.emit({
        value: 'Location',
      });
    }
  }
}
