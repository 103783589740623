import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionComponent} from './terms-and-condition/terms-and-condition.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {HousePitchComponent} from './house-pitch/house-pitch.component';
import {HouseAlignmentComponent} from './house-alignment/house-alignment.component';
import {HouseHotWaterComponent} from './house-hot-water/house-hot-water.component';
import {HouseHeaterComponent} from './house-heater/house-heater.component';
import {HouseConsumptionComponent} from './house-consumption/house-consumption.component';
import {HouseRoofAreaComponent} from './house-roof-area/house-roof-area.component';
import {HouseRoofTypeComponent} from './house-roof-type/house-roof-type.component';
import {ApiEndPoints, Configuration, Constants} from './app.constant';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {ResultModule} from './result/result.module';
import {ROUND_PROGRESS_DEFAULTS, RoundProgressModule} from 'angular-svg-round-progressbar';
import {ChartsModule} from 'ng2-charts';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {MatRadioModule} from '@angular/material/radio';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {UiSwitchModule} from 'ngx-toggle-switch';
import {HousePriorityComponent} from './house-priority/house-priority.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import { ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogContactComponent} from './dialog-contact/dialog-contact.component';
import {HouseEnergyComponent} from './house-energy/house-energy.component';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatSliderModule} from '@angular/material/slider';
import {MatOptionModule} from '@angular/material/core';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TokenInterceptor} from './auth/token.interceptor';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoaderInterceptor} from './loader/loader.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import {AddressComponent} from './address/address.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {MatTabsModule} from '@angular/material/tabs';
import {NewResultComponent} from './new-result/new-result.component';
import {ResultConsumptionComponent} from './result-consumption/result-consumption.component';
import {ResultIndependenceComponent} from './result-independence/result-independence.component';
import {ResultHowFutherComponent} from './result-how-futher/result-how-futher.component';
import {ResultTypicalDayComponent} from './result-typical-day/result-typical-day.component';
import {ResultSustainComponent} from './result-sustain/result-sustain.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {HomeLocationComponent} from './home-location/home-location.component';
import {ResultFinancialComponent} from './result-financial/result-financial.component';
import {AuthorizationInterceptor} from './auth/authorization.interceptor';
import {HeaderComponent} from './header/header.component';
import {Locationv3Component} from './locationv3/locationv3.component';
import {Roofareav3Component} from './roofareav3/roofareav3.component';
import {Housetypev3Component} from './housetypev3/housetypev3.component';
import {Roofpitchv3Component} from './roofpitchv3/roofpitchv3.component';
import {Orientationv3Component} from './orientationv3/orientationv3.component';
import {Hotwaterv3Component} from './hotwaterv3/hotwaterv3.component';
import {Heaterv3Component} from './heaterv3/heaterv3.component';
import {Prioritiesv3Component} from './prioritiesv3/prioritiesv3.component';
import {Consumptionv3Component} from './consumptionv3/consumptionv3.component';
import {Resultv3Component} from './resultv3/resultv3.component';
import {Furtherv3Component} from './resultv3/furtherv3/furtherv3.component';
import {Independencev3Component} from './resultv3/independencev3/independencev3.component';
import {ResultConsumptionv3Component} from './resultv3/result-consumptionv3/result-consumptionv3.component';
import {Sustainabilityv3Component} from './resultv3/sustainabilityv3/sustainabilityv3.component';
import {TypicalDayv3Component} from './resultv3/typical-dayv3/typical-dayv3.component';
import {Quotev3Component} from './quotev3/quotev3.component';
import {Financialv3Component} from './resultv3/financialv3/financialv3.component';
import {AddressNewV1Component} from './address-new-v1/address-new-v1.component';
import {HomeNewV1Component} from './home-new-v1/home-new-v1.component';
import {HouseRoofAreaNewV1Component} from './house-roof-area-new-v1/house-roof-area-new-v1.component';
import {HouseHotWaterNewV1Component} from './house-hot-water-new-v1/house-hot-water-new-v1.component';
import {HouseHeaterNewV1Component} from './house-heater-new-v1/house-heater-new-v1.component';
import {HouseConsumptionNewV1Component} from './house-consumption-new-v1/house-consumption-new-v1.component';
import {InfoDialogueComponent} from './home-new-v1/info-dialogue/info-dialogue.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ResultNewV1Component} from './result-new-v1/result-new-v1.component';
import {ResultIndependenceNewV1Component} from './result-independence-new-v1/result-independence-new-v1.component';
import {ResultConsumptionNewV1Component} from './result-consumption-new-v1/result-consumption-new-v1.component';
import {ResultTypicalDayNewV1Component} from './result-typical-day-new-v1/result-typical-day-new-v1.component';
import {ResultFinancialNewV1Component} from './result-financial-new-v1/result-financial-new-v1.component';
import {ResultHowFurtherNewV1Component} from './result-how-further-new-v1/result-how-further-new-v1.component';
import {ResultSustainNewV1Component} from './result-sustain-new-v1/result-sustain-new-v1.component';
import {Locationv2Component} from './locationv2/locationv2.component';
import {Roofareav2Component} from './roofareav2/roofareav2.component';
import {Hotwaterv2Component} from './hotwaterv2/hotwaterv2.component';
import {Heaterv2Component} from './heaterv2/heaterv2.component';
import {Consumptionv2Component} from './consumptionv2/consumptionv2.component';
import {Resultv2Component} from './resultv2/resultv2.component';
import {Independencev2Component} from './resultv2/independencev2/independencev2.component';
import {ResultConsumptionv2Component} from './resultv2/result-consumptionv2/result-consumptionv2.component';
import {Financialv2Component} from './resultv2/financialv2/financialv2.component';
import {TypicalDayv2Component} from './resultv2/typical-dayv2/typical-dayv2.component';
import {Sustainabilityv2Component} from './resultv2/sustainabilityv2/sustainabilityv2.component';
import {Furtherv2Component} from './resultv2/furtherv2/furtherv2.component';
import {Homev3Component} from './homev3/homev3.component';
import {DialogContactAgv2Component} from './dialog-contact-agv2/dialog-contact-agv2.component';
import {RoofAreaDialogComponent} from './house-roof-area-new-v1/roof-area-dialog/roof-area-dialog.component';
import { Homev4Component } from './v4/homev4/homev4.component';
import { Locationv4Component } from './v4/locationv4/locationv4.component';
import { Roofareav4Component } from './v4/roofareav4/roofareav4.component';
import { Consumptionv4Component } from './v4/consumptionv4/consumptionv4.component';
import { Homev5Component } from './v5/homev5/homev5.component';
import { Roofareav5Component } from './v5/roofareav5/roofareav5.component';
import { LocationV5Component } from './v5/location-v5/location-v5.component';
import { CommonModule } from '@angular/common';
import { Consumptionv5Component } from './v5/consumptionv5/consumptionv5.component';





export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    HousePitchComponent,
    HouseAlignmentComponent,
    HouseHotWaterComponent,
    HouseHeaterComponent,
    HouseConsumptionComponent,
    HouseRoofAreaComponent,
    HouseRoofTypeComponent,
    HousePriorityComponent,
    DialogContactComponent,
    DialogContactAgv2Component,
    AddressComponent,
    HouseEnergyComponent,
    NewResultComponent,
    ResultConsumptionComponent,
    ResultIndependenceComponent,
    ResultHowFutherComponent,
    ResultTypicalDayComponent,
    ResultSustainComponent,
    HomeLocationComponent,
    ResultFinancialComponent,
    HeaderComponent,
    Locationv3Component,
    Roofareav3Component,
    Housetypev3Component,
    Roofpitchv3Component,
    Orientationv3Component,
    Hotwaterv3Component,
    Heaterv3Component,
    Prioritiesv3Component,
    Consumptionv3Component,
    Resultv3Component,
    Furtherv3Component,
    Independencev3Component,
    ResultConsumptionv3Component,
    Sustainabilityv3Component,
    TypicalDayv3Component,
    Quotev3Component,
    Financialv3Component,
    AddressNewV1Component,
    HomeNewV1Component,
    HouseRoofAreaNewV1Component,
    HouseHotWaterNewV1Component,
    HouseHeaterNewV1Component,
    HouseConsumptionNewV1Component,
    InfoDialogueComponent,
    RoofAreaDialogComponent,
    ResultNewV1Component,
    ResultIndependenceNewV1Component,
    ResultConsumptionNewV1Component,
    ResultTypicalDayNewV1Component,
    ResultFinancialNewV1Component,
    ResultHowFurtherNewV1Component,
    ResultSustainNewV1Component,
    Locationv2Component,
    Roofareav2Component,
    Hotwaterv2Component,
    Heaterv2Component,
    Consumptionv2Component,
    Resultv2Component,
    Independencev2Component,
    ResultConsumptionv2Component,
    Financialv2Component,
    TypicalDayv2Component,
    Sustainabilityv2Component,
    Furtherv2Component,
    Homev3Component,
    Homev4Component,
    Locationv4Component,
    Roofareav4Component,
    Consumptionv4Component,
    Homev5Component,
    Roofareav5Component,
    LocationV5Component,
    Consumptionv5Component
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    // MetaModule.forRoot(),
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    ModalModule.forRoot(),
    ResultModule,
    RoundProgressModule,
    ChartsModule,
    ResultModule,
    MatRadioModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot( {
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    UiSwitchModule,
    TabsModule.forRoot(),
    TabsModule.forRoot(),
    MatSelectModule,
    MatSliderModule,
    MatOptionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    DragDropModule,
    ProgressbarModule.forRoot(),
    NgxUiLoaderModule,
    NgxSpinnerModule,
    InputsModule,
    MatTabsModule,
    NgCircleProgressModule.forRoot(),
    BsDropdownModule.forRoot(),
    DropDownsModule,
    GooglePlaceModule,
    MatDialogModule,
    CommonModule

  ],
  entryComponents: [
    DialogContactComponent,
    InfoDialogueComponent,
    DialogContactAgv2Component,
    RoofAreaDialogComponent
  ],
  providers: [
    ApiEndPoints,
    Configuration,
    Constants,
    CookieService,
    BsModalRef,
    Location,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
