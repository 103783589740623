import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {HousePriorityCategory} from "./prioritiesv3.model";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {PriorityService} from "./prioritiesv3.service";
import {HomeService} from "../home/home.service";
import {Constants} from "../app.constant";

@Component({
  selector: 'app-prioritiesv3',
  templateUrl: './prioritiesv3.component.html',
  styleUrls: ['./prioritiesv3.component.scss']
})
export class Prioritiesv3Component implements OnInit, OnChanges {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSumbit1: EventEmitter<any> = new EventEmitter<any>();

  @Input() leadId: string;
  @Input() prirorityStep;
  trash = faTrash;
  check = false;
  email;
  data;
  priorityCategories: HousePriorityCategory[];

  selectedList;

  constructor(
    private housePriorityService: PriorityService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private homeService: HomeService,
    private appConstants: Constants,


  ) {this.appConstants.email.subscribe(value => {
    this.email = value as string;
  });
  }

  ngOnInit(): void {
    this.selectedList = [];
    this.priorityCategories = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.leadId && this.leadId !== '') {
      this.getPriorityListItems();
    }
  }

  private getPriorityListItems() {
    const keyLabelMapper = {
      FIN: this.translate.instant('priority.financial'),
      SUS: this.translate.instant('priority.sus'),
      PHY: this.translate.instant('priority.physical')
    }
    this.housePriorityService.getPriorityListItems(this.leadId).subscribe(res => {
      const response = res as any;
      if (response.PriorityListLookup && response.PriorityListLookup) {
        let catMapper = {};
        for (let key in response.PriorityListLookup) {
          let cat = key.substr(0, key.indexOf('-'));
          if (!catMapper[cat]) {
            catMapper[cat] = [];
          }
          catMapper[cat].push({
            key: key,
            label: response.PriorityListLookup[key]
          });
        }
        for (let key in catMapper) {
          let tempCat = new HousePriorityCategory()
          tempCat.key = key;
          tempCat.list = catMapper[key];
          tempCat.label = keyLabelMapper[key.toUpperCase()];
          this.priorityCategories.push(tempCat);
        }
      }
    }, error => {
      if (error && error && error.message) {
        this.toastr.error(error.message);
      }
    });
  }

  public selectItem(category, key): void {
    category.list.forEach((item) => {
      if (item.key === key) {
        if (!item.isSelected && !item.isChecked) {
          item.isSelected = true;
          this.check = true;
          this.selectedList.push(item);
        }
      }
    });
  }
  updatePriority(list) {
    this.homeService.updatePriority({
      email: this.email,
      lead_id: this.leadId,
      prioritylist: list
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
    }, error => {
      if (error && error && error.message) {
        this.toastr.error(error.message);
      }
    });
  }

  public submitForm(): void {
    let list = []
    for (let item of this.selectedList)
      list.push(item.key)
    this.updatePriority(list);
    this.onSubmit.emit({
      list: list
    });
    this.onSumbit1.emit({
      value: 'Priority'
    });
  }

  removePriority(item) {
    this.selectedList = this.selectedList.filter(value => item.key !== value.key);
    this.priorityCategories.forEach(category => {
      category.list.forEach(priority => {
        if (item.key === priority.key) {
          item.isSelected = false;
          item.isChecked = false;
        }
      });
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedList, event.previousIndex, event.currentIndex);
  }


}
