<section class="alignment-section">
  <div>
    <div class="d-flex head">
      <h3 class="step-heading">{{'alignment.Orientation' | translate}}</h3>
      <p class="float-right" style="color: gray; font-family: 'poppins';">STEP 4/8</p>
    </div>

    <p>{{'alignment.content' | translate}}</p>
    <div class="spacer-2"></div>
    <div class="spacer-3"></div>
    <div class="spacer-3"></div>
    <div class="spacer-3"></div>
    <div class="container-fluid">
      <div class="row mb-5">
        <div class="col-12 ">
          <!--<mat-slider-->
          <!--class="example-margin form-control"-->
          <!--[disabled]="false"-->
          <!--[invert]="false"-->
          <!--[max]="180"-->
          <!--[min]="-180"-->
          <!--[value]="alignment"-->
          <!--[step]="1"-->
          <!--[thumbLabel]="true"-->
          <!--(input)="changeRotation($event)"-->
          <!--[vertical]="false">-->
          <!--</mat-slider>-->
          <kendo-slider
            [tickPlacement]="tickPlacement"
            [min]="-180"
            [max]="180"
            [smallStep]="1"
            [(ngModel)] = "alignment"
          >
            <kendo-slider-messages
              increment="Right"
              decrement="Left"
            ></kendo-slider-messages>
          </kendo-slider>
          <div class="text-center mb-4" style="color: #A0A6A6; font-family: 'poppins';">
            {{'alignment.slider-content' | translate}}
          </div>
          <div class="text-center" >
            {{ alignment }}
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 d-flex justify-content-center">
          <div class="house-circle" [ngStyle]="{transform:'rotate('  +  alignment + 'deg)'}">
            <div class="vertical-line"></div>
            <!--<div class="circle-dot"></div>-->
            <div class="horizontal-line"></div>
            <!--<div class="circle-dot"></div>-->
            <div class="house-image" [innerHTML]="image" >
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="text-center mt-3"   >
      <button class="btn btn-primary w-100" (click)="submitForm()">{{'Continue' | translate}}</button>
    </div>
  </div>
</section>
