<section>
  <div class="justify-content-center text-center">
    <div class="row mt-5 p-3 mb-2">
      <div class="col">
        <span class="dtext">{{'Consumption.header' | translate}}</span>
      </div>
    </div>
    <div class="row heading my-3 pb-2">
      <div class="col">
        <span class="htext">{{'Consumption.question1' | translate}}</span>
      </div>
    </div>
    <div class="row mt-4 ">
      <div class="col">
        <input class="form-control text-center searchbar w-25" type="text" [(ngModel)]="consumption.numPeople" (input)="extraInput($event)"  placeholder="{{'Consumption.num-of-people-placeholder' | translate}}" aria-label="Search">
      </div>
    </div>
    <div class="row heading my-3 py-2">
      <div class="col">
        <span class="htext">{{'Consumption.question2' | translate}}</span>
      </div>
    </div>
    <div class="row mt-4 ">
      <div class="col">
        <input class="form-control text-center searchbar w-25" [(ngModel)]="consumption.YearlyElecRequired" [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'Consumption.yearly-consumption' | translate}}" aria-label="Search">
      </div>
    </div>
    <div class="row heading mt-3  py-2">
      <div class="col">
        <span class="htext">{{'Consumption.question3' | translate}}</span>
      </div>
    </div>
    <div class="row mt-4 ">
      <div class="col">
        <button *ngIf="electrics.length===0"  class="btn btn-block btn-light text-center spinner-button searchbar w-25" type="button" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
        <select *ngIf="electrics.length>0" id="electricSupp" id="electricSupp" class="form-control text-center searchbar w-25" [ngModelOptions]="{standalone: true}" (change)="getElectricSupplierCode(electricId)"  [(ngModel)]="electricId" type="text"  >
          <option *ngIf="!electricId" [value]="0" selected  hidden>Select Energy Supplier</option>
          <option *ngFor="let electric of electrics" [value]="electric.elecID">{{ electric.Name }}</option>
        </select>
      </div>
    </div>

    <div class="mt-3">
      <button type="button" style="font-size: 17px" class="btn btn-secondary" (click)="submitForm()">{{'Continue' | translate}}</button>
    </div>

  </div>

</section>
