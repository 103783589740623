<div class="container howFuther mt-4  ">
  <div class="row mt-5 ">
    <div class="col-12">
      <p class="title">{{'how-futher.content' | translate }}</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <p class="content">{{'how-futher.content1-agrola' | translate }}</p>
      <p class="content" *ngIf="this.lang === 'fr'">{{'how-futher.content2' | translate }}</p>

      <p class="content mt-2" *ngIf="this.lang === 'fr'">{{'how-futher.content3' | translate }}</p>
    </div>
  </div>
  <div class="row mt-4 mb-5 remove-padding">
    <div class="col-10">
      <button type="button" class="btn btn-primary button text-uppercase" (click)=" createQuotation()">
        {{'contact-form.label1' |
        translate }}
      </button>
    </div>
  </div>
  <!--</div>-->

</div>
