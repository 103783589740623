<div class="container financial">
  <p class="title-header h5 mb-2 mt-3">{{'financial.label' | translate }}</p>

  <!--// desktop radio button select storage-->
  <div class="row ml-0 mt-3 radio-btn  flex-column">
    <div class="radio-btn1 d-flex">
      <div class="radio-btn1 d-flex">
        <label class="radio-button">
          <input type="radio" class="radio-button__input" [(ngModel)]="storage" value="PV-without-storage"
                 name="PV-select"
                 (change)="changeSeason()">
          <span class="radio-button__control"></span>
          <span class="radio-button__label" style="color: #677171;  font-family: 'poppins';">{{'pv-without-storage' | translate }}</span>
        </label>
        <!--<img class="ml-0 mb-2" tooltip="Photovoltaic system without battery"-->
        <!--src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZDVkNWQ1OwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9ImluZm9faWNvbiIgY2xhc3M9ImNscy0xIiBkPSJNMTAtLjAwNWExMCwxMCwwLDEsMCwxMCwxMEExMCwxMCwwLDAsMCwxMC0uMDA1Wm0yLjA4MiwxNS41cS0wLjc3MS4zLTEuMjMyLDAuNDY0YTMuMjQ3LDMuMjQ3LDAsMCwxLTEuMDY4LjE2LDIuMTM3LDIuMTM3LDAsMCwxLTEuNDU0LS40NTZBMS40NzUsMS40NzUsMCwwLDEsNy44MDYsMTQuNWE0LjI0LDQuMjQsMCwwLDEsLjAzOC0wLjU1OGMwLjAyNi0uMTg5LjA2OC0wLjQsMC4xMjUtMC42NDJsMC42NDQtMi4yNzVjMC4wNTctLjIyLjEwNi0wLjQyNywwLjE0NS0wLjYyMWEyLjc1MywyLjc1MywwLDAsMCwuMDU3LTAuNTM2LDAuODI0LDAuODI0LDAsMCwwLS4xNzktMC42MDdBMS4wMTgsMS4wMTgsMCwwLDAsNy45NDcsOS4xYTEuOCwxLjgsMCwwLDAtLjUxMi4wNzZjLTAuMTc0LjA1My0uMzI1LDAuMS0wLjQ0OCwwLjE0OWwwLjE3LS43UTcuNzksOC4zNjEsOC4zNjgsOC4xNzhBMy41NzcsMy41NzcsMCwwLDEsOS40Niw3Ljk5M2EyLjA5MSwyLjA5MSwwLDAsMSwxLjQzMy40NDksMS40OTIsMS40OTIsMCwwLDEsLjUsMS4xNjVxMCwwLjE0OS0uMDM1LjUyM2EzLjUyOCwzLjUyOCwwLDAsMS0uMTI5LjY4N2wtMC42NDEsMi4yNjlxLTAuMDc4LjI3NC0uMTQxLDAuNjIzYTMuMjY2LDMuMjY2LDAsMCwwLS4wNjIuNTMyLDAuNzYzLDAuNzYzLDAsMCwwLC4yLjYxNiwxLjEzMywxLjEzMywwLDAsMCwuNy4xNjQsMS45OTMsMS45OTMsMCwwLDAsLjUzMS0wLjA4MywyLjkzNCwyLjkzNCwwLDAsMCwuNDI4LTAuMTQ0Wm0tMC4xMTMtOS4yMWExLjUzLDEuNTMsMCwwLDEtMS4wOC40MTZBMS41NDgsMS41NDgsMCwwLDEsOS44LDYuMjg0YTEuMzU5LDEuMzU5LDAsMCwxLDAtMi4wMjMsMS41MzUsMS41MzUsMCwwLDEsMS4wODQtLjQyMSwxLjUxOCwxLjUxOCwwLDAsMSwxLjA4LjQyMUExLjM2NCwxLjM2NCwwLDAsMSwxMS45NjQsNi4yODRaIi8+Cjwvc3ZnPgo=">-->
        <span class="ml-0 mt-1"><i class="fas fa-info-circle fa-2x infoIcon"
                                   tooltip="{{'Photovoltaic-system-without-battery' | translate }}"
                                   [ngClass]="{'active' : storage === 'PV-without-storage'}"></i></span>
      </div>
      <div class="radio-btn1 d-flex ml-5">
        <label class="radio-button">
          <input type="radio" class="radio-button__input" [(ngModel)]="storage" value="PV-with-storage"
                 name="PV-select"
                 (change)="changeSeason()">
          <span class="radio-button__control"></span>
          <span class="radio-button__label" style="color: #677171;  font-family: 'poppins';">{{'PV-with-storage' | translate }}</span>
        </label>
        <!--<img class="ml-1 mb-2" tooltip="Photovoltaic system with battery"-->
        <!--src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZDVkNWQ1OwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9ImluZm9faWNvbiIgY2xhc3M9ImNscy0xIiBkPSJNMTAtLjAwNWExMCwxMCwwLDEsMCwxMCwxMEExMCwxMCwwLDAsMCwxMC0uMDA1Wm0yLjA4MiwxNS41cS0wLjc3MS4zLTEuMjMyLDAuNDY0YTMuMjQ3LDMuMjQ3LDAsMCwxLTEuMDY4LjE2LDIuMTM3LDIuMTM3LDAsMCwxLTEuNDU0LS40NTZBMS40NzUsMS40NzUsMCwwLDEsNy44MDYsMTQuNWE0LjI0LDQuMjQsMCwwLDEsLjAzOC0wLjU1OGMwLjAyNi0uMTg5LjA2OC0wLjQsMC4xMjUtMC42NDJsMC42NDQtMi4yNzVjMC4wNTctLjIyLjEwNi0wLjQyNywwLjE0NS0wLjYyMWEyLjc1MywyLjc1MywwLDAsMCwuMDU3LTAuNTM2LDAuODI0LDAuODI0LDAsMCwwLS4xNzktMC42MDdBMS4wMTgsMS4wMTgsMCwwLDAsNy45NDcsOS4xYTEuOCwxLjgsMCwwLDAtLjUxMi4wNzZjLTAuMTc0LjA1My0uMzI1LDAuMS0wLjQ0OCwwLjE0OWwwLjE3LS43UTcuNzksOC4zNjEsOC4zNjgsOC4xNzhBMy41NzcsMy41NzcsMCwwLDEsOS40Niw3Ljk5M2EyLjA5MSwyLjA5MSwwLDAsMSwxLjQzMy40NDksMS40OTIsMS40OTIsMCwwLDEsLjUsMS4xNjVxMCwwLjE0OS0uMDM1LjUyM2EzLjUyOCwzLjUyOCwwLDAsMS0uMTI5LjY4N2wtMC42NDEsMi4yNjlxLTAuMDc4LjI3NC0uMTQxLDAuNjIzYTMuMjY2LDMuMjY2LDAsMCwwLS4wNjIuNTMyLDAuNzYzLDAuNzYzLDAsMCwwLC4yLjYxNiwxLjEzMywxLjEzMywwLDAsMCwuNy4xNjQsMS45OTMsMS45OTMsMCwwLDAsLjUzMS0wLjA4MywyLjkzNCwyLjkzNCwwLDAsMCwuNDI4LTAuMTQ0Wm0tMC4xMTMtOS4yMWExLjUzLDEuNTMsMCwwLDEtMS4wOC40MTZBMS41NDgsMS41NDgsMCwwLDEsOS44LDYuMjg0YTEuMzU5LDEuMzU5LDAsMCwxLDAtMi4wMjMsMS41MzUsMS41MzUsMCwwLDEsMS4wODQtLjQyMSwxLjUxOCwxLjUxOCwwLDAsMSwxLjA4LjQyMUExLjM2NCwxLjM2NCwwLDAsMSwxMS45NjQsNi4yODRaIi8+Cjwvc3ZnPgo=">-->
        <span class="ml-0 mt-1"><i class="fas fa-info-circle fa-2x infoIcon" tooltip="{{'Photovoltaic-system-with-battery' | translate }}"
                                   [ngClass]="{'active' : storage === 'PV-with-storage'}"></i></span>

      </div>

    </div>


  </div>

  <!--//mobile dropdown for storage change-->
  <div class="row mobile_btn  mb-3 mt-4">
    <div class="col-12 w-100">
      <select id="pv" (change)="changeSeason()" [(ngModel)]="storage" name="pv">
        <option value="PV-without-storage" style="color: #677171;   font-family: 'poppins';">{{'pv-without-storage' |
          translate }}
        </option>
        <option value="PV-with-storage" style="color: #677171;   font-family: 'poppins';">{{'PV-with-storage' |
          translate }}
        </option>
      </select>

    </div>
  </div>

  <!--//Four upper block -->
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">

      <div class="row">
        <div class="col-lg-6 col-sm-12 col-md-12 mt-3 text-center ">
          <div class="card">
            <p class="card-item  m-auto">{{'financial.total-investment' | translate }}{{investment}} CHF</p>
          </div>

        </div>
        <div class="col-lg-6 col-sm-12 col-md-12 mt-3 text-center">
          <div class="card">
            <p class="card-item m-auto">{{'financial.energy-cost-saving' | translate }} {{NetSavings25Year}} CHF</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-sm-12 col-md-12 mt-3 text-center">
          <div class="card">
            <p class="card-item m-auto">{{'financial.payback' | translate }}{{payback}} {{'financial.payback-year' |
              translate }}</p>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-md-12 mt-3 text-center">
          <div class="card">
            <p class="card-item m-auto">{{'financial.monthly-saving' | translate }} {{NetSavingsMonth}} CHF</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>

  <!--// 3 Graph are here -->
  <div class="row mt-4 mb-5 remove-space">
    <div class="col-lg-4  graph col-sm-12 mt-5">
      <div #container style="display: block; " class="bar-chart" [style.height.px]="container.offsetWidth">
        <canvas baseChart class="barchart"
                [datasets]="barChartDataLeft"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [colors]="barChartColors"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                [options]="options"
        >
        </canvas>
      </div>
      <div class="d-flex mb-5 flex-column">
        <div class="yellow d-flex flex-row">
          <div class="" style="background: yellow; width: 18px; height: 18px; min-width: 18px">
          </div>
          <div class="ml-2">{{'financial.investment' | translate}}</div>
        </div>
        <div class="green mt-2 d-flex flex-row">
          <div class="" style="background: green; width: 18px; height: 18px;min-width: 18px">
          </div>
          <div class="ml-2">{{'financial.incomeOver25' | translate}}</div>
        </div>
      </div>
    </div>
        <div class="col-lg-4 graph col-sm-12 mt-5">
          <div  style="display: block;" class="bar-chart" [style.height.px]="container.offsetWidth">
            <canvas baseChart class="barchart"
                    [datasets]="barChartDataCenter"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [colors]="barChartColors"
                    [legend]="barChartLegend"
                    [chartType]="barChartType"
                    [options]="options"
            >
            </canvas>
          </div>
          <div class="d-flex mb-5 flex-column">
            <div class="yellow d-flex flex-row">
              <div class="" style="background: yellow; width: 18px; height: 18px;min-width: 18px">
              </div>
              <div class="ml-2">{{'financial.eleBillBeforeSolar' | translate}}</div>
            </div>
            <div class="green mt-2 d-flex flex-row">
              <div class="" style="background: green; width: 18px; height: 18px; min-width: 18px">
              </div>
              <div class="ml-2">{{'financial.eleBillAfterSolar' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 graph col-sm-12 mt-5">
          <div  style="display: block;" class="bar-chart" [style.height.px]="container.offsetWidth">
            <canvas baseChart class="barchart"
                    [datasets]="barChartDataRight"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [colors]="barChartColors"
                    [legend]="barChartLegend"
                    [chartType]="barChartType"
                    [options]="IRROptions"
            >
            </canvas>
          </div>
          <div class="d-flex mb-5 flex-column">
            <div class="yellow d-flex flex-row">
              <div class="" style="background: yellow; width: 18px; height: 18px;min-width: 18px">
              </div>
              <div class="ml-2">{{'financial.bank-interest' | translate}}</div>
            </div>
            <div class="green mt-2 d-flex flex-row">
              <div class="" style="background: green; width: 18px; height: 18px;min-width: 18px">
              </div>
              <div class="ml-2">{{'financial.solar-irr' | translate}}</div>
            </div>
          </div>
        </div>
      </div>


    </div>
