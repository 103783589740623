<section>
  <div class="container justify-content-center text-center">
    <div class="row mt-5 p-3">
      <div class="col "><span class="dtext">{{'priority.label' | translate}}</span></div>
    </div>
    <div class="row pb-3 heading ">
      <div class="col "><span class="htext">{{'priority.content1' | translate}}</span>
      </div>
    </div>
  </div>
  <div class="container justify-content-center">
    <div class="mb-2 ">
      <mat-tab-group>
        <mat-tab *ngFor="let category of priorityCategories" [label]="category.label">
          <div class="spacer-1"></div>
          <ul class="list-group">
            <li class="list-group-item float-right "
                style="cursor: pointer;color:black" title="Select Priority"
                *ngFor="let item of category.list">
              <label class="radio-but">
                <input type="radio" class="radio-but__input" (click)="selectItem(category,item.key)"
                       [ngClass]="{'priority-selected': item.isSelected, 'priority-checked': item.isChecked }"
                       [checked]="item.isSelected" >
                <span class="radio-but__control"></span>
                <span class="radio-but__label">{{item.label}}</span>
              </label>
            </li>

          </ul>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="selected-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 px-0">
            <div class="card">
              <div class="card-header pt-2 pb-1">
                <label><b>{{'priority.selected' | translate}}</b></label>
              </div>
              <div class="card-body px-1" *ngIf="selectedList.length <= 0">
                <div class="no-item ml-2">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <a style="color: #C3C6C6">{{'priority.click' | translate}}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-body px-1" *ngIf="selectedList.length > 0">
                <div>
                  <ul class="list-group" cdkDropList (cdkDropListDropped)="drop($event)">
                    <li class="list-group-item" *ngFor="let item of selectedList" cdkDrag>
                    <span class="float-right" (click)="removePriority(item)" style="cursor: pointer">
                      <fa-icon [icon]="trash" [classList]="['text-danger']" title="remove"></fa-icon>
                    </span>
                      {{item.label}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col p-3 text-center" *ngIf="selectedList.length>0">
        <button type="button" style="font-size: 17px" class="btn btn-secondary"(click)="submitForm()" >{{'Continue' | translate}}</button>
      </div>
    </div>
  </div>
</section>
