import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/catch';
import {ApiEndPoints, Constants} from '../app.constant';
import {AuthService} from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  accessToken;
  accessTokenPreProd;

  constructor(private appConstants: Constants, private authService: AuthService,
              private apiEndPoints: ApiEndPoints) {
    this.appConstants.accessToken.subscribe(value => {
      this.accessToken = value as string;
    });
    this.appConstants.accessTokenPreProd.subscribe(value => {
      this.accessTokenPreProd = value as string;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedRequest;
    let modifiedRequestPreProd;
    modifiedRequestPreProd = request.clone({
      setHeaders: {}
    });
    if (!request.url.includes(this.apiEndPoints.AUTH_GOOGLE_CLOUD) && !request.url.includes('https://storage.googleapis.com/upload/storage/v1/b/solbucket/o')) {
      if (!request.url.includes('https://maps.googleapis.com/maps/api/geocode/json')) {
        modifiedRequest = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.accessTokenPreProd,
          }
        });

        return next.handle(modifiedRequest);
      }
      return next.handle(modifiedRequestPreProd);
    }
    if (request.url.includes(this.apiEndPoints.AUTH_GOOGLE_CLOUD)) {
      const header = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('imageAuth')
        }
      });
      return next.handle(header);
    }
    if (request.url.includes('https://storage.googleapis.com/upload/storage/v1/b/solbucket/o')) {
      const header = request.clone({
          setHeaders: {
            'Content-Type': 'image/png',
            Authorization: 'Bearer ' + localStorage.getItem('imageAccessToken')
          }
        })
      ;
      return next.handle(header);
    }

    // modifiedRequest = request.clone({
    //   setHeaders: {
    //     Authorization: 'Bearer ' + this.accessTokenPreProd,
    //   }
    // });
    //
    // return next.handle(modifiedRequest);

  }

}
