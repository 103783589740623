<div class="container howFuther less mt-4">
<!--  {{selectedRoofArea > 100 ? 'more': 'less'}}-->
  <div class="row mt-5 ">
    <div class="col-12">
      <p class="title">{{'how-futher.content' | translate }}</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12" [ngClass]="{'mb-5': selectedroofArea}">
      <p class="content" *ngIf="selectedRoofArea>100">{{'how-futher.content1' | translate }}</p>
      <p class="content" *ngIf="selectedRoofArea<100">{{'how-further-new.new-content' | translate}}</p>
      <p class="content" *ngIf="selectedRoofArea<100">{{'how-further-new.new-content-1' | translate}}</p>
      <p class="content" *ngIf="selectedRoofArea<100">{{'how-further-new.new-content-2' | translate}}</p>
      <p class="content" *ngIf="selectedRoofArea<100">{{'how-further-new.new-content-3' | translate}}</p>
      <p class="content" *ngIf="selectedRoofArea<100">{{'how-further-new.new-content-4' | translate}}</p>
      <p class="content" *ngIf="selectedRoofArea<100">{{'how-further-new.new-content-5' | translate}}</p>
      <p class="content" *ngIf="selectedRoofArea<100">{{'how-further-new.new-final-content' | translate}}</p>
      <p class="content" *ngIf="this.lang === 'fr'">{{'how-futher.content2' | translate }}</p>

      <p class="content mt-2" *ngIf="this.lang === 'fr'">{{'how-futher.content3' | translate }}</p>
    </div>
  </div>
  <div class="row mt-4 mb-5 remove-padding" >
    <div class="col-10" *ngIf="selectedRoofArea>100">
      <button type="button" class="btn btn-primary button text-uppercase"  (click)=" createQuotation()">
        {{'contact-form.label1' |
        translate }}
      </button>
    </div>
  </div>
  <!--</div>-->

</div>
