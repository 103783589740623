import {Component, Input, OnInit} from '@angular/core';
import {HomeService} from "../home/home.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {Constants} from "../app.constant";
import {DialogContactComponent} from "../dialog-contact/dialog-contact.component";

@Component({
  selector: 'app-result-how-further-new-v1',
  templateUrl: './result-how-further-new-v1.component.html',
  styleUrls: ['./result-how-further-new-v1.component.scss']
})
export class ResultHowFurtherNewV1Component implements OnInit {
  @Input() selectedRoofArea;
  selectedroofArea = false;
  leadId;
  bsModalRef;
  lang;
  email;
  constructor(private homeService: HomeService,
              private modalService: BsModalService,
              private  toastr: ToastrService,
              private appConstants: Constants, ) {
    this.appConstants.leadId.subscribe(value => {
      this.leadId = value as string;
    });
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang');
    if (this.selectedRoofArea < 100) {
      this.selectedroofArea = true;
    }
  }

  sendLeadEmail() {
    const data = {
      email: this.email,
      lead_id: this.leadId,
      lang: "de",
    }
    this.homeService.sendLeadEmail(data).subscribe(reply => {
      const response = reply as any;
    }, error => {
      this.toastr.warning('Something went wrong', 'Oops');
    });
  }
  createQuotation() {
    const initialState = {
      title: 'Conatct',
      confirmed: false,
      class: 'modal-lg'
    };
    this.bsModalRef = this.modalService.show(DialogContactComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose = (myData) => {
      myData.data.customercontact.lead_id  = this.leadId;
      this.homeService.updateContactInformations(myData.data).subscribe(reply => {
        const response = reply as any;
        this.bsModalRef.hide();
        this.sendLeadEmail()
        this.toastr.success('Quotation upload successfully');
      }, error => {
        this.toastr.warning('Contact Information not updated Please try again', 'Oops');
      });
    };

  }

}
