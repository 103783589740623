<section>
  <div class="container-fluid" style="padding-top: 5px">
    <div class="row ">
      <div class="col-sm-12 first-heading"> <h1 style="font-size: 25px; font-weight: 500"> {{'result-agv2.Agrola-solar-analysis-for' | translate}} {{houserNumber}}, {{street}}</h1></div>

    </div>
  </div>


  <div class="container-fluid  pl-3" style="margin-top: 1.2rem;">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-3 pr-0">
        <div class="container-fluid">
          <div class="row py-2">
            <div class="col-12  text-center ">

              <span class="title">{{'result-agv2.Simulation-Results' | translate}}</span>

            </div>
          </div>
          <hr style="background-color: forestgreen ; border-top: 3px solid rgba(0,0,0,.1);">
          <ng-container class="d-flex flex-column">
            <div class="row justify-content-between">
              <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: start">
                <span style=" font-size: 16px; color: black">{{'result-agv2.Power' | translate}}</span>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 text-sm-right text-md-right text-lg-right simulation-results-values " >
                <span>{{dcPower}} kWp</span>
              </div>
            </div>
          </ng-container>
          <hr style="background-color: forestgreen">
          <ng-container class="d-flex flex-column">
            <div class="row justify-content-between">
              <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: start">
                <span style=" font-size: 16px; color: black">{{'result-agv2.Yearly-Production' | translate}}</span>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 text-sm-right text-md-right text-lg-right simulation-results-values" >
                <span>{{totEnergy}} kWh</span>
              </div>
            </div>
          </ng-container>
          <hr style="background-color: forestgreen">
          <ng-container class="d-flex flex-column">
            <div class="row justify-content-between">
              <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: start">
                <span style=" font-size: 16px; color: black">{{'result-agv2.Total-Investment' | translate}}</span>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 text-sm-right text-md-right text-lg-right simulation-results-values" >
                <span>{{investment}} CHF</span>
              </div>
            </div>
          </ng-container>
          <hr style="background-color: forestgreen">
          <ng-container class="d-flex flex-column">
            <div class="row justify-content-between">
              <div class="col-sm-6 col-md-6 col-lg-6 " style="text-align: start">
                <span style=" font-size: 16px; color: black">{{'result-agv2.Payback-Time' | translate}}</span>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 text-sm-right text-md-right text-lg-right simulation-results-values">
                <span>{{payback}} {{'result-agv2.years' | translate}}</span>
              </div>
            </div>
          </ng-container>
          <hr style="background-color: forestgreen ; border-top: 3px solid rgba(0,0,0,.1);">
        </div>
        <div class="container-fluid" style="margin-top: 4.5rem">
          <div class="row d-flex flex-column">
            <div class="col-12 text-center mb-3">
              <span  class="title">{{'result-agv2.Settings' | translate}}</span>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-sm-6 ">
                  <label for="dropdown" style="margin-right: 20px; font-size: 16px; color: black">
                    {{'result-agv2.Season' | translate}}
                  </label>
                </div>
                <div class="col-sm-6  pl-0 d-flex justify-content-end">
                  <kendo-dropdownlist id="dropdown"
                                      [data]="listItems"
                                      textField="text"
                                      valueField="value"
                                      [valuePrimitive]="true"
                                      (valueChange)="changeSeason()"
                                      [(ngModel)]="season"

                  >
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
            <div class="col-12" style="padding-top: 20px">
              <div class="row">
                <div class="col-6">
                  <label for="dropdown" style="margin-right: 20px; font-size: 16px; color: black">
                    {{'result-agv2.Battery' | translate}}
                  </label>
                </div>
                <div class="col-6 " style="text-align: end">
                  <ui-switch size="small"
                             [checked]="isBatteryEnabled"   (change)="toggleBattery($event)" color="blue"></ui-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 pl-0">
        <div class="container-fluid">
          <div class="row d-flex flex-column">
            <div class="col-12">
              <div class="container-fluid">
                <div class="row py-2">
                  <div class="col-12  text-center ">

                    <span class="title">{{'result-agv2.A-typical-day' | translate}}</span>

                  </div>
                </div>
              </div>
              <div class="graph-container" [ngClass]="{'year': seasonSelected === 'year',
                'summer': seasonSelected === 'summer',
                'winter': seasonSelected === 'winter',
                'autumn': seasonSelected === 'autumn',
                'spring': seasonSelected === 'spring'}">
                <canvas baseChart #baseChart="base-chart" style="height: 500px; width: 750px; margin: auto auto 15px;"
                        [datasets]="lineChartData"
                        [labels]="lineChartLabels"
                        [options]="lineChartOptions"
                        [colors]="lineChartColors"
                        [legend]="lineChartLegend"
                        [chartType]="lineChartType"
                        [plugins]="lineChartPlugins"
                >
                </canvas>
                <span style=" padding-left: 20px; font-size: 15px; font-weight: 400; color: black">
                  {{'result-agv2.graph-info' | translate}} {{'result-agv2.in' | translate}} <span *ngIf="season === 'yearly'">{{'result-agv2.yearly-season' | translate}}</span><span *ngIf="season !== 'yearly'">{{translatedSeason}}</span>.
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-12 col-md-12  col-lg-3 pl-0">
        <div class="container-fluid pl-0">
          <div class="row text-sm-center d-flex flex-column">
            <div class="col-12 circle-graph" >
              <div class="container-fluid progress-circle-heading">
                <div class="row py-2">
                  <div class="col-12  text-center ">
                    <div class="remove-max-width">
                      <span class="title">{{'result-agv2.Self-Consumption' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="progress-circle" >
                <div class="card" [ngClass]="{'year': seasonSelected === 'year',
                'summer': seasonSelected === 'summer',
                'winter': seasonSelected === 'winter',
                'autumn': seasonSelected === 'autumn',
                'spring': seasonSelected === 'spring'}">
                  <div class="row mx-auto d-flex mt-3">
                    <img [src]="seasonLogo">
                  </div>
                  <circle-progress
                    [percent]="yearPercent"
                    [radius]="radius"
                    [outerStrokeColor]="mainColor"
                    [innerStrokeColor]="secondaryColor"
                    [titleFontWeight]="90"
                    [titleColor]= "mainColor"
                    [unitsColor]="mainColor"
                    [titleFontSize]=50
                    [outerStrokeWidth]="15"
                    [innerStrokeWidth]="15"
                    [space]="-15"
                    [animation]="true"
                    [animationDuration]="300"
                    [showSubtitle]="false"
                  ></circle-progress>
                  <p class="card-season-title" [ngClass]="{'year': seasonSelected === 'year',
                'summer': seasonSelected === 'summer',
                'winter': seasonSelected === 'winter',
                'autumn': seasonSelected === 'autumn',
                'spring': seasonSelected === 'spring'}">{{translatedSeason}}</p>
                </div>
              </div>
            </div>
            <div class="col-12" style="padding-top: 3rem">
              <div class="container-fluid consumption-info-container">
                <div class="row d-flex flex-column">
                  <div class="col-12 " style="margin-bottom: 0.5rem"> <span class="title" >{{'result-agv2.Consumption' | translate}}</span></div>
                  <div class="col-12">
                    <p style="text-align: justify; text-justify: inter-word; font-size: 16px">
                      {{'result-agv2.consumption1' | translate}}<span *ngIf="season === 'yearly'">{{'result-agv2.yearly-season' | translate}}</span><span *ngIf="season !== 'yearly'">{{translatedSeason}}</span>{{'result-agv2.consumption2' | translate}}<span style="color: forestgreen">{{yearPercent}}%</span>{{'result-agv2.consumption3' | translate}}
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid pl-2" style="margin-top: 1.2rem;" >
    <div class="row pb-3">
      <div class="col-12 d-flex" style="padding-left: 40px">
        <span class="my-auto" style="font-size: 19px; font-weight: 500; color: black">{{'result-agv2.quotation-text' | translate}}</span><button (click)="createQuotation()" class="btn btn-warning request-quote-btn">{{'result-agv2.quotation-btn-text' | translate}}</button>
      </div>
    </div>
  </div>
</section>
