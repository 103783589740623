import {Component, OnInit} from '@angular/core';
import {Homev3Model} from "./homev3.model";
import {HomeService} from "../home/home.service";
import {HeaderModel} from "../header/header.model";
import {Constants} from "../app.constant";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinnerService} from "ngx-spinner";
import {ResultDataService, ResultService} from "../result/result.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {Quotev3Component} from "../quotev3/quotev3.component";

@Component({
  selector: 'app-homev3',
  templateUrl: './homev3.component.html',
  styleUrls: ['./homev3.component.scss']
})
export class Homev3Component implements OnInit {
  bsModalRef: BsModalRef;
  activeStep = 'Location';
  isVisible = true;
  showLoader = false;
  leadId;
  consumption;
  surfaceArea;
  hotWater;
  heaterType;
  searchedlocation;
  yearly;
  email;
  yearlyElectricAfterChange;
  data;
  isResultApiCompleted = false;
  contactFormDetails: any;
  filtered;
  public model = new Homev3Model();

  headArr = [
    {title: 'Location', selected: false, value: '...'},
    {title: 'Roof Area', selected: false, value: '...'},
    {title: 'Hot Water', selected: false, value: '...'},
    {title: 'Heater', selected: false, value: '...'},
    {title: 'Average Consumption', selected: false, numOfPeople: 1, Consumption: '...', Supplier: '...'}
  ];

  constructor(private homeService: HomeService,
              private appConstants: Constants,
              private toastr: ToastrService,
              private translate: TranslateService,
              private loader: NgxSpinnerService,
              private resultService: ResultService,
              private router: Router,
              private resultDataService: ResultDataService,
              private modalService: BsModalService,
  ) {
    // @ts-ignore
    window?.angularComponentRef = {component: this, zone: Zone};
    this.model = new HeaderModel();
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
  }

  ngOnInit(): void {
  }
  stepChange(step) {
    this.filtered = this.headArr.filter((item) => item.title === step);
    console.log(this.filtered)
    if (this.filtered[0].selected) {
      this.filtered[0].value = '...';
      return this.activeStep = step;
    }
  }
  next(step) {
    // console.log(step, 'step');
    if (step === 'Location') {
      this.activeStep = 'Roof Area';
      this.isVisible = true;
      this.headArr[0].selected = true;
      this.searchedlocation = localStorage.getItem('locationName');
      console.log(this.searchedlocation, typeof (this.searchedlocation));
      this.headArr[0].value = this.searchedlocation;
      console.log(this.headArr[0].value);
    } else if (step === 'Roof Area') {
      this.activeStep = 'Hot Water';
      this.isVisible = true;
      this.headArr[1].selected = true;
    } else if (step === 'Hot Water') {
      this.activeStep = 'Heater';
      this.isVisible = true;
      this.headArr[2].selected = true;
    } else if (step === 'Heater') {
      this.activeStep = 'Average Consumption';
      this.isVisible = true;
      this.headArr[3].selected = true;
      // this.uploadExtraInput();
    } else if (step === 'Average Consumption') {
      this.showLoader = true;
      this.isVisible = true;
      this.headArr[4].selected = true;
      if (this.yearlyElectricAfterChange !== this.model.consumption.YearlyElecRequired) {
        this.uploadYearlyElectricAfterChange();
      } else {
        this.verifyUploadEnergy();
      }
      this.updateContactInformations();

    } else {
      this.isVisible = true;
    }
    // else if (step === 'Motivation') {
    //   this.isVisible = true;
    // }

  }

  receiveData(data): void {
    this.model.email = data.email;
    this.model.token = data.token;
    this.model.leadId = data.lead;
    // this.model.longitude = data.lat;
    this.model.location = data.location;
    this.model.roofPitch = data.pitch;
    this.model.alignment = data.alignAngle;
  }

  receiveLeadId(data) {
    this.leadId = data.value;
    this.model.leadId = data.value;
    this.surfaceArea = data.surfaceArea["Area0"];
    this.headArr[1].value = this.surfaceArea + ' m2 ';
  }

  selectedDataHotWater(data) {
    console.log(data.value);
    this.hotWater = data.value;
    if (this.hotWater === 'EL') {
      this.headArr[2].value = 'Electric Heater';
    } else if (this.hotWater === 'WP') {
      this.headArr[2].value = 'Heat Pump Boiler';
    } else if (this.hotWater === 'OL') {
      this.headArr[2].value = 'Oil/Gas/Wood';
    }
  }

  selectedHeater(data) {
    this.heaterType = data.value;
    console.log(this.heaterType);
    if (this.heaterType === 'EL') {
      this.headArr[3].value = 'Electric Heater';
    } else if (this.heaterType === 'WP') {
      this.headArr[3].value = 'Heat Pump';
    } else if (this.heaterType === 'OL') {
      this.headArr[3].value = 'Oil/Gas/Wood';
    }
  }

  uploadExtraInput1(event) {

    console.log('first working');
    console.log(this.heaterType);
    this.model.consumption = event.value;
    this.homeService.uploadExtraInput({
      email: this.email,
      lead_id: this.leadId,
      info: {
        NumPeople: this.model.consumption.numPeople,
        Heating: this.heaterType,
        Water: this.hotWater,
        Type: 'Residential',
      }
    }).subscribe(res => {
      const response = res as any;
      this.model.consumption.YearlyElecRequired = response.energy;
      this.yearlyElectricAfterChange = response.energy;
      this.uploadYearlyElectric();
      this.headArr[4].numOfPeople = this.model.consumption?.numPeople;
      this.headArr[4].Consumption = this.model.consumption.YearlyElecRequired.toString() + 'kW';
    }, error => {
      this.toastr.warning('Something went Wrong Please try again', 'Oops');
    });
  }

  uploadExtraInput() {
    setTimeout(() => {
      console.log('second working');
      console.log(this.heaterType);
      this.homeService.uploadExtraInput({
        email: this.email,
        lead_id: this.leadId,
        info: {
          NumPeople: this.model.consumption?.numPeople,
          Water: this.hotWater,
          Heating: this.heaterType,
          Type: 'Residential',
        }
      }).subscribe(res => {
        const response = res as any;
        this.model.consumption.YearlyElecRequired = response.energy;
        this.yearlyElectricAfterChange = response.energy;
        this.headArr[4].numOfPeople = this.model.consumption?.numPeople;
        this.headArr[4].Consumption = this.model.consumption.YearlyElecRequired.toString() + 'kW';
      });
    }, 200);
  }


  uploadYearlyElectric() {
    this.homeService.uploadYearlyElectric({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      energy: this.model.consumption.YearlyElecRequired,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.yearly = this.data.YearlyElecRequired;
      // this.uploadElectricId();
      if (this.yearly === undefined || this.yearly === '') {
        this.toastr.warning('There is no Suppliers in your Location Sorry!', 'Oops');
      }
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Yearly-Electric-not-updated-Please-try-again'));
    });
  }

  uploadYearlyElectricAfterChange() {
    console.log('uploadYearlyElectricAfterChange working')
    this.homeService.uploadYearlyElectric({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      energy: this.model.consumption.YearlyElecRequired,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.yearly = this.data.YearlyElecRequired;
      this.verifyUploadEnergy();
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Yearly-Electric-not-updated-Please-try-again'));
    });
  }

  verifyUploadEnergy() {
    console.log('verifyuploadenegy api working')
    this.homeService.uploadYearlyElectric({
      email: this.email,
      lead_id: this.leadId,
      action: 'getenergydata',
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      if (response.YearlyElecRequired.TotalElec === this.model.consumption.YearlyElecRequired) {
        this.uploadElectricId();
      }
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Yearly-Electric-not-updated-Please-try-again'));
    });
  }

  uploadElectricId() {
    console.log('home api uploadelectricId working')
    this.homeService.uploadElectricId({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      elecID: this.model.electric,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.getResultV2();
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Supplier-not-updated-Please-try-again'));
    });
  }

  contactFormSubmit(myData) {
    myData.data.customercontact.lead_id = this.model.leadId;
    this.homeService.updateContactInformations(myData.data).subscribe(reply => {
      const response = reply as any;
      this.bsModalRef.hide();
      this.sendLeadEmail();
      this.toastr.success('Quotation upload successfully');
      // this.router.navigate(['result', 'independence']);

    }, error => {
      this.toastr.warning('Contact Information not updated Please try again', 'Oops');
    });
  }

  sendLeadEmail() {
    const data = {
      email: this.email,
      lead_id: this.model.leadId,
      lang: 'de',
    };
    this.homeService.sendLeadEmail(data).subscribe(reply => {
      const response = reply as any;
    }, error => {
      this.toastr.warning('Something went wrong', 'Oops');
    });
  }

  getResultV2() {
    this.loader.hide();
    this.resultService.getResultV2().subscribe(res => {
      this.data = res as any;
      if (this.isResultApiCompleted) {
        this.contactFormSubmit(this.contactFormDetails);
      } else {
        this.isResultApiCompleted = true;
      }
      this.router.navigate(['resultv3']);
      if (this.data.status) {
      }
      this.resultDataService.setResult(this.data);
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

  startTimer() {

    this.showLoader = true;
  }

  updateContactInformations() {
    this.startTimer();
    const initialState = {
      title: 'Contact',
      confirmed: false,
      class: 'modal-lg',
    };
    this.bsModalRef = this.modalService.show(Quotev3Component, {initialState});

    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose = (myData) => {
      this.contactFormDetails = myData;
      if (this.isResultApiCompleted) {
        this.contactFormSubmit(this.contactFormDetails);
      } else {
        this.bsModalRef.hide();
        this.isResultApiCompleted = true;
      }
    };
  }

  receiveElecId(event) {
    this.model.electric = event.value;
  }
  receiveSupplierShortName(event) {
    console.log(event);
    this.headArr[4].Supplier = event.value;
  }

}
