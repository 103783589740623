import {HouseRoofType} from "../house-roof-type/house-roof-type.model";
import {HouseConsumptionNewV1Model} from "../house-consumption-new-v1/house-consumption-new-v1.model";


class  FormDataModelNew {
  leadId: string;
  roofArea: any;
  roofSurfaceNumber: number;
  roofType: HouseRoofType;
  roofPitch: number;
  alignment: number;
  // consumption: any;
  consumption: HouseConsumptionNewV1Model;
  hotWaterType: string;
  heaterType: string;
  electric: number;
  location: any;
}

export {
  FormDataModelNew
};
