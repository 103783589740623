import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ResultService} from "../result/result.service";
import {InfoDialogueComponent} from "../home-new-v1/info-dialogue/info-dialogue.component";
import {MatDialog} from "@angular/material/dialog";
import {RoofAreaDialogComponent} from "./roof-area-dialog/roof-area-dialog.component";

@Component({
  selector: 'app-house-roof-area-new-v1',
  templateUrl: './house-roof-area-new-v1.component.html',
  styleUrls: ['./house-roof-area-new-v1.component.scss']
})
export class HouseRoofAreaNewV1Component implements OnInit, OnChanges {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() roofStep;
  @Input() roofArea: number;
  @Input() area;
  leadId;

  constructor(private resultService: ResultService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (this.area && this.roofArea) {
    //   this.roofArea = this.area;
    // }
  }

  public submitForm(): void {
    if (this.roofArea <= 150) {
      this.dialog.open(RoofAreaDialogComponent, {
      });
    } else {
      this.onSubmit.emit({
        roofArea: this.roofArea
      });
    }
  }

  // checkAreaLoaction()  {
  //   this.homeService.checkAreaLoaction({
  //     email: this.appConstants.email,
  //     lead_id: this.leadId,
  //     check: 'area' }).subscribe(res);
  // }
}
