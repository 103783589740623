import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/app.constant';
import { HeaderModelv4 } from '../../header/header.model';
import { HomeService } from 'src/app/home/home.service';
import { Quotev3Component } from 'src/app/quotev3/quotev3.component';
import { ResultService, ResultDataService } from 'src/app/result/result.service';

@Component({
  selector: 'app-homev4',
  templateUrl: './homev4.component.html',
  styleUrls: ['./homev4.component.scss']
})
export class Homev4Component implements OnInit {
  model: HeaderModelv4 = {
    email: '',
    token: '',
    leadId: '',
    roofArea: 0,
    // longitude: any;
    // latitude: any;
    roofSurfaceNumber: 0,
    roofPitch: 0,
    alignment: 0,
    consumption: {
      numPeople: 0,
      YearlyElecRequired: 50000,
      monthlyElecCost: 150,
      isMonthlyPriceSelected: false
    },
    hotWaterType: '',
    heaterType: '',
    electric: 0,
    location: null,
  };
  @Output() inputConsumption: EventEmitter<any> = new EventEmitter<any>();
  activeStep = 'Location';
  selectedV = true;
  // email: string;
  bsModalRef: BsModalRef;
  token;
  mail;
  leadId;
  areaEvent;
  roofTypeData;
  numSurface;
  roofPitch;
  lead;
  data;
  hotWater;
  Heater;
  title1;
  isVisible = true;
  mapCondition = false;
  showLoader = false;
  consumption;
  oldHeater;
  email;
  yearlyElectricAfterChange;
  heaterType;
  yearly;
  isResultApiCompleted = false;
  contactFormDetails: any;
  filtered;
  headArr = [
  { title: 'Location', selected: false },
  { title: 'Roof Area', selected: false },
  { title: 'Average Consumption', selected: false }
  ];

  constructor(private homeService: HomeService,
    private toastr: ToastrService,
    private loader: NgxSpinnerService,
    private Zone: NgZone,
    private translate: TranslateService,
    private appConstants: Constants,
    private resultService: ResultService,
    private modalService: BsModalService,
    private router: Router,
    private resultDataService: ResultDataService,
  ) {
    // @ts-ignore
    window?.angularComponentRef = { component: this, zone: Zone };
    this.model = new HeaderModelv4();

    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
  }

  translateLang(heading) {
    return this.translate.instant('heading.' + heading);
  }

  ngOnInit(): void { }
  stepChange(step) {
    this.filtered = this.headArr.filter((item) => item.title === step);
    if (this.filtered[0].selected) {
      return this.activeStep = step;
    }
  }

  receiveData(data): void {
    this.model.email = data.email;
    this.model.token = data.token;
    this.model.leadId = data.lead;
    // this.model.longitude = data.lat;
    this.model.location = data.location;
    this.model.roofPitch = data.pitch;
    this.model.alignment = data.alignAngle;
  }

  receiveLeadId(data) {
    this.leadId = data.value;
    this.model.leadId = data.value;
  }


  selectedData(rdata) {
    this.roofTypeData = rdata.value.key;
    this.numSurface = rdata.twoSideOccupy;
  }

  selectedDataHotWater(data) {
    this.hotWater = data.value;
  }

  selectedHeater(data) {
    this.heaterType = data.value;
    console.log(this.heaterType);
  }

  uploadExtraInput1(event) {

    console.log(this.heaterType, this.showLoader);
    this.model.consumption = event.value;
    this.oldHeater = this.heaterType;
    this.homeService.uploadExtraInput({
      email: this.email,
      lead_id: this.leadId,
      info: {
        NumPeople: this.model.consumption.numPeople,
        Heating: this.heaterType,
        Water: this.hotWater,
        Type: 'Residential',
      }
    }).subscribe(res => {
      const response = res as any;
      this.model.consumption.YearlyElecRequired = response.energy;
      this.yearlyElectricAfterChange = response.energy;
      // this.uploadYearlyElectric();
    }, error => {
      this.toastr.warning('Something went Wrong Please try again', 'Oops');
    });
  }

  uploadExtraInput() {
    setTimeout(() => {
      this.homeService.uploadExtraInput({
        email: this.email,
        lead_id: this.leadId,
        info: {
          NumPeople: 4,
          Water: "OL",
          Heating: "EL",
          Type: 'Residential',
        }
      }).subscribe(res => {
        const response = res as any;
        console.log(this.model, this.model.consumption)
        this.yearlyElectricAfterChange = response.energy;
        this.model.consumption.YearlyElecRequired = response.energy;
      });
    }, 500);
  }


  uploadYearlyElectric() {
    console.log('yearly electric', this.model)
    this.homeService.uploadYearlyElectric({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      energy: this.model.consumption.YearlyElecRequired,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.yearly = this.data.YearlyElecRequired;
      // this.uploadElectricId();
      this.verifyUploadEnergy()
      if (this.yearly === undefined || this.yearly === '') {
        this.toastr.warning('There is no Suppliers in your Location Sorry!', 'Oops');
      }
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Yearly-Electric-not-updated-Please-try-again'));
    });
  }

  uploadYearlyElectricAfterChange() {
    console.log('uploadYearlyElectricAfterChange working')
    this.homeService.uploadYearlyElectric({
      email: this.email,
      lead_id: this.leadId,
      action: 'upload',
      energy: this.model.consumption.YearlyElecRequired,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.yearly = this.data.YearlyElecRequired;
      this.verifyUploadEnergy();
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Yearly-Electric-not-updated-Please-try-again'));
    });
  }

  verifyUploadEnergy() {
    console.log('verifyuploadenegy api working');
    // this.startTimer();
    this.homeService.uploadYearlyElectric({
      email: this.email,
      lead_id: this.leadId,
      action: 'getenergydata',
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.uploadElectricId();
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Yearly-Electric-not-updated-Please-try-again'));
    });
  }

  receiveElecId(event) {
    console.log(this.model, event)
    this.model.electric = event.elecId;
    console.log(this.model)
  }

  sendLeadEmail() {
    const data = {
      email: this.email,
      lead_id: this.model.leadId,
      lang: 'de',
    };
    this.homeService.sendLeadEmail(data).subscribe(reply => {
      const response = reply as any;
    }, error => {
      this.toastr.warning('Something went wrong', 'Oops');
    });
  }

  uploadElectricId() {
    console.log('home api uploadelectricId working', this.model)

    this.homeService.uploadElectricId({ 
      email: this.email,
      lead_id: this.model.leadId,
      action: 'upload',
      elecID: this.model.electric,
      ebillmonthly: this.model.consumption.isMonthlyPriceSelected ? this.model.consumption.monthlyElecCost : undefined
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      // this.getResult();
      this.getResultV2();
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Supplier-not-updated-Please-try-again'));
    });
  }

  contactFormSubmit(myData) {
    myData.data.customercontact.lead_id = this.model.leadId;
    this.homeService.updateContactInformations(myData.data).subscribe(reply => {
      const response = reply as any;
      this.bsModalRef.hide();
      this.sendLeadEmail()
      this.toastr.success('Quotation upload successfully');
      // this.router.navigate(['result', 'independence']);

    }, error => {
      this.toastr.warning('Contact Information not updated Please try again', 'Oops');
    });
  }

  updateContactInformations() {
    this.startTimer();
    const initialState = {
      title: 'Contact',
      confirmed: false,
      class: 'modal-lg',
    };
    this.bsModalRef = this.modalService.show(Quotev3Component, { initialState });

    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose = (myData) => {
      this.contactFormDetails = myData;
      if (this.isResultApiCompleted) {
        this.contactFormSubmit(this.contactFormDetails);
      } else {
        this.bsModalRef.hide();
        this.isResultApiCompleted = true;
      }
    };
  }

  startTimer() {
    this.showLoader = true;
  }

  getResultV2() {
    this.loader.hide();
    this.resultService.getResultV2().subscribe(res => {
      this.data = res as any;
      if (this.isResultApiCompleted) {
        this.contactFormSubmit(this.contactFormDetails);
      } else {
        this.isResultApiCompleted = true;
      }
      this.router.navigate(['resultv2']);
      if (this.data.status) {
      }
      this.resultDataService.setResult(this.data);
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

  next(step) {
    // console.log(step, 'step');
    if (step.value === 'Location') {
      console.log('is it wrking')
      this.activeStep = 'Roof Area';
      this.isVisible = true;
      this.headArr[0].selected = true;
      console.log(this.headArr[0].selected);
    } else if (step.value === 'Roof Area') {
      console.log('this')
      this.activeStep = 'Average Consumption';
      this.isVisible = true;
      this.headArr[1].selected = true;
      this.uploadExtraInput();
    } else if (step.value === 'Average Consumption') {
      // this.showLoader = true;
      console.log(this.showLoader);
      this.isVisible = true;
      this.headArr[2].selected = true;
      console.log(this.model.consumption)
      this.model.consumption = step.consumption
      if (this.model.consumption.isMonthlyPriceSelected === false) {
        console.log('1')
        this.uploadYearlyElectric();
      } else if (this.model.consumption.isMonthlyPriceSelected === true) {
        // this.verifyUploadEnergy();
        console.log('2')
        this.uploadElectricId();
      }
      this.updateContactInformations();

    } else {
      this.isVisible = true;
    }
    // else if (step === 'Motivation') {
    //   this.isVisible = true;
    // }

  }

  checkAreaError() {
    // this.loader.hide();
    this.mapCondition = true;
    return this.mapCondition;
  }


}
