import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {HomeService} from "../home/home.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-resultv2',
  templateUrl: './resultv2.component.html',
  styleUrls: ['./resultv2.component.scss']
})
export class Resultv2Component implements OnInit {

  convertLang;
  activeStep = 'Independence';
  headArr = [ {title: 'Independence'},
    {title: 'Consumption'},
    {title: 'Financial'},
    {title: 'A Typical Day'},
    {title: 'Sustainability'},
    {title: 'How Further'},
  ]
  isVisible = true;
  constructor(private translate: TranslateService,
              private homeService: HomeService,
              private route: ActivatedRoute, ) {
    this.convertLang = localStorage.getItem('lang');
  }

  ngOnInit(): void { this.convertLanguage() }

  stepChange(step){
    this.activeStep = step;
  }
  next(step) {
    if (step =='Independence') {
      this.activeStep = 'Independence';
      this.isVisible = true;
    } else if (step == 'Consumption') {
      this.activeStep = 'Financial';
      this.isVisible = true;
    } else if (step == 'Financial') {
      this.activeStep = 'A Typical Day';
      this.isVisible = true;
    } else if (step == 'A Typical Day') {
      this.activeStep = 'Sustainability';
      this.isVisible = true;
    } else if (step == 'Sustainability') {
      this.activeStep = 'How Further';
      this.isVisible = true;
    } else {
      this.isVisible = true
    }
  }
  translateLang(heading){
    return this.translate.instant('rheading.'+ heading);
  }
  convertLanguage() {
    // this.convertLang = localStorage.getItem('lang');
    if (this.convertLang === null || this.convertLang === '') {
      localStorage.setItem('lang', 'de');
      this.translate.use(this.homeService.getLanguage());
      // console.log('IF', this.convertLang)
    } else {
      this.translate.use(this.convertLang);
    }
  }
}
