<section class="m-0 p-0 overflow-hidden background">

    <!--  <nav class="row navbar">-->
    <!--    <a class="navbar-brand"><span class="navhead">{{'address.solar' | translate}}</span></a>-->
    <!--  </nav>-->
    <div class="row justify-content-around text-center align-content-center headernav ">
      <div *ngFor="let head of headArr">
        <!--   (click)="stepChange(head.title)"   -->
        <a class="col-md-1 headtag" style="{{activeStep!== head.title ? 'opacity:30%' : ''}}"
           (click)="stepChange(head.title)"
           [ngClass]="{'active' : activeStep === head.title , 'condition' : selectedV === head.selected  }">{{translateLang(head.title)}}</a>
        <i class="fas arrowicon fa-chevron-circle-right" style="{{activeStep!== head.title ? 'opacity:30%' : ''}}"
           [ngClass]="{'active' : activeStep === head.title, 'condition' : selectedV === head.selected }"></i>
      </div>
    </div>
  
  
    <div class="row" style="flex: 1; overflow-y: clip">
      <!--    [ngStyle]="{backgroundImage: 'url(assets/bgimg.png)'}"-->
      <div class="col-12 h-100" *ngIf="!showLoader">
        <div *ngIf="activeStep === 'Location'">
          <app-locationv4 (onSubmit)="next($event)" (mapDetails)="receiveData($event)"></app-locationv4>
        </div>
        <div *ngIf="activeStep === 'Roof Area'">
          <app-roofareav4 (onSubmit)="next($event)" (leadid)="receiveLeadId($event)"></app-roofareav4>
          <!--        [value]=""-->
        </div>
        <!--      <div *ngIf="activeStep === 'Priority'">-->
        <!--        <app-prioritiesv3 [leadId]="this.leadId" (onSumbit1)="next($event.value)"></app-prioritiesv3>-->
        <!--      </div>-->
        <!-- <div *ngIf="activeStep === 'Hot Water'">
          <app-hotwaterv2 (onSubmit)="next($event.value)" (selectedData)="selectedDataHotWater($event)"></app-hotwaterv2>
        </div> -->
        <!-- <div *ngIf="activeStep === 'Heater'">
          <app-heaterv2 (onSubmit)="next($event.value)" [leadId]="this.leadId" [inputConsumption]="consumption"
                        [hotWaterType]="this.hotWater" (selectedData)="selectedHeater($event)"></app-heaterv2>
        </div> -->
        <div *ngIf="activeStep === 'Average Consumption'">
          <app-consumptionv4 [yearly]="yearly" [consumption]="model.consumption" (onSubmit)="receiveElecId($event)" (onSubmit)="next($event)"></app-consumptionv4>
        </div>
  
      </div>
      <div class="col-12 h-100" *ngIf="showLoader">
        <div class="result-bar d-flex align-items-center" style="height:100% ;width: 100%">
          <div class="container-fluid">
            <div class="row mb-5">
              <div class="col-12  ">
                <p class="fetch-result text-center"
                   style="color: #3E494A; font-size: 35px; font-weight: bold">{{'Preparing-Results' | translate}}</p>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12">
                <div class="progress" style="height: 37px">
                  <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar"
                       style="width: 100%; background-color: #154A75 !important;" aria-valuenow="0" aria-valuemin="0"
                       aria-valuemax="100"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="text-center result-content" style="color: #677171; font-size: 17px">
                  {{'content' | translate}}
                  {{'content1' | translate}}
                  {{'content2' | translate}}
                  {{'content3' | translate}}
                </p>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </section>
  
  
  