<section>
  <div class="row wrapper-row remove-extra-space">
    <div class="col desktop-map pr-0">
      <div class="map-wrapper" >
        <div id="mapid" ></div>
        <div class="search input-group" id="search">
          <input type="text" class=" form-control"  style="height: 60px; border: 1px solid white" [(ngModel)]="searchLocation" (keyup)="valuechange($event)" [options]='options' (input)="location()" (onAddressChange)="handleAddressChange($event)" placeholder="{{'address.placeholder' | translate}}"   ngx-google-places-autocomplete   #placesRef="ngx-places" aria-label="Recipient's username" aria-describedby="basic-addon2">
          <div class="input-group-append"  style="height: 60px">
            <span class="input-group-text" id="basic-addon2"><img  style="height: 50px" src="./assets/refresh.png" (click)="mapRedirect()"></span>
          </div>
        </div>
        <div class="solar-intense"id="solar-intense">
          <div class="row"><h2 class="col-12 mb-2 text-center">{{'solar-energy-potential' | translate}}</h2></div>
          <div class="d-flex justify-content-center">
            <h2>
              {{'low' | translate}}
            </h2>
            <div class="ml-2 mr-2">
              <img src="./assets/solar2.png">
            </div>
            <h2>
              {{'high' | translate}}
            </h2>
          </div>
        </div>
        <div >
          <button type="button" id = 'continue' style="font-size: 17px" class="btn btn-secondary my_button"
                  (click)="submitForm()">{{'Continue' | translate}}
          </button>

        </div>


        <!--        <iframe *ngIf='iframeSourceUrl'  [src]="iframeSourceUrl" width="100%"  class="map-iframe" frameborder="0"-->
        <!--                style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>-->
      </div>
    </div>

  </div>
  <!--  <div class="col mobile-map">-->
  <!--    <div class="map-wrapper" >-->
  <!--      &lt;!&ndash;        <div id="map2" class="map" ></div>&ndash;&gt;-->
  <!--      <div id="map" ></div>-->
  <!--      <div class="search input-group" id="search1">-->
  <!--        <input type="text" class=" form-control"  style="height: 60px; border: 1px solid white" [(ngModel)]="searchLocation"  placeholder="{{'address.placeholder' | translate}}"  (input)="location()" ngx-google-places-autocomplete [options]='options'  (onAddressChange)="handleAddressChange($event)"  #placesRef="ngx-places" aria-label="Recipient's username" aria-describedby="basic-addon2">-->
  <!--        <div class="input-group-append"  style="height: 60px">-->
  <!--          <span class="input-group-text" id="basic-addon"><img  style="height: 50px" src="./assets/search.png"  (click)="mapRedirectMobile()"></span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="solar-intense" id="solar-intense1">-->
  <!--        <div class="row"><h2 class="col-12 mb-2 text-center">{{'solar-energy-potential' | translate}}</h2></div>-->
  <!--        <div class="d-flex">-->
  <!--          <h2>-->
  <!--            {{'low' | translate}}-->
  <!--          </h2>-->
  <!--          <div class="ml-2 mr-2">-->
  <!--            <img src="./assets/solar2.jpg">-->
  <!--          </div>-->
  <!--          <h2>-->
  <!--            {{'high' | translate}}-->
  <!--          </h2>-->
  <!--        </div>-->
  <!--        &lt;!&ndash;          <img src="./assets/solar1.png">&ndash;&gt;-->
  <!--      </div>-->
  <!--      <div class="ga-tooltip" style="display: none"></div>-->
  <!--      &lt;!&ndash;    iframe is old map&ndash;&gt;-->
  <!--      &lt;!&ndash;        <iframe *ngIf='iframeSourceUrl'  [src]="iframeSourceUrl" width="100%"  class="map-iframe" frameborder="0"&ndash;&gt;-->
  <!--      &lt;!&ndash;                style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>&ndash;&gt;-->
  <!--    </div>-->

  <!--  </div>-->


</section>
