import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/app.constant';
import { HeaderModel } from 'src/app/header/header.model';
import { HomeService } from 'src/app/home/home.service';
declare var selectedRoof;
declare var pitch;
declare var alignment;
declare function addRoof(area): any;
declare function addAlignment(alignment): any;
declare function addPitch(pit): any;
declare function changeCenter(coord): any;
declare function getMapExtent(data): any;
declare function changeCenterMobile(coord): any;
declare function mapDesktop(): any;
declare function mapMobile(): any;
declare function assignFunction(coor, ext, img): any;
@Component({
  selector: 'app-roofareav5',
  templateUrl: './roofareav5.component.html',
  styleUrls: ['./roofareav5.component.scss']
})
export class Roofareav5Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() leadid: EventEmitter<any> = new EventEmitter<any>();
  @Output() surfaceData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  input = document.getElementsByClassName('pac-item');
  email;
  place = false;
  postalCode = '';
  countryCode;
  surfaceData1 = [];
  searchLocation;
  data;
  innerWidth;
  lang;
  lat;
  show = false;
  coordinat: any;
  extend: any;
  imgDisplay: any;
  locationName;
  roofData: any;
  area: any;
  roofPitch = {};
  alignment = {};
  surfaceArea = {};
  checklang;
  checklat;
  location1;
  sumArea;
  mapCondition = false;
  public model = new HeaderModel();
  options = {
    types: [],
    componentRestrictions: { country: 'CH' }
  };
  constructor(private homeService: HomeService,
    private appConstants: Constants,
    private toastr: ToastrService,
    private translate: TranslateService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private Zone: NgZone,




  ) {
    this.lang = this.route.snapshot.queryParamMap.get('lon');
    this.lat = this.route.snapshot.queryParamMap.get('lat');
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.appConstants.searchLocation.subscribe(value => {
      this.searchLocation = value;
      console.log(this.searchLocation, 'this is location searched')
    });
    this.appConstants.searchLocation.subscribe(value => {
      // @ts-ignore
      this.surfaceData1 = value;
    });
    window['angularComponentRef'] = { component: this, zone: Zone };


  }

  ngOnInit(): void {
    this.appConstants.reloader.subscribe(res => {
      if (res === true) {
        this.appConstants.setLocation(JSON.parse(localStorage.getItem('address')));
        this.makeNewLead();
      }
    });
    this.makeNewLead();

  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): any {
    this.innerWidth = window.innerWidth;
  }

  makeNewLead() {
    selectedRoof = [];
    pitch = [];
    alignment = [];
    this.homeService.makeNewLead({
      email: this.email,
      address: this.searchLocation
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.model.leadId = this.data.lead_id;
      console.log(this.model.leadId);
      this.appConstants.setLeadId(this.model.leadId);
      mapDesktop();
      changeCenter([this.lang, this.lat]);
      console.log(this.surfaceData1[2]);

      assignFunction(this.setCoordinate, this.setExtend, this.setImgDisplay);
      const solarIntense: HTMLElement = document.getElementById('solar-intense');
      solarIntense.style.position = 'absolute';
      const search: HTMLElement = document.getElementById('search');
      search.style.position = 'absolute';
      const continueBtn: HTMLElement = document.getElementById('continue');
      continueBtn.style.position = 'absolute';
    }, error => {
      this.show = false;
      this.toastr.warning('LeadID not present');
    });
  }
  setCoordinate = (data: any) => {
    if (data == undefined || data == null) return;
    this.coordinat = data;
  }
  setExtend = (data: any) => {
    if (data == undefined || data == null) return;
    this.extend = data;
  }
  setImgDisplay = (data: any) => {
    if (data == undefined || data == null) return;
    this.imgDisplay = data;
    this.mapClick(this.coordinat, this.extend, this.imgDisplay);
  }
  public location() {
    this.place = false;
    if (this.input.length === 0) {
      this.place = true;
    } else {
      this.place = false;
    }
  }
  valuechange(newValue) {
    this.searchLocation = newValue.srcElement.value;
  }
  mapClick(coor, ext, img) {
    console.log('mapclick working')
    const apiCall = this.http.get(`https://api.geo.admin.ch/rest/services/api/MapServer/identify?geometryType=esriGeometryPoint&geometry=${coor[0]},${coor[1]}&geometryFormat=geojson&imageDisplay=${img[0]},${img[1]},96&mapExtent=${ext[1]},${ext[2]},${ext[3]},${ext[4]}&tolerance=10&sr=2056&layers=all:ch.bfe.solarenergie-eignung-daecher&lang=en`);
    apiCall.subscribe(res => {
      const response = res as any;
      this.roofData = response;
      addRoof(response.results[0].properties.flaeche);
      addAlignment(response.results[0].properties.ausrichtung);
      addPitch(response.results[0].properties.neigung);
      // tslint:disable-next-line:only-arrow-functions
      this.area = Math.round(selectedRoof.reduce(function (a, b) {
        return a + b;
      }, 0));
      getMapExtent(response.results[0].geometry.coordinates[0][0])

      for (let i in pitch) {
        this.roofPitch['Area' + i] = Math.round(pitch[i]);
      }
      for (let i in alignment) {
        this.alignment['Area' + i] = Math.round(alignment[i]);
      }
      for (let i in selectedRoof) {
        this.surfaceArea['Area' + i] = Math.round(selectedRoof[i]);
        console.log(Object.keys(this.surfaceArea).length);

      }
      this.model.roofArea = this.area;
    }, error => {
      this.toastr.warning(this.translate.instant('Toast.Please-first-select-roof-from-map'));
    })
  }
  mapRedirect() {

    if (Object.keys(this.surfaceArea).length) {
      addRoof(0);
      console.log('mapredirect working!');
    }
    if (this.place === false) {
      this.callGeocode();
      this.waitForOneSecond().then((value) => {
        if (this.postalCode === '') {
          return false
        }
        if (this.countryCode === 'CH') {
          changeCenter([this.checklang, this.checklat]);
          assignFunction(this.setCoordinate, this.setExtend, this.setImgDisplay);
          changeCenter([this.checklang, this.checklat]);
        }
      }
      );
    }
    if (this.place === true) {
      changeCenter([this.lang, this.lat]);
      assignFunction(this.setCoordinate, this.setExtend, this.setImgDisplay);
      changeCenter([this.lang, this.lat]);
    }
  }
  waitForOneSecond() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('I promise to return after one second!');
      }, 1000);
    });
  }
  calledFromOutside(newValue) {
    this.Zone.run(() => {
      this.sumArea = newValue;
      // tslint:disable-next-line:only-arrow-functions
      this.area = Math.round(selectedRoof.reduce(function (a, b) {
        return a + b;
      }, 0));
      this.roofPitch = {};
      this.alignment = {};
      this.surfaceArea = {};
      for (let i in pitch) {
        this.roofPitch['Area' + i] = Math.round(pitch[i]);
        this.mapCondition = true;
      }
      for (let i in alignment) {
        this.alignment['Area' + i] = Math.round(alignment[i]);
      }
      for (let i in selectedRoof) {
        this.surfaceArea['Area' + i] = Math.round(selectedRoof[i]);
      }
      this.model.roofArea = this.area;

    });
  }
  public handleAddressChange(address: Address) {
    if (address && address.geometry && address.geometry.location) {
      this.place = true;
      this.lang = address.geometry.location.lng();
      this.lat = address.geometry.location.lat();
      this.locationName = address.name;
      console.log(address.name);
      this.searchLocation = address.formatted_address;
      length = address.address_components.length;
      // console.log(this.lang, this.lat, this.locationName, this.searchLocation);
      for (let i = 0; i < address.address_components.length; i++) {
        for (let j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === "postal_code") {
            this.postalCode = address.address_components[i].long_name;
          }
          if (address.address_components[i].types[j] === "country") {
            this.countryCode = address.address_components[i].short_name;
          }
        }
      }
      if (this.postalCode === '') {
        this.toastr.error(this.translate.instant('Toast.Please-write-your-full-address'));
        return false;
      }
      this.surfaceData1 = [];
      this.surfaceData1.push(this.searchLocation);
      this.surfaceData1.push(this.postalCode);
      this.surfaceData1.push(this.locationName);
      localStorage.setItem('locationName', this.locationName)
      this.surfaceData1.push(this.countryCode);
      localStorage.setItem('address', JSON.stringify(this.surfaceData1));
      this.appConstants.setLocation(this.surfaceData1);
      console.log(this.surfaceData1[2])
    }
  }
  public callGeocode<T>() {
    console.log('callgeocode working');
    let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.searchLocation}&key=AIzaSyA1uefY3TIXavmMXW0qgHNLn9a8vcE_804`;
    this.http.get(api).subscribe(res => {
      const response = res as any;
      const address = response.results[0];
      this.checklang = address.geometry.location.lng;
      console.log(this.checklang);
      this.checklat = address.geometry.location.lat;
      this.location1 = address.formatted_address;
      length = address.address_components.length;
      console.log('working?');
      this.postalCode = '';
      for (var i = 0; i < address.address_components.length; i++) {
        for (var j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === "postal_code") {
            this.postalCode = address.address_components[i].long_name;
          }
          if (address.address_components[i].types[j] === "country") {
            this.countryCode = address.address_components[i].short_name;
          }
          if (address.address_components[i].types[j] === "locality") {
            this.locationName = address.address_components[i].short_name;
          }
        }
      }
      if (this.postalCode === '') {
        this.toastr.error(this.translate.instant('Toast.Please-write-your-full-address'));
        return false;
      }
      if (this.countryCode !== 'CH') {
        this.toastr.warning('This service is provided only in Switzerland');
        return false;
      }
      this.surfaceData1 = [];
      this.surfaceData1.push(this.searchLocation);
      this.surfaceData1.push(this.postalCode);
      this.surfaceData1.push(this.locationName);
      localStorage.setItem('locationName', this.locationName);
      this.surfaceData1.push(this.countryCode);
      localStorage.setItem('address', JSON.stringify(this.surfaceData1));
      this.appConstants.setLocation(this.surfaceData1);
      console.log(this.surfaceData1[2]);
    });
  }

  uploadMannualArea() {
    this.homeService.uploadMannulaArea({
      email: this.email,
      lead_id: this.model.leadId,
      manual_area: this.model.roofArea
      // manual_area: this.area
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
    });
  }
  public submitForm(): void {
    if (Object.keys(this.surfaceArea).length !== 0) {
      this.onSubmit.emit({
        value: 'Roof Area',
      });
      this.leadid.emit({
        value: this.data.lead_id,
        surfaceArea: this.surfaceArea
      });
      this.surfaceData.emit({
        email: this.email,
        lead_id: this.model.leadId,
        latitude: parseFloat(this.lat),
        longitude: parseFloat(this.lang),
        locationName: this.surfaceData1[2],
        CountryCode: this.surfaceData1[3],
        Postal: this.surfaceData1[1],
        'map address': this.surfaceData1[0],
        tilt: this.roofPitch,
        azimuth: this.alignment,
        numsurfaces: this.model.roofSurfaceNumber ? 2 : 1,
        surfacearea: this.surfaceArea,
        manual_area: this.model.roofArea
      })
    }
  }
}
