<div class="container-fluid " xmlns="http://www.w3.org/1999/html">
  <div class="row wrapper-row remove-extra-space">
    <div class="result-bar d-flex align-items-center" style="height:100% ;width: 100%; background-color: #E5E9EC" *ngIf="showLoader">
      <div class="container-fluid">
        <div class="row mb-5">
          <div class="col-12  ">
            <p class="fetch-result text-center">{{'Preparing-Results' | translate}}</p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12">
            <div class="progress" style="height: 37px">
              <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar" style="width: 100%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <!--<div class="col-12">-->
          <!--<progressbar [value]="loaderValue" [animate]="true" [striped]="true"  type="success">{{ loaderValue }}%</progressbar>-->
          <!--</div>-->
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-center result-content">
              {{'content' | translate}}
              {{'content1' | translate}}
              {{'content2' | translate}}
              {{'content3' | translate}}

            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-7 mobile-map">
      <div class="map-wrapper" *ngIf="!showLoader">
        <!--        <div id="map2" class="map" ></div>-->
        <div id="map" ></div>
        <div class="search input-group" id="search1">
          <input type="text" class=" form-control"  style="height: 60px; border: 1px solid white" [(ngModel)]="searchLocation"  placeholder="{{'address.placeholder' | translate}}"  (input)="location()" ngx-google-places-autocomplete [options]='options'  (onAddressChange)="handleAddressChange($event)"  #placesRef="ngx-places" aria-label="Recipient's username" aria-describedby="basic-addon2">
          <div class="input-group-append"  style="height: 60px">
            <span class="input-group-text" id="basic-addon"><img  style="height: 50px" src="./assets/search.png"  (click)="mapRedirectMobile()"></span>
          </div>
        </div>
        <div class="solar-intense" id="solar-intense1">
          <div class="row"><h2 class="col-12 mb-2 text-center">{{'solar-energy-potential' | translate}}</h2></div>
          <div class="d-flex">
            <h2>
              {{'low' | translate}}
            </h2>
            <div class="ml-2 mr-2">
              <img src="./assets/solar2.png">
            </div>
            <h2>
              {{'high' | translate}}
            </h2>
          </div>
          <div class="text-center"><i class="bi bi-info-circle"></i><span class="info" (click)="openDialogMobile()"> {{'info-problem' | translate}}</span></div>
          <!--          <img src="./assets/solar1.png">-->
        </div>
        <div class="ga-tooltip" style="display: none"></div>
        <!--    iframe is old map-->
        <!--        <iframe *ngIf='iframeSourceUrl'  [src]="iframeSourceUrl" width="100%"  class="map-iframe" frameborder="0"-->
        <!--                style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>-->
      </div>

    </div>
    <div class="col-md-5 scroll" *ngIf="!showLoader">
      <div class="form-wrapper card">

        <div class="steps-container">
          <div *ngFor="let step of formSteps; let i = index">
            <div *ngIf="step.isVisible">
              <div class="step-nav-link" *ngIf="activeStep!=i">
                <div class="row">
                  <div class="col-4">
                    <div class="step-label" style="color: #D5D5D5; font-family: 'poppins';">{{step.label | translate}}</div>
                  </div>
                  <div class="col-8">
                    <div class="step-right">
                      <div class="row ">
                        <div class="col-7 step-label">
                          <div *ngIf="step.key=='house-roof-area' && model.roofArea">
                            <span style="padding-right: 8px" >
                              <span>{{model.roofArea}}m<sup>2</sup></span>
                            </span>
                          </div>
                          <div *ngIf="step.key=='house-roof-type' && model.roofArea">
                            <div class="roof-type-nav-icon">
                              <div class="roof-type-nav-icon" [innerHTML]="model?.roofType?.iconSvg"></div>
                            </div>
                          </div>
                          <div *ngIf="step.key =='house-pitch' && model.roofPitch !== null">
                            {{model.roofPitch}}  &#176;
                          </div>
                          <div *ngIf="step.key == 'house-hot-water' && model.hotWaterType">
                            {{ waterHeaters[model.hotWaterType] | translate }}
                          </div>
                          <div *ngIf="step.key == 'house-heater' && model.heaterType">
                            {{ houseHeaters[model.heaterType] | translate }}
                          </div>
                          <div *ngIf="step.key == 'house-alignment' && model.alignment !== null">
                            {{model.alignment}}
                          </div>
                          <div *ngIf="step.key == 'house-consumption' && model.consumption?.numPeople !== null">
                            {{model.consumption.numPeople}},{{model.consumption.YearlyElecRequired}}
                          </div>

                        </div>
                        <div class="col-5">
                          <b><a *ngIf="step.isEditable" (click)="showStep(i)">{{'To-change' | translate}}</a></b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [collapse]="activeStep!=i" [isAnimated]="true">
                <div class="step-form-container " *ngIf="model.leadId">
                  <div *ngIf="step.key=='house-roof-area'">
                    <app-house-roof-area-new-v1 [roofArea]="model.roofArea"
                                         (onSubmit)="checkArea(i, $event)"></app-house-roof-area-new-v1>
                  </div>
                  <div *ngIf="step.key=='house-roof-type'">
                    <app-house-roof-type   (onSubmit)="onSubmit(i, $event)"></app-house-roof-type>
                  </div>
                  <div *ngIf="step.key=='house-pitch'">
                    <app-house-pitch  (onSubmit)="onSubmit(i, $event)"></app-house-pitch>
                  </div>
                  <div *ngIf="step.key=='house-alignment'">
                    <app-house-alignment *ngIf="model && model?.roofType && model?.roofType?.key"
                                         [roofType]="model?.roofType?.key"
                                         (onSubmit)="onSubmit(i, $event)"></app-house-alignment>
                  </div>
                  <div *ngIf="step.key=='house-priority'">
                    <app-house-priority [leadId]="model.leadId" (onSubmit)="onSubmit(i, $event)"></app-house-priority>
                  </div>
                  <div *ngIf="step.key=='house-hot-water'">
                    <app-house-hot-water-new-v1  (onSubmit)="onSubmit(i, $event)"></app-house-hot-water-new-v1>
                  </div>
                  <div *ngIf="step.key=='house-heater'">
                    <app-house-heater-new-v1   (onSubmit)="onSubmit(i, $event)"></app-house-heater-new-v1>
                  </div>
                  <div *ngIf="step.key=='house-consumption'">
<!--                  (extraInput1)="uploadExtraInput1($event)"  -->
                    <app-house-consumption-new-v1 [leadId]="model.leadId" [isAreaUploaded] = isAreaUploaded   [yearly]="yearly"  (skip)="skip1($event)" (onSubmit)="onSubmit(i, $event)"></app-house-consumption-new-v1>
                  </div>
                  <div *ngIf="step.key=='house-energy'">
                    <app-house-energy [leadId]="model.leadId"  (onSubmit)="onSubmit(i, $event)"></app-house-energy>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!--<div class="text-center  contact" *ngIf="showContact()">-->
        <!--<button type="button" class="btn btn-primary w-100 mb-4" (click)="updateContactInformations()">-->
        <!--{{'Contact' | translate }}-->

        <!--</button>-->
        <!--</div>-->

      </div>
    </div>
    <div class="col-md-7 desktop-map">
      <div class="map-wrapper" *ngIf="!showLoader">
        <div id="mapid"></div>
        <div class="search input-group" id="search">
          <input type="text" class=" form-control"  style="height: 60px; border: 1px solid white" [(ngModel)]="searchLocation" (keyup)="valuechange($event)" placeholder="{{'address.placeholder' | translate}}"  (input)="location()" ngx-google-places-autocomplete [options]='options'  (onAddressChange)="handleAddressChange($event)"  #placesRef="ngx-places" aria-label="Recipient's username" aria-describedby="basic-addon2">
          <div class="input-group-append"  style="height: 60px">
            <span class="input-group-text" id="basic-addon2"><img  style="height: 50px" src="./assets/search.png"  (click)="mapRedirect()"></span>
          </div>
        </div>
        <div class="solar-intense"id="solar-intense">
          <div class="row"><h2 class="col-12 mb-2 text-center">{{'solar-energy-potential' | translate}}</h2></div>
          <div class="d-flex justify-content-center">
            <h2>
              {{'low' | translate}}
            </h2>
            <div class="ml-2 mr-2">
              <img src="./assets/solar2.png">
            </div>
            <h2>
              {{'high' | translate}}
            </h2>
          </div>
          <div class="text-center"><i class="bi bi-info-circle"></i><span class="info" (click)="openDialog()"> {{'info-problem' | translate}}</span></div>

        </div>

        <!--        <iframe *ngIf='iframeSourceUrl'  [src]="iframeSourceUrl" width="100%"  class="map-iframe" frameborder="0"-->
        <!--                style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>-->
      </div>
    </div>
  </div>

</div>
