import { HouseRoofTypeModel } from '../housetypev3/HouseRoofType.model';
import { ConsumptionModelV2 } from "../consumptionv2/consumptionv2.model";
import { ConsumptionModelV4 } from '../v4/consumptionv4/consumption.model';

class HeaderModel {
  email: string;
  token: any;
  leadId: string;
  roofArea: number;
  // longitude: any;
  // latitude: any;
  roofSurfaceNumber: number;
  roofPitch: number;
  alignment: number;
  consumption: ConsumptionModelV2;
  hotWaterType: string;
  heaterType: string;
  electric: number;
  location: any;
}
class HeaderModelv4 {
  email: string;
  token: any;
  leadId: string;
  roofArea: number;
  // longitude: any;
  // latitude: any;
  roofSurfaceNumber: number;
  roofPitch: number;
  alignment: number;
  consumption: ConsumptionModelV4 = {
    numPeople: 0,
    YearlyElecRequired: 50000,
    monthlyElecCost: 150,
    isMonthlyPriceSelected: false
  };
  hotWaterType: string;
  heaterType: string;
  electric: number;
  location: any;
}
export {
  HeaderModel,
  HeaderModelv4
};
