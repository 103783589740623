<section>
  <div class="container pt-4 justify-content-center text-center">
<!--    <div class="row mt-5 p-3">-->
<!--      <div class="col "><span class="dtext">HOW FURTHER</span></div>-->
<!--    </div>-->
    <div class="row pb-3 heading ">
      <div class="col p-3"><span class="htext">
      {{'how-futher.content' | translate }}!</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col p-2">
        <span class="desc px-3">{{'how-futher.content1' | translate }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col mt-3 ">
        <button type="button" style="font-size: 17px" class="btn btn-secondary" (click)="createQuotation()">{{'contact-form.labelv' |
          translate }}</button>
      </div>
    </div>
  </div>

</section>
