import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {HomeService} from "../home/home.service";
import {Constants} from "../app.constant";
import {FormDataModel} from "../home/home.model";
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {NgxSpinnerService} from 'ngx-spinner';
import { Location} from '@angular/common';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  options = {
    types: [],
    componentRestrictions: {country: 'CH'}
  };
  lang;
  lat;
  data;
  place = false;
  input = document.getElementsByClassName('pac-item');
  selected = document.getElementsByClassName('pac-item-selected');
  query = document.getElementsByClassName('pac-item-query');
  matched = document.getElementsByClassName('pac-matched');
  input1 = document.getElementsByClassName('map-search');
  searchLocation;
  accessToken;
  location1;
  postalCode = '';
  surfaceData = [];
  locationName;
  countryCode;
  accessTokenPreProd;
  convertLang;
  origin;

  constructor(private router: Router,
              private toastr: ToastrService,
              private homeService: HomeService,
              private appConstants: Constants,
              private sanitizer: DomSanitizer,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private http: HttpClient,
              private loader: NgxSpinnerService,
              private locations: Location) {
    this.appConstants.accessToken.subscribe(value => {
      this.accessToken = value as string;
    });
    this.appConstants.lang = this.route.snapshot.queryParamMap.get('lang');
    this.origin = this.route.snapshot.queryParamMap.get('origin');
    localStorage.setItem('lang', this.route.snapshot.queryParamMap.get('lang') );
    localStorage.setItem('origin', this.origin );
    this.convertLang = this.route.snapshot.queryParamMap.get('lang');
    // this.homeService.setLanguage(this.appConstants.lang);
    this.appConstants.accessTokenPreProd.subscribe(value => {
      this.accessTokenPreProd = value as string;
    });

  }

  ngOnInit() {
    this.checkOrigin();
    this.convertLanguage();
  }

  checkOrigin() {
    let origin;
    const data = {
      origin: ''
    };
    if (this.route.snapshot.queryParams?.origin) {
      data.origin = this.route.snapshot.queryParams?.origin;
    } else if (this.locations['_platformLocation'].location.origin.includes('solextron')) {
      data.origin = 'https://solcalc.solextron.com';
    } else {
      // data.origin = "https://solcalc.solextron.com";
      data.origin = '';
    }
    localStorage.setItem('origin', data.origin)

    this.homeService.checkOrigin(data).subscribe(res => {
      this.appConstants.setTokenPreProd(res['access_token']);
      this.appConstants.setEmail(res['leadusers']);
    }, error => {
      if (error && error && error.message) {
        this.router.navigate(['service-error']);
      }
    });
  }

  convertLanguage() {
    if (this.convertLang === null || this.convertLang === '') {
        localStorage.setItem('lang', 'de');
        this.translate.use(this.homeService.getLanguage());
    } else {
        this.translate.use(this.convertLang);
    }
  }

  valuechange(newValue) {
    this.searchLocation = newValue.srcElement.value;
  }

  public handleAddressChange(address: Address) {
    if (address && address.geometry && address.geometry.location) {
      this.appConstants.setReload(false)
      this.place = false;
      this.lang = address.geometry.location.lng();
      this.lat = address.geometry.location.lat();
      this.location1 = address.formatted_address;
      this.locationName = address.name;
      this.searchLocation = address.formatted_address;
      length = address.address_components.length;
      this.postalCode = '';
      for (var i = 0; i < address.address_components.length; i++) {
        for (var j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === "postal_code") {
            this.postalCode = address.address_components[i].long_name;
          }
          if (address.address_components[i].types[j] === "country") {
            this.countryCode = address.address_components[i].short_name;
          }
        }
      }
      if (this.postalCode === '') {
        this.toastr.error('Please write your full address');
        return false;
      }
      this.surfaceData = [];
      this.surfaceData.push(this.searchLocation);
      this.surfaceData.push(this.postalCode);
      this.surfaceData.push(this.locationName);
      this.surfaceData.push(this.countryCode);
      console.log(this.surfaceData[2]);
      localStorage.setItem('address', JSON.stringify(this.surfaceData));
      this.appConstants.setLocation(this.surfaceData);
    }
  }
  processChange(e) {
    if (e.target.tagName === 'INPUT') {

    } else {
      this.loader.hide();
      if (this.searchLocation.length > 1) {
        this.callGeocodeWithoutSearch();
      }
    }
  }
  public callGeocodeWithoutSearch<T>() {
    console.log('geocode working...?')
    let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.searchLocation}&key=AIzaSyA1uefY3TIXavmMXW0qgHNLn9a8vcE_804`;
    this.http.get(api).subscribe(res => {
      const response = res as any;
      let address = response.results[0];
      this.lang = address.geometry.location.lng;
      this.lat = address.geometry.location.lat;
      this.location1 = address.formatted_address;
      this.searchLocation = address.formatted_address;
      length = address.address_components.length;
      // this.locationName = address.address_components[0].long_name;
      this.postalCode = '';
      for (var i = 0; i < address.address_components.length; i++) {
        for (var j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === "postal_code") {
            this.postalCode = address.address_components[i].long_name;
          }
          if (address.address_components[i].types[j] === "country") {
            this.countryCode = address.address_components[i].short_name;
          }
          if (address.address_components[i].types[j] === "locality") {
            this.locationName = address.address_components[i].short_name;
          }
        }
      }
      if (this.postalCode === '') {
        this.toastr.error('Please write your full address');
        return false;
      }
      if (this.countryCode !== 'CH') {
        this.toastr.warning('This service is provided only in Switzerland');
        return false;
      }
      this.place = false;
      this.surfaceData = [];
      this.surfaceData.push(this.searchLocation)
      this.surfaceData.push(this.postalCode)
      this.surfaceData.push(this.locationName)
      this.surfaceData.push(this.countryCode)
      // @ts-ignore
      localStorage.setItem('address', JSON.stringify(this.surfaceData));
      this.appConstants.setLocation(this.surfaceData);
    })
  }

  homePage() {
    if (this.searchLocation === undefined || this.searchLocation === '') {
      this.toastr.warning('Please write your address');
      return false;
    } else if (this.lat === undefined || this.lang === undefined || this.lang === '' || this.lat === '') {
      this.callGeocode();
    } else {
      if (this.postalCode || this.postalCode.length !== 0) {
      this.router.navigate(['home'], {queryParams: {lon: this.lang, lat: this.lat}});
      }
      return true;
    }
  }
  
  waitForOneSecond() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('I promise to return after one second!');
      }, 1000);
    });
  }

  public callGeocode<T>() {
      let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.searchLocation}&key=AIzaSyA1uefY3TIXavmMXW0qgHNLn9a8vcE_804`;
      this.http.get(api).subscribe(res => {
        const response = res as any;
        let address = response.results[0];
        this.lang = address.geometry.location.lng;
        this.lat = address.geometry.location.lat;
        this.location1 = address.formatted_address;
        length = address.address_components.length;
        // this.locationName = address.address_components[0].long_name;
        this.postalCode = '';
        for (var i = 0; i < address.address_components.length; i++) {
          for (var j = 0; j < address.address_components[i].types.length; j++) {
            if (address.address_components[i].types[j] === "postal_code") {
              this.postalCode = address.address_components[i].long_name;
            }
            if (address.address_components[i].types[j] === "country") {
              this.countryCode = address.address_components[i].short_name;
            }
            if (address.address_components[i].types[j] === "locality") {
              this.locationName = address.address_components[i].short_name;
            }
          }
        }
        if (this.postalCode === '') {
          this.toastr.error('Please write your full address');
          return false;
        }
        if (this.countryCode !== 'CH') {
          this.toastr.warning('This service is provided only in Switzerland');
          return false;
        }
        this.surfaceData = [];
        this.surfaceData.push(this.searchLocation)
        this.surfaceData.push(this.postalCode)
        this.surfaceData.push(this.locationName)
        this.surfaceData.push(this.countryCode)
        localStorage.setItem('address', JSON.stringify(this.surfaceData));
        this.appConstants.setLocation(this.surfaceData);
        this.router.navigate(['home'], {queryParams: {lon: this.lang, lat: this.lat}});
      })
  }

  public location() {
    if (this.input.length === 0) {
      this.place = true;
    } else {
      this.place = false;
    }
  }


  loginPreProd() {
    this.homeService.loginPreProd('abhinandan.gupta@symbtechnologies.com', 'password').subscribe(response => {
        this.appConstants.setTokenPreProd(response.access_token);
      },
      response => {
        if (response && response.error && response.error.error) {
          this.toastr.error(response.error.error, 'Opps!');
        } else {
          this.toastr.error('Unable to complete your request, please try after sometime.', 'Opps!');
        }
      });
  }

}
