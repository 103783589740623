<div class="container sustain">
  <p class="title-header h5 mb-2 ml-1 mt-3">{{'sustainability.content' | translate}}</p>



  <!--// All card to show content-->
  <div class="row all-season">
    <div class="cards-list mt-3 mb-4">

      <div class="card card-color">
        <div class="row mx-auto d-flex mt">
          <img src="./assets/sustain/earth.png">
        </div>
        <div class="title title-color">
          <p>{{'sustainability.globe' | translate}}  <span class="value">{{autoKM}}</span>  {{'sustainability.globe1' | translate}}</p>
        </div>
      </div>

      <div class="card card-color">
        <div class="row mx-auto d-flex mt">
          <img src="./assets/sustain/foot.png">
        </div>
        <div class="title title-color">
          <p>{{'sustainability.foot' | translate}} <span class="value">{{co2}}%.</span></p>
        </div>
      </div>

      <div class="card card-color">
        <div class="row mx-auto d-flex mt">
          <img src="./assets/sustain/plant.png">
        </div>
        <div class=" title title-color ">
          <p>{{'sustainability.tree' | translate}} <span class="value">{{trees}}</span> {{'sustainability.tree1' | translate}}</p>
        </div>
      </div>

    </div>
  </div>
  <!--<div class="row">-->
    <!--<div class="col-12">-->
      <!--<div class="next-button mb-3 float-right">-->
        <!--<b type="button" class="text-uppercase mr-2" style="cursor: pointer" (click)="next()">NEXT</b>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
</div>
