<section class="section">
  <div class="container pt-4 justify-content-center text-center">
<!--    <div class="row mt-5 p-3">-->
<!--      <div class="col "><span class="dtext">{{'consumption1.label1' | translate }}</span></div>-->
<!--    </div>-->
    <div class="row pb-3 head ">
      <div class="col p-3"><span class="htext">
      {{'consumption1.content' | translate }}</span>
      </div>
    </div>
    <!--switch toggler-->
    <div class="row justify-content-center">
      <div class="col-6 d-flex justify-content-end ">
        <div class="form-check">
          <input class="form-check-input input"  type="radio"  value="PV-without-storage" (change)="changeSeason()" [(ngModel)]="storage"name="flexRadioDefault" id="flexRadioDefault1">
          <label class="form-check-label label" for="flexRadioDefault1">
            {{'pv-without-storage' | translate }}
          </label>
        </div>
        <span><i class="fas fa-info-circle" tooltip="Photovoltaic system without battery" [ngClass]="{'active' : storage === 'PV-without-storage'}"> </i></span>
      </div>
      <div class="col-6 d-flex justify-content-start">
        <div class="form-check">
          <input class="form-check-input input" value="PV-with-storage" [(ngModel)]="storage" (change)="changeSeason()" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
          <label class="form-check-label label" for="flexRadioDefault2">
            {{'PV-with-storage' | translate }}
          </label>
        </div>
        <span><i class="fas fa-info-circle" tooltip="Photovoltaic system with battery" [ngClass]="{'active' : storage === 'PV-with-storage'}"> </i></span>

      </div>
    </div>

    <!--main section-->
    <div class="row">
      <div class="col-12">
        <div class="container-fluid">
          <div class="row main-container graph-d my-5 " style="border: 1px black solid">
            <div class="col-12">
              <div class="container-fluid">
                <div class="row mt-5 ">
                  <div class="col heading justify-content-md-end">
                    {{'consumption1.localprod' | translate}}
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-3 headings">
                    <span>{{'consumption1.solarvalue' | translate}}</span>
                  </div>
                  <div class="col-3 headings">
                    <span>{{'consumption1.grid' | translate}}</span>
                  </div>
                  <div class="col-3 headings">
                    <span>{{'consumption1.direct-use' | translate}}</span>
                  </div>
                  <div class="col-3 headings">
                    <span>{{'consumption1.battery' | translate}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 value">
                    {{ seasonValue }}kWh
                  </div>
                  <div class="col-3 value">
                    {{ toNet }}kWh
                  </div>
                  <div class="col-3 value">
                    {{directUse}}kWh
                  </div>
                  <div class="col-3 value">
                    {{battery}}kWh
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col heading">
                    <span>{{'consumption1.summary1' | translate}}</span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-3 headings">
                    <span>{{'consumption1.solar-system1' | translate}}</span>
                  </div>
                  <div class="col-3 headings">
                    <span>{{'consumption1.battery-size1' | translate}}</span>
                  </div>
                  <div class="col-3  headings">
                    <span>{{'consumption1.annual-production1' | translate}}</span>
                  </div>
                  <div class="col-3">
                    <kendo-dropdownlist
                      [data]="listItems"
                      textField="text"
                      valueField="value"
                      [valuePrimitive]="true"
                      (valueChange)="changeSeason()"
                      [(ngModel)]="season"
                      class="form-control"
                    >
                    </kendo-dropdownlist>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-3 value">
                    {{dcPower}}kWp
                  </div>
                  <div class="col-3 value">
                    {{battkWh}}kWh
                  </div>
                  <div class="col-3 value">
                    {{totEnergy}}kWh
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 pb-5">
              <img class="img-fluid " src="./assets/Group%202606.png" alt="picture">
            </div>
          </div>
          <div class="main-container graph-m my-5 row" style="border: 1px black solid">
            <div class="col-12">
              <div class="row mt-5 ">
                <div class="col heading justify-content-md-end">
                  {{'consumption1.localprod' | translate}}
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6 col-md-3 headings">
                  <span>{{'consumption1.solarvalue' | translate}}</span>
                </div>
                <div class="col-6 col-md-3 headings">
                  <span>{{'consumption1.grid' | translate}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-3 value">
                  {{ seasonValue }}kWh
                </div>
                <div class="col-6 col-md-3 value">
                  {{ toNet }}kWh
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6 col-md-3 headings">
                  <span>{{'consumption1.direct-use' | translate}}</span>
                </div>
                <div class="col-6 col-md-3 headings">
                  <span>{{'consumption1.battery' | translate}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-3 value">
                  {{directUse}}kWh
                </div>
                <div class="col-6 col-md-3 value">
                  {{battery}}kWh
                </div>
              </div>
              <div class="row mt-3 justify-content-end">
                <div class="col-5">
                  <kendo-dropdownlist
                    [data]="listItems"
                    textField="text"
                    valueField="value"
                    [valuePrimitive]="true"
                    (valueChange)="changeSeason()"
                    [(ngModel)]="season"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col heading">
                  <span>{{'consumption1.summary1' | translate}}</span>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4 headings">
                  <span>{{'consumption1.solar-system1' | translate}}</span>
                </div>
                <div class="col-4 headings">
                  <span>{{'consumption1.battery-size1' | translate}}</span>
                </div>
                <div class="col-4 headings">
                  <span>{{'consumption1.annual-production1' | translate}}</span>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-4 value">
                  {{dcPower}}kWp
                </div>
                <div class="col-4 value">
                  {{battkWh}}kWh
                </div>
                <div class="col-4 value">
                  {{totEnergy}}kWh
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
