<div>
  <!--<h4 class="step-heading">{{'Consumption.content' | translate}}</h4>-->
  <div class="d-flex head">
    <h4 class="step-heading">{{'Consumption.content' | translate}}</h4>
    <p class="float-right" style="color: gray; font-family: 'poppins';">{{'step' | translate }} 5/5</p>
  </div>
  <p></p>
  <div class="spacer-1"></div>
  <div class="form-container">
    <div class="row form-group d-flex flex-column ">
     <div class="row" >
       <label class="col-12 col-form-label">
         {{'Consumption.num-of-people' | translate}}
       </label>
     </div>
      <div class="row">
        <div class="col-12 col-form-label">
          <input type="number" class="form-control" [(ngModel)]="consumption.numPeople" (input)="extraInput($event)"
                 placeholder="{{'Consumption.num-of-people-placeholder' | translate}}" (mousewheel)="$event.preventDefault()">
        </div>
      </div>
    </div>
    <div class="row form-group d-flex flex-column ">
    <div class="row">
      <label class="col-12 col-form-label">
        {{'Consumption.yearly-consumption' | translate}}
      </label>
    </div>
      <div class="row">
        <div class="col-12 col-form-label">
          <input type="number" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="consumption.YearlyElecRequired"
                 placeholder="{{'Consumption.yearly-consumption' | translate}}" (mousewheel)="$event.preventDefault()">
        </div>
      </div>
    </div>

    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <label class="col-12 col-form-label ">
          {{'Supplier.label1' | translate}}
        </label>
      </div>

     <div class="row">
       <div class="col-12 col-form-label">
         <select name="electricId" id="electricId" [ngModelOptions]="{standalone: true}"  [(ngModel)]="electricId" class="form-control">
           <!--<option [ngValue]="undefined"  hidden selected>Select Energy Supplier</option>-->
            <option  *ngIf="!electricId" [value]="0" hidden >{{'Consumption.supplier' | translate}}</option>

           <option *ngFor="let electric of electrics" [value]="electric.elecID">{{ electric.Name }}</option>
         </select>
       </div>
     </div>
    </div>


    <div class="row">
      <div class="col-12  text-center" *ngIf="consumption.numPeople && consumption.YearlyElecRequired">
        <button class="btn btn-primary w-100" (click)="submitForm()">{{'Continue' | translate}}</button>
      </div>
      <!--<div class="col-6 text-center m-auto"><button class="btn btn-primary w-100" (click)="skip1()">   {{'Consumption.skip' | translate}}</button> </div>-->
    </div>
  </div>
</div>
