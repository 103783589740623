import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { FormDataModel } from "./home.model";
import { HomeService } from "./home.service";
import { ToastrService } from "ngx-toastr";
import { HouseRoofType } from "../house-roof-type/house-roof-type.model";
import { Constants } from "../app.constant";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { generateTypeCheckBlock } from "@angular/compiler-cli/src/ngtsc/typecheck/src/type_check_block";
import { DialogContactComponent } from "../dialog-contact/dialog-contact.component";
import { HousePriorityService } from "../house-priority/house-priority.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../auth/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/internal/operators";
import { ResultDataService, ResultService } from "../result/result.service";
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { ptBrLocale } from "ngx-bootstrap/chronos";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";

declare function mapDesktop(): any;

declare function mapMobile(): any;

declare function addRoof(area): any;

declare function simulate(element, eventName): any;

declare function assignFunction(coor, ext, img): any;

declare function getMapExtent(data): any;

declare function getMapExtentMob(data): any;

declare function eventlistner(): any;

declare function screenShot(): any;

declare var selectedRoof;
declare var pitch;
declare var alignment;

declare function changeCenter(coord): any;

declare function changeCenterMobile(coord): any;

declare function addAlignment(alignment): any;

declare function addPitch(pit): any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [FormDataModel],
})
export class HomeComponent implements OnInit, AfterContentChecked, OnChanges {
  location1;
  postalCode = "";
  surfaceData1 = [];
  locationName;
  countryCode;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  options = {
    types: [],
    componentRestrictions: { country: "CH" },
  };
  place = false;
  input = document.getElementsByClassName("pac-item");
  data;
  yearly;
  searchLocation;
  iframeSourceUrl;
  url = "https://ecoserve-dot-solex-mvp-2.appspot.com/calc/get-results";
  show = false;
  public model = new FormDataModel();
  // email: string = {
  //   email: this.appConstants.email,
  //   address: this.searchLocation
  // };

  area: any;
  sumArea;
  yearlyElectricAfterChange;
  showLoader = false;
  loaderValue = 0;
  roofPitch = {};
  alignment = {};
  surface_data;
  selected;
  waterHeaters = {
    WP: this.translate.instant("Hot-water.Heat-pump"),
    OL: this.translate.instant("Hot-water.oil-gas"),
    EL: this.translate.instant("Hot-water.Electric-boiler"),
  };

  houseHeaters = {
    WP: this.translate.instant("heater.Heat-pump"),
    OL: this.translate.instant("heater.oil-gas"),
    EL: this.translate.instant("heater.Electric-boiler"),
  };

  isCollapsed = false;
  formSteps = [];
  bsModalRef: BsModalRef;
  activeStep = 0;
  accessToken;
  counter;
  customerInfo;
  oldHeater;
  newHeater;
  coordinat: any;
  extend: any;
  imgDisplay: any;
  lang;
  checklang;
  lat;
  checklat;
  roofData: any;
  surfaceArea = {};
  innerWidth;
  isResultApiCompleted = false;
  contactFormDetails: any;
  email;
  uploadEnergy = false;
  constructor(
    private titleService: Title,
    private Zone: NgZone,
    private resultDataService: ResultDataService,
    private resultService: ResultService,
    private sanitizer: DomSanitizer,
    private homeService: HomeService,
    private priorityService: HousePriorityService,
    private toastr: ToastrService,
    private appConstants: Constants,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private translate: TranslateService,
    private router: Router,
    private http: HttpClient,
    private loader: NgxSpinnerService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.titleService.setTitle("Home page title");
    window["angularComponentRef"] = { component: this, zone: Zone };
    this.model = this.model || new FormDataModel();
    this.appConstants.accessToken.subscribe((value) => {
      this.accessToken = value as any;
    });
    this.appConstants.searchLocation.subscribe((value) => {
      // @ts-ignore
      this.surfaceData1 = value;
    });
    this.appConstants.email.subscribe((value) => {
      this.email = value as string;
    });
    this.lang = this.route.snapshot.queryParamMap.get("lon");
    this.lat = this.route.snapshot.queryParamMap.get("lat");

    // Sample data

    this.formSteps.push({
      label: this.translate.instant("Roof-area.label"),
      key: "house-roof-area",
      content: "Mark the entire roof area of ​​your house.",
    });
    // this.formSteps.push({
    //   label: this.translate.instant('House-type.label1'),
    //   key: 'house-roof-type',
    //   content: 'Choose your house type.'
    // });
    // this.formSteps.push({
    //   label: this.translate.instant('Roof-pitch.label1'),
    //   key: 'house-pitch',
    //   content: 'Choose your roof pitch or enter it manually.'
    // });
    // this.formSteps.push({
    //   label: this.translate.instant('alignment.label'),
    //   key: 'house-alignment',
    //   content: 'Choose your roof pitch or enter it manually.'
    // });
    this.formSteps.push({
      label: this.translate.instant("priority.Priority"),
      key: "house-priority",
      content: "Select your motivation to use solar by priority.",
    });
    this.formSteps.push({
      label: this.translate.instant("Hot-water.label1"),
      key: "house-hot-water",
      content: "Choose your roof pitch or enter it manually.",
    });
    this.formSteps.push({
      label: this.translate.instant("heater.label"),
      key: "house-heater",
      content: "Choose your roof pitch or enter it manually.",
    });
    this.formSteps.push({
      label: this.translate.instant("Consumption.label"),
      key: "house-consumption",
      content: "Choose your roof pitch or enter it manually.",
    });

    // this.formSteps.push({
    //   label: this.translate.instant('Supplier.label'),
    //   key: 'house-energy',
    //   content: 'Who is your energy supplier'
    // });

    for (const step of this.formSteps) {
      step.isOpen = false;
      step.isVisible = false;
      step.isEnabled = false;
      step.isEditable = true;
    }

    this.formSteps[0].isOpen = true;
    this.formSteps[0].isVisible = true;
    this.formSteps[0].isEnabled = true;
  }

  ngOnInit(): void {
    // this.login();
    this.appConstants.reloader.subscribe((res) => {
      if (res === true) {
        this.appConstants.setLocation(
          JSON.parse(localStorage.getItem("address"))
        );
        this.makeNewLead();
      }
    });
    this.getScreenSize();
    this.makeNewLead();
    this.convertLanguage();
    document.body.style.background = "#ffffff";
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.lang && this.lat !== "") {
      // this.makeNewLead();
    }
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?): any {
    this.innerWidth = window.innerWidth;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
    // this.uploadExtraInput()
  }

  convertLanguage() {
    this.translate.setDefaultLang("en");
    if (
      this.homeService.getLanguage() &&
      this.homeService.getLanguage() !== ""
    ) {
      this.translate.use(this.homeService.getLanguage());
    } else {
      this.translate.use("en");
      this.homeService.setLanguage("en");
    }
  }

  // Genrate New Lead Id
  makeNewLead() {
    selectedRoof = [];
    pitch = [];
    alignment = [];
    this.homeService
      .makeNewLead({
        email: this.email,
        address: this.searchLocation,
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.data = response;
          this.model.leadId = this.data.lead_id;
          this.appConstants.setLeadId(this.model.leadId);
          if (this.innerWidth > 768) {
            mapDesktop();
            changeCenter([this.lang, this.lat]);
          }
          if (this.innerWidth < 768) {
            mapMobile();
            changeCenterMobile([this.lang, this.lat]);
          }
          // changeCenter([this.lang, this.lat]);
          assignFunction(
            this.setCoordinate,
            this.setExtend,
            this.setImgDisplay
          );
          const solarIntense: HTMLElement =
            document.getElementById("solar-intense");
          solarIntense.style.position = "absolute";
          const search: HTMLElement = document.getElementById("search");
          search.style.position = "absolute";
          const solarIntense1: HTMLElement =
            document.getElementById("solar-intense1");
          solarIntense1.style.position = "absolute";
          const search1: HTMLElement = document.getElementById("search1");
          search1.style.position = "absolute";
        },
        (error) => {
          this.show = false;
          this.toastr.warning("LeadID not present");
        }
      );
  }

  public handleAddressChange(address: Address) {
    if (address && address.geometry && address.geometry.location) {
      this.place = true;
      this.lang = address.geometry.location.lng();
      this.lat = address.geometry.location.lat();
      this.location1 = address.formatted_address;
      this.locationName = address.name;
      this.searchLocation = address.formatted_address;
      length = address.address_components.length;
      this.postalCode = "";
      for (var i = 0; i < address.address_components.length; i++) {
        for (var j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === "postal_code") {
            this.postalCode = address.address_components[i].long_name;
          }
          if (address.address_components[i].types[j] === "country") {
            this.countryCode = address.address_components[i].short_name;
          }
        }
      }
      if (this.postalCode === "") {
        this.toastr.error(
          this.translate.instant("Toast.Please-write-your-full-address")
        );
        return false;
      }
      this.surfaceData1 = [];
      this.surfaceData1.push(this.searchLocation);
      this.surfaceData1.push(this.postalCode);
      this.surfaceData1.push(this.locationName);
      this.surfaceData1.push(this.countryCode);
      localStorage.setItem("address", JSON.stringify(this.surfaceData1));
      this.appConstants.setLocation(this.surfaceData1);
    }
  }

  waitForOneSecond() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("I promise to return after one second!");
      }, 1000);
    });
  }

  valuechange(newValue) {
    this.searchLocation = newValue.srcElement.value;
  }

  public callGeocode<T>() {
    console.log("callgeocode working");
    let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.searchLocation}&key=AIzaSyA1uefY3TIXavmMXW0qgHNLn9a8vcE_804`;
    this.http.get(api).subscribe((res) => {
      const response = res as any;
      let address = response.results[0];
      this.checklang = address.geometry.location.lng;
      console.log(this.checklang);
      this.checklat = address.geometry.location.lat;
      this.location1 = address.formatted_address;
      length = address.address_components.length;
      console.log("working?");
      this.postalCode = "";
      for (var i = 0; i < address.address_components.length; i++) {
        for (var j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === "postal_code") {
            this.postalCode = address.address_components[i].long_name;
          }
          if (address.address_components[i].types[j] === "country") {
            this.countryCode = address.address_components[i].short_name;
          }
          if (address.address_components[i].types[j] === "locality") {
            this.locationName = address.address_components[i].short_name;
          }
        }
      }
      if (this.postalCode === "") {
        this.toastr.error(
          this.translate.instant("Toast.Please-write-your-full-address")
        );
        return false;
      }
      if (this.countryCode !== "CH") {
        this.toastr.warning("This service is provided only in Switzerland");
        return false;
      }
      this.surfaceData1 = [];
      this.surfaceData1.push(this.searchLocation);
      this.surfaceData1.push(this.postalCode);
      this.surfaceData1.push(this.locationName);
      console.log(this.locationName);
      console.log(this.surfaceData1[2]);
      this.surfaceData1.push(this.countryCode);
      localStorage.setItem("address", JSON.stringify(this.surfaceData1));
      this.appConstants.setLocation(this.surfaceData1);
    });
  }

  mapRedirect() {
    if (Object.keys(this.surfaceArea).length) {
      addRoof(0);
      console.log("mapredirect working!");
    }
    if (this.place === false) {
      this.callGeocode();
      this.waitForOneSecond().then((value) => {
        if (this.postalCode === "") {
          return false;
        }
        if (this.countryCode === "CH") {
          changeCenter([this.checklang, this.checklat]);
          assignFunction(
            this.setCoordinate,
            this.setExtend,
            this.setImgDisplay
          );
          changeCenter([this.checklang, this.checklat]);
        }
      });
    }
    if (this.place === true) {
      changeCenter([this.lang, this.lat]);
      assignFunction(this.setCoordinate, this.setExtend, this.setImgDisplay);
      changeCenter([this.lang, this.lat]);
    }
  }

  mapRedirectMobile() {
    if (Object.keys(this.surfaceArea).length) {
      addRoof(0);
    }
    if (this.place === false) {
      this.callGeocode();
      this.waitForOneSecond().then((value) => {
        if (this.postalCode === "") {
          return false;
        }
        if (this.countryCode === "CH") {
          changeCenterMobile([this.checklang, this.checklat]);
          assignFunction(
            this.setCoordinate,
            this.setExtend,
            this.setImgDisplay
          );
          changeCenterMobile([this.checklang, this.checklat]);
        }
      });
    }
    if (this.place === true) {
      changeCenterMobile([this.lang, this.lat]);
      assignFunction(this.setCoordinate, this.setExtend, this.setImgDisplay);
      changeCenterMobile([this.lang, this.lat]);
    }
  }

  public location() {
    this.place = false;
    if (this.input.length === 0) {
      this.place = true;
    } else {
      this.place = false;
    }
  }

  setCoordinate = (data: any) => {
    console.log(data);
    if (data == undefined || data == null) return;
    this.coordinat = data;
  };
  setExtend = (data: any) => {
    if (data == undefined || data == null) return;
    this.extend = data;
  };
  setImgDisplay = (data: any) => {
    if (data == undefined || data == null) return;
    this.imgDisplay = data;
    this.mapClick(this.coordinat, this.extend, this.imgDisplay);
  };

  checkArea(index, event) {
    if (this.area) {
      // this.uploadMannualArea()
      this.onSubmit(index, event);
      this.toastr.success(
        this.translate.instant("Toast.Roof-area-uploaded-sucessfully")
      );
    } else {
      this.toastr.warning(
        this.translate.instant("Toast.Please-first-select-roof-from-map")
      );
    }
  }

  calledFromOutside(newValue) {
    this.Zone.run(() => {
      this.sumArea = newValue;
      // tslint:disable-next-line:only-arrow-functions
      this.area = Math.round(
        selectedRoof.reduce(function (a, b) {
          return a + b;
        }, 0)
      );
      this.roofPitch = {};
      this.alignment = {};
      this.surfaceArea = {};
      for (let i in pitch) {
        this.roofPitch["Area" + i] = Math.round(pitch[i]);
      }
      for (let i in alignment) {
        this.alignment["Area" + i] = Math.round(alignment[i]);
      }
      for (let i in selectedRoof) {
        this.surfaceArea["Area" + i] = Math.round(selectedRoof[i]);
      }
      this.model.roofArea = this.area;
    });
  }

  mapClick(coor, ext, img) {
    console.log("mapclick working");
    let apiCall = this.http.get(
      `https://api.geo.admin.ch/rest/services/api/MapServer/identify?geometryType=esriGeometryPoint&geometry=${coor[0]},${coor[1]}&geometryFormat=geojson&imageDisplay=${img[0]},${img[1]},96&mapExtent=${ext[1]},${ext[2]},${ext[3]},${ext[4]}&tolerance=10&sr=2056&layers=all:ch.bfe.solarenergie-eignung-daecher&lang=en`
    );
    apiCall.subscribe(
      (res) => {
        const response = res as any;
        this.roofData = response;
        addRoof(response.results[0].properties.flaeche);
        addAlignment(response.results[0].properties.ausrichtung);
        addPitch(response.results[0].properties.neigung);
        // tslint:disable-next-line:only-arrow-functions
        this.area = Math.round(
          selectedRoof.reduce(function (a, b) {
            return a + b;
          }, 0)
        );
        if (this.innerWidth < 768) {
          getMapExtentMob(response.results[0].geometry.coordinates[0][0]);
        }
        if (this.innerWidth > 768) {
          getMapExtent(response.results[0].geometry.coordinates[0][0]);
        }
        for (let i in pitch) {
          this.roofPitch["Area" + i] = Math.round(pitch[i]);
        }
        for (let i in alignment) {
          this.alignment["Area" + i] = Math.round(alignment[i]);
        }
        for (let i in selectedRoof) {
          this.surfaceArea["Area" + i] = Math.round(selectedRoof[i]);
        }
        this.model.roofArea = this.area;
      },
      (error) => {
        this.toastr.warning(
          this.translate.instant("Toast.Please-first-select-roof-from-map")
        );
      }
    );
  }

  // Upload the roof area
  updateroofArea() {
    this.homeService
      .updateroofArea({
        email: this.email,
        lead_id: this.model.leadId,
        tilt: this.roofPitch,
        azimuth: this.alignment,
        numsurfaces: this.model.roofSurfaceNumber ? 2 : 1,
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.data = response;
        },
        (error) => {
          this.toastr.warning(
            this.translate.instant(
              "Toast.Roof-Area-not-updated-Please-try-again"
            )
          );
        }
      );
  }

  // Send surface data
  surfaceData() {
    this.homeService
      .surfaceInfo({
        email: this.email,
        lead_id: this.model.leadId,
        latitude: parseFloat(this.lat),
        longitude: parseFloat(this.lang),
        locationName: this.surfaceData1[2],
        CountryCode: this.surfaceData1[3],
        Postal: this.surfaceData1[1],
        "map address": this.surfaceData1[0],
        tilt: this.roofPitch,
        azimuth: this.alignment,
        numsurfaces: this.model.roofSurfaceNumber ? 2 : 1,
        surfacearea: this.surfaceArea,
      })
      .subscribe((res) => {
        const response = res as any;
        this.data = response;
        this.uploadMannualArea();
        this.toastr.success(
          this.translate.instant("Toast.Area-successfully-selected")
        );
      });
  }

  // upload priority list api
  updatePriority(list) {
    this.homeService
      .updatePriority({
        email: this.email,
        lead_id: this.model.leadId,
        prioritylist: list,
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.data = response;
        },
        (error) => {
          if (error && error && error.message) {
            this.toastr.error(error.message);
          }
        }
      );
  }

  // upload extra input such as numof people and heater water when numof people change
  uploadExtraInput1(event) {
    this.model.consumption = event.value;
    this.oldHeater = this.model.heaterType;
    this.homeService
      .uploadExtraInput({
        email: this.email,
        lead_id: this.model.leadId,
        info: {
          NumPeople: this.model.consumption.numPeople,
          Heating: this.model.heaterType,
          Water: this.model.hotWaterType,
          Type: "Residential",
        },
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.model.consumption.YearlyElecRequired = response.energy;
          this.yearlyElectricAfterChange = response.energy;
          this.uploadYearlyElectric();
        },
        (error) => {
          this.toastr.warning("Something went Wrong Please try again", "Oops");
        }
      );
  }

  // upload extra input
  uploadExtraInput() {
    this.homeService
      .uploadExtraInput({
        email: this.email,
        lead_id: this.model.leadId,
        info: {
          NumPeople: this.model.consumption?.numPeople,
          Heating: this.model.heaterType,
          Water: this.model.hotWaterType,
          Type: "Residential",
        },
      })
      .subscribe((res) => {
        const response = res as any;
        this.model.consumption.YearlyElecRequired = response.energy;
        this.yearlyElectricAfterChange = response.energy;
      });
  }

  // upload electric energy yearly required get by after extra input api
  uploadYearlyElectric() {
    this.homeService
      .uploadYearlyElectric({
        email: this.email,
        lead_id: this.model.leadId,
        action: "upload",
        energy: this.model.consumption.YearlyElecRequired,
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.data = response;
          this.yearly = this.data.YearlyElecRequired;
          // this.uploadElectricId();
          if (this.yearly === undefined || this.yearly === "") {
            this.toastr.warning(
              "There is no Suppliers in your Location Sorry!",
              "Oops"
            );
          }
        },
        (error) => {
          this.toastr.warning(
            this.translate.instant(
              "Toast.Yearly-Electric-not-updated-Please-try-again"
            )
          );
        }
      );
  }

  /// Verify uploaded energy
  verifyUploadEnergy() {
    console.log("verifyuploadenegy api working");
    this.homeService
      .uploadYearlyElectric({
        email: this.email,
        lead_id: this.model.leadId,
        action: "getenergydata",
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.data = response;
          if (
            response.YearlyElecRequired.TotalElec ===
            this.model.consumption.YearlyElecRequired
          ) {
            this.uploadElectricId();
          }
        },
        (error) => {
          this.toastr.warning(
            this.translate.instant(
              "Toast.Yearly-Electric-not-updated-Please-try-again"
            )
          );
        }
      );
  }

  // upload electric when change manually
  uploadYearlyElectricAfterChange() {
    console.log("uploadYearlyElectricAfterChange working");
    this.homeService
      .uploadYearlyElectric({
        email: this.email,
        lead_id: this.model.leadId,
        action: "upload",
        energy: this.model.consumption.YearlyElecRequired,
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.data = response;
          this.yearly = this.data.YearlyElecRequired;
          this.verifyUploadEnergy();
        },
        (error) => {
          this.toastr.warning(
            this.translate.instant(
              "Toast.Yearly-Electric-not-updated-Please-try-again"
            )
          );
        }
      );
  }

  // upload the electric id from dropdown list
  uploadElectricId() {
    console.log("home api uploadelectricId working");
    this.homeService
      .uploadElectricId({
        email: this.email,
        lead_id: this.model.leadId,
        action: "upload",
        elecID: this.model.electric,
      })
      .subscribe(
        (res) => {
          const response = res as any;
          this.data = response;
          // this.getResult();
          this.getResultV2();
        },
        (error) => {
          this.toastr.warning(
            this.translate.instant(
              "Toast.Supplier-not-updated-Please-try-again"
            )
          );
        }
      );
  }

  // upload the roof area from map or manually
  uploadMannualArea() {
    this.homeService
      .uploadMannulaArea({
        email: this.email,
        lead_id: this.model.leadId,
        manual_area: this.model.roofArea,
        // manual_area: this.area
      })
      .subscribe((res) => {
        const response = res as any;
        this.data = response;
      });
  }

  onSubmit(index, event) {
    const step = this.formSteps[index];

    if (this.formSteps[index + 1]) {
      this.formSteps[index + 1].isVisible = true;
    }
    if (step.key === "house-roof-area") {
      const name = screenShot();
      this.homeService.getTokenForImage(this.email).subscribe(
        (res) => {
          localStorage.setItem("imageAccessToken", res.access_token);
          this.homeService.uploadImage(name).subscribe(
            (res) => {},
            (error) => {}
          );
        },
        (error) => {}
      );
      this.model.roofArea = event.roofArea;
      this.surfaceData();
      if (!this.model.roofArea) {
        this.model.roofArea = this.area;
      } else if (this.model.roofArea !== this.area) {
        this.model.roofArea = event.roofArea;
        this.surfaceData();
      }
    } else if (step.key === "house-roof-type") {
      this.model.roofType = event.value;
      this.model.roofSurfaceNumber = event.twoSideOccupy;
      this.model.alignment = 0;
      if (event.value.key && event.value.key === "flat") {
        // this.model.roofPitch = 0;
        // this.model.alignment = 0;
        this.roofPitch = 0;
        this.alignment = 0;
        this.formSteps[index + 1].isEditable = false;
        this.formSteps[index + 2].isEditable = false;
        this.updateroofArea();
        this.jumpToStep(index, index + 3);
        return;
      } else {
        this.model.roofPitch = null as number;
        this.model.alignment = null as number;
        this.formSteps[index + 1].isEditable = true;
        this.formSteps[index + 2].isEditable = true;
        this.updateroofArea();
      }
    } else if (step.key === "house-pitch") {
      this.model.roofPitch = event.value;
    } else if (step.key === "house-alignment") {
      this.model.alignment = event.alignment;
      this.updateroofArea();
    } else if (step.key === "house-priority") {
      this.updatePriority(event.list);
    } else if (step.key === "house-hot-water") {
      this.model.hotWaterType = event.value;
    } else if (step.key === "house-heater") {
      this.model.heaterType = event.value;
      // this.uploadExtraInput();
    } else if (step.key === "house-consumption") {
      this.model.consumption = event.value;
      this.model.electric = event.electricId;
      if (
        this.yearlyElectricAfterChange !==
        this.model.consumption.YearlyElecRequired
      ) {
        this.uploadYearlyElectricAfterChange();
      } else {
        this.verifyUploadEnergy();
      }
      // this.updateContactInformations();
      this.startTimer();
    }
    // else if (step.key === 'house-energy') {
    //   this.model.electric = event.value;
    //   console.log(this.model.electric, 'ele')
    //   this.uploadElectricId()
    // }
    if (this.activeStep >= this.formSteps.length - 1) {
      // alert('Done all');
      // document.getElementById('contact').style.display = 'inline-block';
    } else {
      this.activeStep = index + 1;
    }
  }

  skip1(event) {
    this.model.consumption = event.value;
  }

  showStep(index) {
    this.activeStep = index;
  }

  updateContactInformations() {
    this.startTimer();
    const initialState = {
      title: "Conatct",
      confirmed: false,
      class: "modal-lg",
    };
    this.bsModalRef = this.modalService.show(DialogContactComponent, {
      initialState,
    });

    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose = (myData) => {
      this.contactFormDetails = myData;
      if (this.isResultApiCompleted) {
        this.contactFormSubmit(this.contactFormDetails);
      } else {
        this.bsModalRef.hide();
        this.isResultApiCompleted = true;
      }
    };
  }

  sendLeadEmail() {
    const data = {
      email: this.email,
      lead_id: this.model.leadId,
      lang: "de",
    };
    this.homeService.sendLeadEmail(data).subscribe(
      (reply) => {
        const response = reply as any;
      },
      (error) => {
        this.toastr.warning("Something went wrong", "Oops");
      }
    );
  }

  contactFormSubmit(myData) {
    myData.data.customercontact.lead_id = this.model.leadId;
    this.homeService.updateContactInformations(myData.data).subscribe(
      (reply) => {
        const response = reply as any;
        this.bsModalRef.hide();
        this.sendLeadEmail();
        this.toastr.success("Quotation upload successfully");
        // this.router.navigate(['result', 'independence']);
      },
      (error) => {
        this.toastr.warning(
          "Contact Information not updated Please try again",
          "Oops"
        );
      }
    );
  }

  jumpToStep(fromStep: number, toStep: number) {
    for (let index = fromStep + 1; index <= toStep; index++) {
      this.formSteps[index].isVisible = true;
    }
    this.activeStep = toStep;
  }

  startTimer() {
    this.showLoader = true;
  }

  getResultV2() {
    this.loader.hide();
    this.resultService.getResultV2().subscribe(
      (res) => {
        this.data = res as any;
        if (this.isResultApiCompleted) {
          this.contactFormSubmit(this.contactFormDetails);
        } else {
          this.isResultApiCompleted = true;
        }
        this.router.navigate(["result"]);
        if (this.data.status) {
        }
        this.resultDataService.setResult(this.data);
      },
      (error) => {
        this.toastr.warning("Something went wrong try again", "Oops");
      }
    );
  }
}
